import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Products } from 'src/app/shared/model/e-commerce/product.model';
import { OrderService } from 'src/app/shared/service/e-commerce/order.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { ProductsService } from './../../../shared/service/e-commerce/products.service';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  content = null;
  orderId: string = null;
  orderedItems: any[] = [];
  index = 1;
  size = 8;
  pageCollectionSize: number;

  constructor(
    // tslint:disable-next-line:max-line-length
    public productsService: ProductsService,
    public languageService: LanguageService, public orderService: OrderService, private router: Router, private readonly route: ActivatedRoute) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));
  }

  ngOnInit(): void {
    this.getOrders();
  }

  async getOrders() {
    this.orderService.getOrders(this.index, this.size).then(items => {
      console.log(items);
      this.pageCollectionSize = items?.data?.totalCount;
      this.orderedItems = items?.data?.data;
    });
  }

  onPageChange() {
    this.getOrders();
  }

  redirectToOrderDetails(orderNumber: string) {
    this.router.navigate(['../order-details', orderNumber], { relativeTo: this.route });
  }

  downloadOrderInvoice(orderNumber: string) {
    this.orderService.downloadOrderInvoice(orderNumber).subscribe((data) => {
      var downloadURL = window.URL.createObjectURL(data);
      window.open(downloadURL,"_blank");
    });  
  }

}

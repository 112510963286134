<div class="agency">
  <app-header></app-header>
  <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb-contain ">
            <div>
              <h2 class="breadcrumb-txt">{{content?.MyProfile}}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="portfolio-section port-col zoom-gallery detail-page fullwidth-portfolio">
    <div class="filter-section">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row m-0 m-b-50">
              <div class="col-md-8">
                <div class="portfolio-detail">
                  <h3 class="detail-head">{{ content?.MyDetail }}</h3>
                  <div class="detail-container d-flex detail-border">
                    <div class="portfolio-left">
                      <h5 class="text-left">{{ content?.Name }} :</h5>
                    </div>
                    <div class="portfolio-right">
                      <h5>{{ myProfileData?.name }}</h5>
                    </div>
                  </div>
                  <div class="detail-container d-flex detail-border">
                    <div class="portfolio-left">
                      <h5 class="text-left">{{ content?.Surname }} :</h5>
                    </div>
                    <div class="portfolio-right">
                      <h5>{{ myProfileData?.surname }}</h5>
                    </div>
                  </div>
                  <div class="detail-container d-flex detail-border">
                    <div class="portfolio-left">
                      <h5 class="text-left">{{ content?.Email }} :</h5>
                    </div>
                    <div class="portfolio-right">
                      <h5>{{ myProfileData?.email }}</h5>
                    </div>
                  </div>
                  <div class="detail-container d-flex detail-border">
                    <div class="portfolio-left">
                      <h5 class="text-left">{{ content?.MobileNumber}} :</h5>
                    </div>
                    <div class="portfolio-right">
                      <h5>{{ myProfileData?.mobileNumber }}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 m-b-50">
              <div class="col-md-12">
                <div class="services bg mt-4">
                  <div class="row">
                    <div class="col-sm-6 mb-3" *ngFor="let customerAddress of myProfileData?.addresses; let i = index">
                      <span class="delete-card m-3" (click)="deleteAddress(customerAddress?.id)">X</span>
                      <div class="service service-overlay text-center" data-tilt="" data-tilt-max="6"
                        data-tilt-speed="400" data-tilt-perspective="500" [id]="'active'+(i+1)"
                        style="will-change: transform; transform: perspective(500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
                        <div class="service-feature">
                          <h4 class="feature-text text-center"><b>{{ content?.Address }}: {{ i + 1 }} </b></h4>
                          <p>{{ customerAddress.address }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row m-0 m-b-50">
              <div class="col-md-12 text-center">
                <button class="btn btn-add-address" (click)="addNewAddress(addressModel)"><i class="fa fa-plus"></i>
                  {{ content?.AddAddress }}</button>
                <button class="btn btn-add-address" (click)="editMyDetails(myDetailModel)">
                  <i class="fa fa-pencil" aria-hidden="true"></i> {{ content?.EditMyDetail }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
<ng-template #addressModel let-modal>
  <form [formGroup]="addressForm" (ngSubmit)="saveAddress()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ content?.AddNewAddress }}</h4>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <div class="input-group">
          <input id="addressLine1" class="form-control" placeholder="{{ content?.AddressLine1 }}"
            formControlName="addressLine1">
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group">
          <input id="addressLine2" class="form-control" placeholder="{{ content?.AddressLine2 }}"
            formControlName="addressLine2">
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group">
          <input id="City" class="form-control" placeholder="{{ content?.City }}" formControlName="city">
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group">
          <input id="ZipCode" class="form-control" placeholder="{{content?.ZipCode}}" formControlName="zipCode">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss()">{{ content?.Cancel }}</button>
      <button type="submit" class="btn btn-outline-dark">{{ content?.Save }}</button>
    </div>
  </form>
</ng-template>

<ng-template #myDetailModel let-modal>
  <form [formGroup]="myDetailForm" (ngSubmit)="saveMyDetails()">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{ content?.EditMyDetail }}</h4>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <div class="input-group">
          <input id="firstName" class="form-control" placeholder="{{ content?.Name }}" formControlName="firstName">
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group">
          <input id="lastName" class="form-control" placeholder="{{ content?.Surname }}" formControlName="lastName">
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group">
          <input id="email" class="form-control" placeholder="{{ content?.Email }}" formControlName="email">
        </div>
      </div>
      <div class="mb-3">
        <div class="input-group">
          <input id="mobileNo" class="form-control" placeholder="{{ content?.MobileNumber }}"
            formControlName="mobileNo">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss()">{{ content?.Cancel }}</button>
      <button type="submit" class="btn btn-outline-dark">{{ content?.Save }}</button>
    </div>
  </form>
</ng-template>
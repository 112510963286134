<div class="row">
    <div class="col-12">
        <fieldset>
            <div class="mb-3 row search pull-right">
                <div class="col">
                    <input id="typeahead-http" type="text" class="form-control" [class.is-invalid]="searchFailed"
                        [ngbTypeahead]="search" [resultTemplate]="rt" placeholder="Search products"
                        (selectItem)="selectedItem($event)" [inputFormatter]="formatter" />
                    <small *ngIf="searching" class="form-text text-muted">{{ content?.Searching }}</small>
                    <div class="invalid-feedback" *ngIf="searchFailed">{{ content?.SuggestionsNotLoaded }}.</div>
                </div>
            </div>
        </fieldset>
    </div>
</div>

<ng-template #rt let-r="result" let-t="term">
    <ngb-highlight [result]="r.product" [term]="t"></ngb-highlight>
</ng-template>
<!-- section start -->
<section class="agency blog blog-sec blog-sidebar sider">
    <div class="container">
        <div class="row">
            <div class="col-lg-9 order-lg-2">
                <div class="row p-0">
                    <div class="col-12 p-0" *ngFor="let blog of blogData">
                        <div class="blog-agency">
                            <div class="blog-contain">
                                <img [src]="blog.img" alt="" class="img-fluid">
                                <div class="img-container center-content">
                                    <div class="center-content">
                                        <div class="blog-info">
                                            <div class="m-b-20">
                                                <div class="center-text">
                                                    <i class="fa fa-clock-o m-r-10" aria-hidden="true"></i>
                                                    <h6 class="m-r-25 font-blog">{{blog.date}}</h6>
                                                    <i class="fa fa-map-marker m-r-10" aria-hidden="true"></i>
                                                    <h6 class="font-blog">{{blog.type}}</h6>
                                                </div>
                                            </div>
                                            <h5 class="blog-head font-600">{{blog.title}}</h5>
                                            <p class="para2">{{blog.content}} </p>
                                            <div class="btn-bottom m-t-20">
                                                <a href="#" class="text-uppercase">{{blog.btn}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <app-sidebar></app-sidebar>
            </div>
        </div>
    </div>
</section>
<!-- section end -->
import { Component, EventEmitter, OnInit, Output, OnDestroy, ElementRef, Inject } from '@angular/core';
import { AppIntializerService } from 'src/app/shared/service/app-intializer.service';
import { GymService } from 'src/app/shared/service/gym/gym.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { Menu, NavService } from '../../../service/nav.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, } from '@azure/msal-angular';
import {
  AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest
  , InteractionType
} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CustomizerService } from 'src/app/shared/service/customizer.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {

  public clietnWebAPIUrl: string = environment.apiHostStartPoint + environment.apiHostVersion;
  public openSide = false;
  public activeItem = 'home';
  public active = false;
  public activeChildItem = '';
  public overlay = false;
  hidePage = false;
  languageList = [];
  content = null;
  langName = '';
  isLoggedIn = false;
  private readonly _destroying$ = new Subject<void>();


  constructor(
    public navServices: NavService,
    public languageService: LanguageService,
    public gymService: GymService,
    public appIntializerService: AppIntializerService,
    private el: ElementRef,
    @Inject(DOCUMENT) private document: any,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    public customize: CustomizerService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.getTranslatedData();
    this.loadLanguageData();
  }

  ngOnInit() {
    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  getTranslatedData() {
    this.languageList = this.languageService.languagesList;
    this.content = localStorage.getItem('data');
    if (this.content === undefined || this.content == null) {
      if (localStorage.getItem('language') === undefined || localStorage.getItem('language') == null) {
        localStorage.setItem('language', 'en');
      }
      this.languageService.getTranslations(localStorage.getItem('language'));
    }
    else {
      this.content = JSON.parse(localStorage.getItem('data'));
    }
  }

  loadLanguageData() {
    this.languageList = this.languageService.languagesList;
    this.changeLanguageText();
  }

  changeLanguage(code) {
    localStorage.setItem('language', code);
    this.gymService.updateCustomerDashboardData();
    this.languageService.updateTranslatedData(code);
    this.changeLanguageText();
  }

  changeLanguageText() {
    let currentLang = localStorage.getItem('language');
    if (currentLang !== undefined && currentLang !== '' && currentLang !== '') {
      let currentLangObj = this.languageList.find(x => x.code == currentLang);
      this.langName = currentLangObj?.name.slice(0, 3);
    }
    else {
      this.langName = 'Eng'; // Default Lang
    }
  }

  /* Below Methods are not in Use */

  toggleSidebar() {
    this.openSide = !this.openSide;
  }

  closeOverlay() {
    this.openSide = false;
  }

  // For Active Main menu in Mobile View
  setActive(menuItem) {
    if (this.activeItem === menuItem) {
      this.activeItem = '';
    } else {
      this.activeItem = menuItem;
    }
  }

  isActive(item) {
    return this.activeItem === item;
  }

  // For Active Child Menu in Mobile View
  setChildActive(subMenu) {
    if (this.activeChildItem === subMenu) {
      this.activeChildItem = '';
    } else {
      this.activeChildItem = subMenu;
    }
  }

  public signInGoogle() {
    this.popupCenter(this.clietnWebAPIUrl + 'sociallogin/google-login', 'Google Login', 800, 800);
  }

  public signInFacebook() {
    this.popupCenter(this.clietnWebAPIUrl + 'sociallogin/facebook-login', 'Facebook Login', 800, 800);
  }


  public popupCenter(url, title, w, h) {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    );
  }

  ischildActive(subMenu) {
    return this.activeChildItem === subMenu;
  }

  showHideLang($event) {
    const showLang = (<HTMLElement>this.el.nativeElement).querySelector('.lang-btn.active');
    if (showLang != null) {
      const langOption = (<HTMLElement>this.el.nativeElement).querySelector('.lang-options');

      langOption.classList.remove('open');
      showLang.classList.remove('active');
    }
    else {
      const showLang1 = (<HTMLElement>this.el.nativeElement).querySelector('.lang-btn');
      const langOption = (<HTMLElement>this.el.nativeElement).querySelector('.lang-options');

      showLang1.classList.add('active');
      langOption.classList.add('open');
    }
  }

  addClasses($event) {

    const toggleMenu = (this.el.nativeElement as HTMLElement)
      .querySelector('.responsive-btn.active');
    if (toggleMenu != null) {
      const newHeader = (this.el.nativeElement as HTMLElement)
        .querySelector('.new-header');
      newHeader.classList.remove('open');

      const newHeaderBlock = (this.el.nativeElement as HTMLElement)
        .querySelector('.new-header-blocks');
      newHeaderBlock.classList.remove('active');

      const responsiveBtn = (this.el.nativeElement as HTMLElement)
        .querySelector('.responsive-btn');
      responsiveBtn.classList.remove('active');

      const newHeaderList = (this.el.nativeElement as HTMLElement)
        .querySelector('.new-header-list');
      newHeaderList.classList.remove('active');

      const headerDetails = (this.el.nativeElement as HTMLElement)
        .querySelector('.header-details');
      headerDetails.classList.remove('active');
    }
    else {
      const newHeader = (this.el.nativeElement as HTMLElement)
        .querySelector('.new-header');
      newHeader.classList.add('open');

      const newHeaderBlock = (this.el.nativeElement as HTMLElement)
        .querySelector('.new-header-blocks');
      newHeaderBlock.classList.add('active');

      const responsiveBtn = (this.el.nativeElement as HTMLElement)
        .querySelector('.responsive-btn');
      responsiveBtn.classList.add('active');

      const newHeaderList = (this.el.nativeElement as HTMLElement)
        .querySelector('.new-header-list');
      newHeaderList.classList.add('active');

      const headerDetails = (this.el.nativeElement as HTMLElement)
        .querySelector('.header-details');
      headerDetails.classList.add('active');
    }
  }

  setLoginDisplay() {
    this.isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  logIn(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logOut() {
    this.authService.logoutRedirect();
  }

  showHideProfile($event) {
    const showProfile = (<HTMLElement>this.el.nativeElement).querySelector('.profile-btn.active');
    if (showProfile != null) {
      const langOption = (<HTMLElement>this.el.nativeElement).querySelector('.profile-options');

      langOption.classList.remove('open');
      showProfile.classList.remove('active');
    }
    else {
      const showProfile1 = (<HTMLElement>this.el.nativeElement).querySelector('.profile-btn');
      const langOption = (<HTMLElement>this.el.nativeElement).querySelector('.profile-options');

      showProfile1.classList.add('active');
      langOption.classList.add('open');
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}

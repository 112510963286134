import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CountToModule } from 'angular-count-to';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { AngularTiltModule } from 'angular-tilt';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { SharedModule } from '../shared/shared.module';
import { TreeviewModule } from 'ngx-treeview';

// Gym Layout
import { GymComponent } from './gym/gym.component';
import { GymNavComponent } from './gym/gym-nav/gym-nav.component';
import { GymHeaderComponent } from './gym/gym-header/gym-header.component';
import { GymAboutComponent } from './gym/gym-about/gym-about.component';
import { GymScheduleComponent } from './gym/gym-schedule/gym-schedule.component';
import { GymWorkoutAboutComponent } from './gym/gym-workout-about/gym-workout-about.component';
import { GymCounterComponent } from './gym/gym-counter/gym-counter.component';
import { GymTrainerComponent } from './gym/gym-trainer/gym-trainer.component';
import { GymTestimonialComponent } from './gym/gym-testimonial/gym-testimonial.component';
import { GymPricingComponent } from './gym/gym-pricing/gym-pricing.component';
import { GymBMIComponent } from './gym/gym-bmi/gym-bmi.component';
import { GymBrandComponent } from './gym/gym-brand/gym-brand.component';
import { GymFooterComponent } from './gym/gym-footer/gym-footer.component';
import { GymCopyrightComponent } from './gym/gym-copyright/gym-copyright.component';
import { PortfolioComponentComponent } from './portfolio/portfolio-component/portfolio-component.component';
import { PortfolioSlideComponent } from './portfolio/portfolio-slide/portfolio-slide.component';
import { ProductComponent } from './product/product/product.component';
import { CategoryComponent } from './product/category/category.component';
import { ProductHeaderComponent } from './product/product-header/product-header.component';
import { ProductFooterComponent } from './product/product-footer/product-footer.component';
import { ProductCategoriesComponent } from './product/product-categories/product-categories.component';
import { ProductPriceComponent } from './product/product-price/product-price.component';
import { ProductNewComponent } from './product/product-new/product-new.component';
import { ProductBoxComponent } from './product/product-box/product-box.component';
import { CategoryBoxComponent } from './product/category-box/category-box.component';
import { SubCategoryComponent } from './product/subcategory/subcategory.component';
import { SubCategoryBoxComponent } from './product/subcategory-box/subcategory-box.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidebarComponent } from './product/sidebar/sidebar.component';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { ProductCartComponent } from './product/product-cart/product-cart.component';
import { CheckoutComponent } from './product/checkout/checkout.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { SuccessComponent } from './product/success/success.component';
import { OrderDetailsComponent } from './product/order-details/order-details.component';
import { OrderListComponent } from './product/order-list/order-list.component';
import { BlogDetailsComponent } from './blogs/blog-details/blog-details.component';
import { BlogListComponent } from './blogs/blog-list/blog-list.component';
import { NgbTypeaheadModule } from "@ng-bootstrap/ng-bootstrap";
import { ProductTypeheadComponent } from './product/product-typehead/product-typehead.component';
import { ManifestoComponent } from './manifesto/manifesto.component';
import { NewsroomCategoryComponent } from './blogs/newsroom-category/newsroom-category.component';
import { NewsroomSubcategoryComponent } from './blogs/newsroom-subcategory/newsroom-subcategory.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
  declarations: [
    GymComponent
    , GymNavComponent
    , GymHeaderComponent
    , GymAboutComponent
    , GymScheduleComponent
    , GymWorkoutAboutComponent
    , GymCounterComponent
    , GymTrainerComponent
    , GymTestimonialComponent
    , GymPricingComponent
    , GymBMIComponent
    , GymBrandComponent
    , GymFooterComponent
    // tslint:disable-next-line:max-line-length
    , GymCopyrightComponent, PortfolioComponentComponent, PortfolioSlideComponent,
    ProductComponent, ProductHeaderComponent, ProductFooterComponent, ProductCategoriesComponent,
     // tslint:disable-next-line:max-line-length
     ProductPriceComponent, ProductNewComponent, ProductBoxComponent, SidebarComponent, ProductDetailsComponent, ProductCartComponent, CheckoutComponent, SuccessComponent, OrderDetailsComponent, OrderListComponent,
    MyprofileComponent,
    BlogDetailsComponent,
    BlogListComponent,
    ProductTypeheadComponent,
    ManifestoComponent,
    CategoryComponent,CategoryBoxComponent,SubCategoryComponent, SubCategoryBoxComponent, NewsroomCategoryComponent, NewsroomSubcategoryComponent
  ],

  imports: [
    CommonModule,
    LayoutsRoutingModule,
    SwiperModule,
    CarouselModule,
    NgbModule,
    GalleryModule.forRoot(),
    SharedModule,
    CountToModule,
    AngularTiltModule,
    ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    Ng5SliderModule,
    NgxMasonryModule,
    // BrowserAnimationsModule
    TreeviewModule.forRoot(),
    NgbTypeaheadModule
  ],

  exports: [],

  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})

export class LayoutsModule { }

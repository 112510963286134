import { CartService } from './../../../shared/service/e-commerce/cart.service';
import { ProductsService } from './../../../shared/service/e-commerce/products.service';
import { WishListService } from './../../../shared/service/e-commerce/wish-list.service';
import { Category, Products } from './../../../shared/model/e-commerce/product.model';
import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from "../../../shared/service/gym/language.service";
import { AppIntializerService } from '../../../shared/service/app-intializer.service';

@Component({
  selector: 'app-category-box',
  templateUrl: './category-box.component.html',
  styleUrls: ['./category-box.component.scss']
})
export class CategoryBoxComponent implements OnInit {

  @Input() category: Category;
  closeResult: string;
  public selectedSize: any = '';
  public counter = 1;
  langContent = null;

  constructor(private cartService: CartService,
    public productsService: ProductsService, private wishlistService: WishListService,
    private modalService: NgbModal,
    public languageService: LanguageService,
    public appIntializerService: AppIntializerService,
    private toastr: ToastrService) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.langContent = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.getTranslatedData();
  }

  ngOnInit() {
  }

  getTranslatedData() {
    this.langContent = this.appIntializerService.languageData;
  }
}

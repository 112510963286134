import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './../api.service';
import { ToastrService } from 'ngx-toastr';
import { SingleResponse } from '../../model/single-response';

@Injectable({
  providedIn: 'root'
})
export class MyprofileService {

  constructor(private httpClient: HttpClient, private baseService: ApiService,
    private toastr: ToastrService) {

  }

  public getMyProfile(): Promise<any> {
    return this.httpClient.get(this.baseService.APIUrl + "MyProfile/GetDetails").toPromise()
      .then((result: any) => {
        return result;
      })
      .catch((error) => {
        this.toastr.error('Something went wrong');
        return 0;
      });
  }

  public async SaveMyDetails(myDetails: object): Promise<SingleResponse<any>> {
    return this.httpClient.post(this.baseService.APIUrl + 'MyProfile', myDetails).toPromise()
      .then((result: any) => {
        this.toastr.success('Details has been updated successfully.');
        return result;
      })
      .catch((errorResult) => {
        this.toastr.error(errorResult.error);
        return null;
      });
  }

  public removeAddress(id: number) {
    const obj = {
      addressId: id
    };

    return this.httpClient.post(this.baseService.APIUrl + 'CustomerAddress/RemoveCustomerAddress', obj).toPromise()
      .then((result: any) => {
        this.toastr.success('Selected address has been removed.');
      })
      .catch((errorResult) => {
        this.toastr.error(errorResult.error);
        return null;
      });
  }

  public addAddresses(address: object): Promise<SingleResponse<any>> {
    return this.httpClient.post(this.baseService.APIUrl + 'CustomerAddress/SaveCustomerAddress', address).toPromise()
      .then((result: any) => {
        this.toastr.success('Address has been added successfully.');
        return result;
      })
      .catch((errorResult) => {
        this.toastr.error(errorResult.error);
        return null;
      });
  }

}

import { element } from 'protractor';
import { GallaryService } from './../../../shared/service/gym/gallary.service';
import { Component, OnInit } from '@angular/core';
import {
  AdvancedLayout, ButtonsConfig, ButtonsStrategy, Image, KS_DEFAULT_BTN_CLOSE, KS_DEFAULT_BTN_DELETE,
  KS_DEFAULT_BTN_DOWNLOAD, KS_DEFAULT_BTN_EXTURL, KS_DEFAULT_BTN_FULL_SCREEN, PlainGalleryConfig,
  PlainGalleryStrategy
} from '@ks89/angular-modal-gallery';
import { ColorScssService } from '../../../shared/service/color-scss.service';


@Component({
  selector: 'app-portfolio-slide',
  templateUrl: './portfolio-slide.component.html',
  styleUrls: ['./portfolio-slide.component.scss']
})
export class PortfolioSlideComponent implements OnInit {

  public customizer: any = 'all';
  allImages: Image[] = [];

  index = 1;
  size = 8;
  pageCollectionSize: number;

  buttonsConfigCustom: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [
      KS_DEFAULT_BTN_FULL_SCREEN,
      KS_DEFAULT_BTN_DELETE,
      KS_DEFAULT_BTN_EXTURL,
      KS_DEFAULT_BTN_DOWNLOAD,
      KS_DEFAULT_BTN_CLOSE
    ]
  };

  customPlainGalleryRowDescConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  constructor(private displayblock: ColorScssService, private gallaryService: GallaryService) {
    this.getGallaryData();
  }

  async getGallaryData() {
    this.gallaryService.getGallaryData(this.index, this.size).then(items => {
      this.allImages=[];
      items?.data?.data.forEach((item: any) => {
        this.allImages.push({
          id: item.id,
          modal: {
            img: item.contentData,
            title: item.name,
            description: item.description
          }
        } as Image);
      });
      this.pageCollectionSize = items?.data?.totalCount;
    });
  }

  openImageModalRowDescription(image: Image) {
    const index: number = this.getCurrentIndexCustomLayout(image, this.allImages);
    this.customPlainGalleryRowDescConfig = Object.assign(
      {}, this.customPlainGalleryRowDescConfig, { layout: new AdvancedLayout(index, true) });
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

  openGallery(val) {
    this.customizer = val;
  }

  ngOnInit() {
  }

  onPageChange(){
    this.getGallaryData();
  }
}

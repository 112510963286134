import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs/internal/observable/throwError';
import { map, catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  public APIUrl: string = environment.apiHostStartPoint + environment.apiHostVersion;
  public clietnWebAPIUrl: string = environment.apiHostStartPoint + environment.apiHostVersion;

  constructor(private http: HttpClient) { }

  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    })
  };

  Get<T>(uri: string, param: any) {
    return this.http
      .get<T>(`${uri}`, {
        params: param,
        headers: this.httpOptions.headers
      });
  }

  GetById<T>(uri: string, param: any) {
    return this.http.get<T>(`${uri}/${param}`).pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  GetAll<T>(uri: string) {
    return this.http.get<T>(`${uri}`).pipe(
      retry(1),
      catchError(this.errorHandler)
    );
  }

  errorHandler(error) {
    let message = '';
    if (error.error instanceof ErrorEvent) {
      message = error.error.message;
    } else {
      message = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(message);
  }
}

<!--breadcrumb section start-->
<section class="agency breadcrumb-section breadcrumb-right" [ngStyle]="{'background-image': 'url(assets/images/inner-page/breadcrumb-plain.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-6 order-lg-2">
                <h2 class="breadcrumb-text text-right">features with breadcrumb right</h2>
            </div>
            <div class="col-6">
                <ul class="breadcrumb justify-content-start">
                   
                    <li><a href="#">breadcrumb style</a></li>
                    <li>breadcrumb right</li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!--breadcrumb section end-->

<app-breadcrumb-layout-content></app-breadcrumb-layout-content>
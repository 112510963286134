import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GallaryService {
  constructor(
    private baseService: ApiService,
    private http: HttpClient
  ) { }

  public getGallaryData(index: number, size: number): Promise<any> {
    return this
      .baseService
      .GetAll<any>(
        this.baseService.clietnWebAPIUrl +
        'Gallery?index=' + index + "&size=" + size).toPromise();
  }

}

import {
  ColorFilter,
  Products,
} from "./../../../shared/model/e-commerce/product.model";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LanguageService } from "src/app/shared/service/gym/language.service";
import { ProductsService } from "src/app/shared/service/e-commerce/products.service";
import { Subject } from "rxjs/internal/Subject";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  content = null;
  public products: Products[] = [];
  public items: Products[] = [];
  public allItems: Products[] = [];
  public colorFilters: ColorFilter[] = [];
  public tagsFilters: any[] = [];
  public tags: any[] = [];
  public colors: any[] = [];
  public sortByOrder = ""; // sorting
  public animation: any; // Animation
  public minPrice: number = 0;
  public maxPrice: number = 0;
  public priceValue: number = 0;
  public highValue: number = 0;
  minAndmaxPrice$: Subject<any> = new Subject<any>();
  public categoryId: string = "";
  public subCategoryId: string = "";
  productSearchTerm: string = "";

  lastKey = ""; // key to offset next query from
  finished = false; // boolean when end of data is reached

  sidebaron = false;
  show = false;
  open = false;
  index = 1;
  size = 8;
  throttle = 300;
  scrollDistance = 1;
  scrollUpDistance = 2;
  pageCollectionSize: number;

  public listView = false;
  public col_xl_3 = true;
  public col_xl_4 = false;
  public col_md_6 = true;
  public col_xl_12 = false;
  public gridOptions = true;
  public active = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public languageService: LanguageService,
    public productsService: ProductsService
  ) {
    this.route.params.subscribe((params) => {
      this.categoryId = params.categoryid;
      this.subCategoryId = params.subcategoryid;
    });

    this.getProducts();
    this.languageService.languageDataSubject$.subscribe((res) => {
      this.content = res;
      localStorage.setItem("data", JSON.stringify(res));
    });

    this.content = JSON.parse(localStorage.getItem("data"));
  }

  ngOnInit() {}

  public getProducts(
    minPrice = 0,
    maxPrice = 0,
    category = "",
    subCategory = ""
  ) {
    //this.SetProductFilter(minPrice, maxPrice, category, subCategory);

    this.productsService
      .getProductByCategory(
        "all",
        this.index,
        this.size,
        this.categoryId,
        this.subCategoryId,
        this.minPrice,
        this.maxPrice,
        this.productSearchTerm
      )
      .then((res) => {
        if (res !== undefined) {
          this.products = res;

          this.minPrice = res[0].minPrice;
          this.maxPrice = res[0].maxPrice;

          if (this.priceValue == 0) {
            this.priceValue = this.minPrice;
          }
          if (this.highValue == 0) {
            this.highValue = this.maxPrice;
          }

          this.minAndmaxPrice$.next({
            minPrice: this.minPrice,
            maxPrice: this.maxPrice,
            priceValue: this.priceValue,
            highValue: this.highValue,
          });
        }
        this.allItems = this.products; // all products
        this.pageCollectionSize = this.productsService.productTotalCount;
        this.getTags(this.products);
        this.getColors(this.products);
      });
  }

  public SetProductFilter(
    minPrice = 0,
    maxPrice = 0,
    category = "",
    subCategory = ""
  ) {
    if (minPrice != 0) {
      this.minPrice = minPrice;
    }
    if (maxPrice != 0) {
      this.maxPrice = maxPrice;
    }
    if (category != "") {
      this.categoryId = category;
    }
    if (subCategory != "") {
      this.subCategoryId = subCategory;
    }
  }

  // Get current product tags
  public getTags(products) {
    const uniqueBrands = [];
    const itemBrand = Array();
    products.map((product, index) => {
      if (product.tags) {
        product.tags.map((tag) => {
          // tslint:disable-next-line:no-shadowed-variable
          const index = uniqueBrands.indexOf(tag);
          if (index === -1) {
            uniqueBrands.push(tag);
          }
        });
      }
    });
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < uniqueBrands.length; i++) {
      itemBrand.push({ brand: uniqueBrands[i] });
    }
    this.tags = itemBrand;
  }

  // Get current product colors
  public getColors(products) {
    const uniqueColors = [];
    const itemColor = Array();
    products.map((product, index) => {
      if (product.colors) {
        product.colors.map((color) => {
          // tslint:disable-next-line:no-shadowed-variable
          const index = uniqueColors.indexOf(color);
          if (index === -1) {
            uniqueColors.push(color);
          }
        });
      }
    });
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < uniqueColors.length; i++) {
      itemColor.push({ color: uniqueColors[i] });
    }
    this.colors = itemColor;
  }

  // Update price filter
  public updatePriceFilters(price: any) {
    const pricemin = price[0];
    const maxPrice = price[1];
    const items: any[] = [];
    this.products.filter((item: Products) => {
      if (item.price >= pricemin && item.price <= maxPrice) {
        items.push(item); // push in array
      }
    });
    this.items = items;
  }

  // Initialize filetr Items
  public filterItems(): Products[] {
    // Once tag,price,color filter introduced then repalce allItems with items
    return this.products.filter((item: Products) => {
      const Colors: boolean = this.colorFilters.reduce((prev, curr) => {
        // Match Color
        if (item.colors) {
          if (item.colors.includes(curr.color)) {
            return prev && true;
          }
        }
      }, true);
      const Tags: boolean = this.tagsFilters.reduce((prev, curr) => {
        // Match Tags
        if (item.tags) {
          if (item.tags.includes(curr)) {
            return prev && true;
          }
        }
      }, true);
      return Colors && Tags; // return true
    });
  }

  onPageChange() {
    this.getProducts(this.priceValue, this.highValue);
  }

  // Update tags filter
  public updateTagFilters(tags: any[]) {
    this.tagsFilters = tags;
  }

  // Update color filter
  public updateColorFilters(colors: ColorFilter[]) {
    this.colorFilters = colors;
  }

  // sorting type ASC / DESC / A-Z / Z-A etc.
  public onChangeSorting(val) {
    this.sortByOrder = val;
  }

  openFilter() {
    if (this.show === true && this.sidebaron === true) {
      this.show = false;
      this.sidebaron = false;
    } else {
      this.show = true;
      this.sidebaron = true;
    }
  }
  openMediaFilter() {
    if (
      this.show === false &&
      this.sidebaron === false &&
      this.open === false
    ) {
      this.show = true;
      this.sidebaron = true;
      this.open = true;
    } else {
      this.show = false;
      this.sidebaron = false;
      this.open = false;
    }
  }

  listOpen() {
    this.gridOptions = false;
    this.listView = true;
    this.col_xl_12 = true;
  }

  grid2() {
    this.gridOptions = true;
    this.col_md_6 = true;
    this.col_xl_3 = false;
    this.listView = false;
    this.col_xl_4 = false;
    this.col_xl_12 = false;
  }

  grid3() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_4 = true;
    this.col_xl_3 = false;
    this.col_md_6 = true;
    this.col_xl_12 = false;
  }

  grid4() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_3 = true;
    this.col_md_6 = true;
    this.col_xl_4 = false;
    this.col_xl_12 = false;
  }

  toggleFilter() {
    this.productsService.filterBar = !this.productsService.filterBar;
  }

  // Close sidebar on click on outside the sidebar
  closeOverlay() {
    this.productsService.filterBar = false;
  }
}


<section class="btn-section">
    <div class="container margin-buttons">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>default button</span></h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <a href="javascript:void(0)" class="btn btn-default primary-btn">default</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn success">success</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn info">info</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn warning">warning</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn danger">danger</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn light">light</a>
                    <a href="javascript:void(0)" class="btn btn-default btn-gradient">gradient</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="btn-section bg-light-inner">
    <div class="container margin-buttons">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0">
                            <span>default border button </span></h2>
                    </div>

                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <a href="javascript:void(0)" class="btn btn-default primary-btn back-white">default</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn success back-white">success</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn info back-white">info</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn warning back-white">warning</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn danger back-white">danger</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn light back-white">light</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="btn-section">
    <div class="container margin-buttons">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0">
                            <span>default square button </span></h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <a href="javascript:void(0)" class="btn btn-default primary-btn  radius-0">default</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn success  radius-0">success</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn info  radius-0">info</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn warning  radius-0">warning</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn danger  radius-0">danger</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn light  radius-0">light</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="btn-section bg-light-inner">
    <div class="container margin-buttons">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>default square border button </span>
                        </h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <a href="javascript:void(0)" class="btn btn-default primary-btn  radius-0 back-white">default</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn success  radius-0 back-white">success</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn info  radius-0 back-white">info</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn warning  radius-0 back-white">warning</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn danger  radius-0 back-white">danger</a>
                    <a href="javascript:void(0)" class="btn btn-default primary-btn light  radius-0 back-white">light</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="p-b-0">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>event 1</span></h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
    <section class="yoga event bg bg-gradient-color">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="event-container d-flex">
                        <div class="yoga-circle">
                            <h3 class="gradient-text">03</h3>
                            <h6 class="month">Auguest</h6>
                        </div>
                        <div class="event-info center-content">
                            <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                            <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                            <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>Newyork
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="event-container d-flex">
                        <div class="yoga-circle">
                            <h3 class="gradient-text">03</h3>
                            <h6 class="month">March</h6>
                        </div>
                        <div class="event-info center-content">
                            <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                            <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                            <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>Newyork
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="event-container d-flex">
                        <div class="yoga-circle">
                            <h3 class="gradient-text">03</h3>
                            <h6 class="month">January</h6>
                        </div>
                        <div class="event-info center-content">
                            <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                            <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                            <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>Australia
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="event-container d-flex">
                        <div class="yoga-circle">
                            <h3 class="gradient-text">03</h3>
                            <h6 class="month">Jun</h6>
                        </div>
                        <div class="event-info center-content">
                            <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                            <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                            <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>US
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="event-container d-flex">
                        <div class="yoga-circle">
                            <h3 class="gradient-text">03</h3>
                            <h6 class="month">July</h6>
                        </div>
                        <div class="event-info center-content">
                            <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                            <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                            <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>UK
                            </h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="event-container d-flex">
                        <div class="yoga-circle">
                            <h3 class="gradient-text">03</h3>
                            <h6 class="month">April</h6>
                        </div>
                        <div class="event-info center-content">
                            <h4 class="text-white address">Menla Mountain Retreat Center: Catskills, NY</h4>
                            <h6 class="text-white time">August 3, 2018 @ 8:00 am - September 30, 2019 @ 5:00 pm</h6>
                            <h6 class="text-white city"><i class="fa fa-map-marker m-r-5" aria-hidden="true"></i>India</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    <section class="event schedule set-relative" id="schedule">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title title2 title-inner">
                        <div class="main-title">
                            <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>schedule 1</span></h2>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="cal-time">
                        <div class="time">
                            <h4 class="text-capitalize monday-text">Monday, September 30, 2018</h4>
                        </div>
                    </div>
                    <div class="event-container scrollbar">
                        <div>
                            <div class=" cal-time events">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="schedule-event">
                                            <h5 class="names">Vicky Smith</h5>
                                            <img src="assets/images/event/icon.png" alt="" class="img-fluid schedule-img">
                                            <p class="schedule-description">Lorem Ipsum is simply dummy text of the printing
                                                and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="timing">
                                            <h5 class="event-time">08:00</h5>
                                            <div class="border-container"><div class="border-dashed"></div></div>
                                            <h5 class="event-time">10:00</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" cal-time events">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="schedule-event">
                                            <h5 class="names">Vicky Smith</h5>
                                            <img src="assets/images/event/icon.png" alt="" class="img-fluid schedule-img">
                                            <p class="schedule-description">Lorem Ipsum is simply dummy text of the printing
                                                and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="timing">
                                            <h5 class="active event-time center-content">08:00</h5>
                                            <div class="border-container">
                                                <div class="border-dashed active"></div>
                                            </div>
                                            <h5 class="active event-time center-content">10:00</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="cal-time events">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="schedule-event">
                                            <h5 class="names">Vicky Smith</h5>
                                            <img src="assets/images/event/icon.png" alt="" class="img-fluid schedule-img">
                                            <p class="schedule-description">Lorem Ipsum is simply dummy text of the printing
                                                and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="timing">
                                            <h5 class="event-time center-content">08:00</h5>
                                            <div class="border-container"><div class="border-dashed"></div></div>
                                            <h5 class="event-time center-content">10:00</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class=" cal-time events">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="schedule-event">
                                            <h5 class="names">Vicky Smith</h5>
                                            <img src="assets/images/event/icon.png" alt="" class="img-fluid schedule-img">
                                            <p class="schedule-description">Lorem Ipsum is simply dummy text of the printing
                                                and typesetting industry. Lorem Ipsum has been the industry's standard dummy
                                                text ever since the 1500s, when an unknown printer took a galley of.</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="timing">
                                            <h5 class="event-time center-content">08:00</h5>
                                            <div class="border-container"><div class="border-dashed"></div></div>
                                            <h5 class="event-time center-content">10:00</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
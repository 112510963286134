import { Subscription } from 'rxjs/internal/Subscription';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../../shared/service/e-commerce/cart.service';
import { ProductsService } from '../../../shared/service/e-commerce/products.service';

@Component({
  selector: 'app-product-header',
  templateUrl: './product-header.component.html',
  styleUrls: ['./product-header.component.scss']
})
export class ProductHeaderComponent implements OnInit {

  constructor(private cartService: CartService, private productService: ProductsService) {
  }

  ngOnInit() {
  }
}

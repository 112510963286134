<div class="agency">
  <app-header></app-header>
 <!-- breadcrumb section start -->
 <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="breadcrumb-contain ">
          <div>
            <h2 class="breadcrumb-txt">Portfolio</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--breadcrumb section end -->
  <section class="portfolio-section titles masonray-sec zoom-gallery">
    <div class="filter-section">
      <div class="container">
        <app-portfolio-slide class="p-4col-width"></app-portfolio-slide>
      </div>
    </div>
  </section>
  <app-footer></app-footer>
</div>
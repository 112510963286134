import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/shared/service/blog/blog.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';

@Component({
  selector: 'app-newsroom-category',
  templateUrl: './newsroom-category.component.html',
  styleUrls: ['./newsroom-category.component.scss']
})
export class NewsroomCategoryComponent implements OnInit {

  constructor(
    public languageService: LanguageService, private blogService: BlogService) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));
    this.getNewsRoomCategories();
  }

  ngOnInit(): void {
  }
  content = null;
  public newsRoomCategories: any;
  public listView = false;
  public col_xl_3 = true;
  public col_xl_4 = false;
  public col_md_6 = true;
  public col_xl_12 = false;
  public gridOptions = true;
  public active = false;
  public tagsFilters: any[] = [];
  sidebaron = false;
  show = false;
  open = false;

  async getNewsRoomCategories() {
    this.blogService.getNewsRoomCategories().then(items => {
      this.newsRoomCategories = items?.data?.data;
    });
  }
}

import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class GymService {

  dashBoardDataSubject$: Subject<any> = new Subject<any>();
  constructor(
    private baseService: ApiService,
    private http: HttpClient
  ) { }

  public updateCustomerDashboardData() {
    this.getCustomerDashboardData().then(x => {
      this.dashBoardDataSubject$.next(x);
    });
  }

  public getCustomerDashboardData(): Promise<any> {
    return this
      .baseService
      .GetAll<any>(
        this.baseService.clietnWebAPIUrl +
        'CustomerManageDashboard/Get').toPromise();
  }
}

<div class="agency">
  <app-product-header></app-product-header>
  <!-- breadcrumb section start -->
  <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb-contain ">
            <div>
              <h2 class="breadcrumb-txt">{{content?.ShopWithCart}}</h2>
            </div>
            <div>
              <ul>
                <li><a [routerLink]="['/shop']">{{content?.MenuShop}}</a></li>
                <li><a href="#"><i class="fa fa-angle-double-right"></i>{{content?.Cart}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--breadcrumb section end -->

  <!-- section start -->
  <section class="cart-section section-b-space">
    <div class="container" *ngIf="shoppingCartItems">
      <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!shoppingCartItems.length">
        <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
        <h3><strong>{{ content?.YourCartIsEmpty }}</strong></h3>
        <h4>{{ content?.AddSomethingToMakeMeHappy }} :)</h4>
        <a [routerLink]="['/e-commerce']" class="btn btn-default primary-btn radius-0 m-t-10">{{ content?.ContinueShopping }}</a>
      </div>
      <div class="row" *ngIf='shoppingCartItems.length'>
        <div class="col-sm-12">
          <table class="table cart-table table-responsive-xs">
            <thead>
              <tr class="table-head">
                <th scope="col">{{content?.Image}}</th>
                <th scope="col">{{content?.ProductName}}</th>
                <th scope="col">{{content?.Price}}price</th>
                <th scope="col">{{content?.Quantity}}</th>
                <th scope="col">{{content?.Action}}</th>
                <th scope="col">{{content?.Total}}</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of shoppingCartItems">
              <tr>
                <td>
                  <a [routerLink]="['/shop/product/', item.productId]">
                    <img class="imgSize" [src]="item.posterImage" alt="">
                  </a>
                </td>
                <td>
                  <a [routerLink]="['/shop/product/', item.productId]">{{ item.productName }}</a>
                  <div class="mobile-cart-content row">
                    <div class="col-xs-3">
                      <div class="qty-box">
                        <div class="input-group">
                          <input type="text" name="quantity" class="form-control input-number" disabled
                            [value]="item.qty">
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-3">
                      <h2 class="td-color">{{ item.price | currency:productsService?.currency:'symbol' }}</h2>
                    </div>
                    <div class="col-xs-3">
                      <h2 class="td-color">
                        <a (click)="removeItem(item)" class="icon">
                          <i class="ti-close"></i>
                        </a>
                      </h2>
                    </div>
                  </div>
                </td>
                <td>
                  <h2>{{ item.price | currency:productsService?.currency:'symbol' }}</h2>
                </td>
                <td>
                  <div class="qty-box">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <button type="button" class="btn quantity-left-minus" (click)="decrement(item)"
                          data-type="minus" data-field="">
                          <i class="fa fa-chevron-left"></i>
                        </button>
                      </span>
                      <input type="text" name="quantity" disabled class="form-control input-number"
                        [(ngModel)]="item.qty">
                      <span class="input-group-prepend">
                        <button type="button" class="btn quantity-right-plus" (click)="increment(item)" data-type="plus"
                          data-field="">
                          <i class="fa fa-chevron-right"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <a [routerLink]="[]" (click)="removeItem(item)" class="icon">
                    <i class="fa fa-times"></i>
                  </a>
                </td>
                <td>
                  <h2 class="td-color">{{ item.price * item.qty | currency:productsService?.currency:'symbol' }}</h2>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table cart-table table-responsive-md">
            <tfoot>
              <tr>
                <td>{{content?.TotalPrice}}</td>
                <td>
                  <h2> {{ totalCount | currency:productsService?.currency:'symbol' }}</h2>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
      <div class="row cart-buttons" *ngIf='shoppingCartItems.length'>
        <div class="col-6">
          <a [routerLink]="['/shop']" class="btn btn-default primary-btn radius-0 m-t-10">{{content?.ContinueShopping}}
          </a>
        </div>
        <div class="col-6">
          <a [routerLink]="['/shop/cart/checkout']"
            class="btn btn-default primary-btn ra-dius-0 m-t-10">{{content?.CheckOut}}</a>
        </div>
      </div>
    </div>
  </section>
  <!--section end-->
  <app-product-footer></app-product-footer>
</div>
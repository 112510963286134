// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiHostStartPoint: 'https://svartsvanewebapi.azurewebsites.net',
  apiHostVersion: '/api/',
  Msal: {
    Config:
    {
      signUpSignIn: 'B2C_1_social',
      authority: 'https://svartsvanecustomers.b2clogin.com/svartsvanecustomers.onmicrosoft.com/B2C_1_social',
      authorityDomain: 'svartsvanecustomers.b2clogin.com',
      clientId: 'c8204eb8-6354-40f2-978c-dedaf3bfbf36',
      redirectUri: 'https://svartsvane.com/',
      protectedAPIs: {
        scopes: ['https://svartsvanecustomers.onmicrosoft.com/c8204eb8-6354-40f2-978c-dedaf3bfbf36/user.read'],
        protectedAPIEndPoints: [
          'Order/AddToCart',
          'Order/GetCartProducts',
          'Order/UpdateOrderQuantity',
          'Order/DownloadInvoice',
          'CustomerAddress/GetAllCustomerAddress',
          'CustomerAddress/SaveCustomerAddress',
          'Order/PlaceOrder',
          'Order/RemoveCartProduct',
          'CustomerAddress/RemoveCustomerAddress',
          'MyProfile/GetDetails',
          'Order/GetOrderedProductsByOrderNumber',
          'Order/GetCustomerOrderedList',
          'MyProfile',
          'Order/GetCheckoutSession'          
        ]
      }
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

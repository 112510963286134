/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */

import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
        signUpSignIn: environment.Msal.Config.signUpSignIn
        //  editProfile: 'b2c_1_edit_profile_v2'
    },
    authorities: {
        signUpSignIn: {
            authority: environment.Msal.Config.authority
        },
        //  editProfile: {
        //      authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2'
        //  }
    },
    authorityDomain: environment.Msal.Config.authorityDomain
};

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
export const msalConfig: Configuration = {
    auth: {
        clientId: environment.Msal.Config.clientId, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Defaults to "https://login.microsoftonline.com/common"
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: environment.Msal.Config.redirectUri,
        postLogoutRedirectUri: environment.Msal.Config.redirectUri,
        // If "true", will navigate back to the original request location before processing the auth code response.
        navigateToLoginRequestUrl: false,
    },
    cache: {
        // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or 
    },
    system: {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
                console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
        },
    },
};

export const protectedResources = {
    resourceEndPoints: APIEndPoints(),
    scopes: environment.Msal.Config.protectedAPIs.scopes,
};


function APIEndPoints(): Map<string, Array<string>> {
    let endpoints = new Map<string, Array<string>>();
    for (let i = 0; i < environment.Msal.Config.protectedAPIs.protectedAPIEndPoints.length; i++) {
        const element = environment.apiHostStartPoint + environment.apiHostVersion + environment.Msal.Config.protectedAPIs.protectedAPIEndPoints[i];
        endpoints.set(element, environment.Msal.Config.protectedAPIs.scopes);
    }
    return endpoints;
}
<app-content></app-content>
<!-- subscribe section Start-->
<footer class="app1 subscribe light" id="contact" [ngStyle]="{'background-image': 'url(assets/images/app_landing1/subscribe/subscribe.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="title title1">
                    <div class="main-title">
                        <h2>Subscribe to our newsletter</h2>
                    </div>
                    <div class="sub-title">
                        <p class="para">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8 offset-lg-2">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="d-flex">
                                <input class="form-control " name="email"
                                       placeholder="Please Enter Your Email Address"
                                       type="email">
                                <div class="button-primary">
                                    <button class=" btn btn-default btn-gradient text-white text-uppercase"
                                            type="submit">subscribe
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="socials-lists m-t-50">
                    <ul class="socials-horizontal justify-content-center">
                        <li>
                            <a href="#"><i aria-hidden="true" class="fa fa-facebook center-content"></i></a>
                        </li>
                        <li>
                            <a href="#"><i aria-hidden="true"
                                           class="fa fa-twitter center-content"></i></a>
                        </li>
                        <li>
                            <a href="#"><i aria-hidden="true"
                                           class="fa fa-google center-content"></i></a>
                        </li>
                        <li>
                            <a href="#"><i aria-hidden="true" class="fa fa-instagram center-content"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- subscribe section end-->

<!-- copyright section Start-->
<div class="app1 copyright">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="center-content">
                    <div>
                        <h6 class="copyright-text text-white">Copyright © 2019, Development by Pixelstrap</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- copyright section end-->

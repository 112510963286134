import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { BlogService } from 'src/app/shared/service/blog/blog.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent implements OnInit {

  constructor(
    public languageService: LanguageService, private blogService: BlogService, private sanitizer: DomSanitizer) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));
  }

  content = null;
  public blogData: any;
  public latestBlogData: any;

  public myOptions: NgxMasonryOptions = {
    transitionDuration: '0.8s',
    originTop: true
  };

  index = 1;
  size = 8;
  pageCollectionSize: number;

  ngOnInit(): void {
    // this.blogData = this.column;
    this.getArticles();
    this.getLatestArticles();
  }

  async getArticles() {
    this.blogService.getArticles(this.index, this.size).then(items => {
      console.log(items);
      this.pageCollectionSize = items?.data?.totalCount;
      this.blogData = items?.data?.data;
    });
  }

  async getLatestArticles() {
    this.blogService.getLatestArticles().then(items => {
      this.pageCollectionSize = items?.data?.totalCount;
      this.latestBlogData = items?.data?.data;

      for (const blog of this.latestBlogData) {
        blog.content = this.renderHtmlContent(blog.content);
      }
    });
  }

  onPageChange() {
    this.getArticles();
  }

  renderHtmlContent(htmlContent: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

}

<div class="agency">
    <app-product-header></app-product-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">{{content?.Orders}}</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a [routerLink]="['/shop']">{{content?.MenuShop}}</a></li>
                                <li><a [routerLink]="['/shop/orders']"><i
                                            class="fa fa-angle-double-right"></i>{{content?.Orders}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->

    <!-- section start -->
    <section class="cart-section section-b-space">
        <div class="container table-responsive">
            <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!orderedItems.length">
                <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
                <h3><strong>{{content?.NoOrder}}</strong></h3>
                <a [routerLink]="['/shop']" class="btn btn-default primary-btn radius-0 m-t-10">continue shopping</a>
              </div>
            <table class="table table-hover table-striped">
                <tbody>
                    <tr *ngFor="let item of orderedItems" (click)="redirectToOrderDetails(item.orderNumber)">
                        <td colspan="3">
                            <span class="float-left"><b>Order# </b>{{item.orderNumber}}</span>
                        </td>
                        <td colspan="3">
                            <span class="float-left"><b>Date: </b>{{item.orderDate}}</span>
                        </td>
                        <td colspan="3">
                            <span class="float-left"><b>Status: </b>{{item.status}}</span>
                        </td>
                        <td colspan="3">
                            <span class="float-left"><b>Total: </b>{{item.orderTotalAmount | currency:productsService?.currency:'symbol'}}</span>
                        </td>
                        <td colspan="3" onclick=event.stopPropagation()>
                            <a (click)="downloadOrderInvoice(item.orderNumber)" class="float-left"><i  class="fa fa-download" aria-hidden="true"></i> Download</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
    <!--section end-->
    <!-- section End -->
    <!-- pagination Start -->
    <div class="pagination_sec mb-3">
        <div class="content_detail__pagination cdp">
            <ngb-pagination class="d-flex justify-content-center" [collectionSize]="pageCollectionSize" #numPages
                [pageSize]="size" [(page)]="index" (pageChange)="onPageChange()" [maxSize]="10" [rotate]="true"
                [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
        </div>
    </div>
    <!-- pagination End -->
    <app-product-footer></app-product-footer>
</div>
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { Products } from 'src/app/shared/model/e-commerce/product.model';
import { CartService } from 'src/app/shared/service/e-commerce/cart.service';
import { ProductsService } from 'src/app/shared/service/e-commerce/products.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';

@Component({
  selector: 'app-product-cart',
  templateUrl: './product-cart.component.html',
  styleUrls: ['./product-cart.component.scss']
})
export class ProductCartComponent implements OnInit {

  public shoppingCartItems: Products[];
  public totalCount: number;
  content = null;

  constructor(private productsService: ProductsService,
              public languageService: LanguageService,
              private cartService: CartService,
              private toastr: ToastrService) {
    this.getItems();
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.cartService.cartItems$.subscribe(cartItems => {
      this.shoppingCartItems = cartItems.data.cartItems ?? [];
      this.totalCount = cartItems.data.totalAmount;
      console.log(this.shoppingCartItems);
    });
    this.content = JSON.parse(localStorage.getItem('data'));
  }

  ngOnInit() {
  }

  async getItems() {
    this.cartService.getItems();
  }
  // Increase Product Quantity
  public increment(product: any, quantity: number = 1) {
    this.cartService.updateCartQuantity(product, quantity);
  }

  // Decrease Product Quantity
  public decrement(product: any, quantity: number = -1) {
    if ((product.qty + quantity) >= 1) {
      this.cartService.updateCartQuantity(product, quantity);
    }
    else {
      this.toastr.error('At least one item should be there');
    }
  }

  // Remove cart items
  public removeItem(item: any) {
    this.cartService.removeFromCart(item);
  }
}

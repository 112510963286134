<a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="login-btn" *ngIf="!isLoggedIn">
  <a class="main-btn" (click)="logIn()">{{ content?.Login }}</a>
</div>
<div class="profile-btn" (click)="showHideProfile($event)" *ngIf="isLoggedIn">
  <a class="main-btn"> {{ content?.Profile }} </a>
  <ul class="profile-options dropdown-size">
    <li><a class="nav-link" routerLink="/myprofile">{{ content?.MyProfile }}</a></li>
    <li><a routerLink="/shop/cart">{{ content?.MyCart }}</a></li>
    <li><a routerLink="/shop/orders">{{ content?.MyOrder }}</a></li>
    <li><a href="#" (click)="logOut()">{{ content?.Logout }}</a></li>
  </ul>
</div>
<div class="lang-btn" (click)="showHideLang($event)">
  <a class="main-btn">{{ langName }}</a>
  <ul class="lang-options dropdown-size">
    <li><a (click)="changeLanguage('en')">English</a></li>
    <li><a (click)="changeLanguage('de')">German</a></li>
    <li><a (click)="changeLanguage('no')">Norwegian</a></li>
    <!-- <li *ngFor="let language of languageList;let i = index">
            <a (click)="changeLanguage(language.code)">{{language?.name}}</a>
        </li> -->
  </ul>
</div>
<div class="responsive-btn" (click)="addClasses($event)">
  <a>
    <span>&nbsp;</span>
    <span>&nbsp;</span>
    <span>&nbsp;</span>
  </a>
</div>

<div class="new-header">
  <div class="new-block-main">
    <div class="new-block-inner d-flex">
      <div class="new-header-blocks">
        <span class="new-header-block">&nbsp;</span>
        <span class="new-header-block">&nbsp;</span>
        <span class="new-header-block">&nbsp;</span>
        <span class="new-header-block">&nbsp;</span>
      </div>
      <div class="new-header-inner new-header-inner1 d-flex align-items-center justify-content-start">
        <ul class="new-header-list">
          <li>
            <a routerLink=""><span>101. {{ content?.MenuHome }}</span></a>
          </li>
          <!-- <li><a routerLink="/page/commingsoon"><span>102. {{content?.MenuDepthOfVision }}</span></a></li> -->
          <li>
            <a class="nav-link scrollto" routerLink="/page/about-us">102. {{ content?.MenuAbout }}</a>
          </li>
          <li>
            <a class="nav-link scrollto" routerLink="/portfolio">103. {{ content?.MenuPortfolio }}</a>
          </li>
          <li>
            <a class="nav-link scrollto" routerLink="/shop">104. {{ content?.MenuShop }}</a>
          </li>
          <li>
            <a class="nav-link scrollto" routerLink="/newsroom">105. {{ content?.MenuNewsRoom }}</a>
          </li>
          <li>
            <a class="nav-link scrollto" routerLink="/manifesto">106. {{ content?.MenuManifesto }}</a>
          </li>
        </ul>
      </div>
      <div class="new-header-inner new-header-inner2 d-flex align-items-center justify-content-center">
        <div class="header-details row">
          <div class="row">
            <div class="col-sm-12 text-left our-work-link">
              <h2>Reach Us</h2>
              <div class="header-addr">
                <ul class="header-contact-list">
                  <li><i class="fa fa-map-marker" aria-hidden="true"></i> Stemmane 10, 4656 Kristiansand, Norway</li>
                  <li><i class="fa fa-envelope"></i>hello@svartsvane.co</li>
                </ul>
                <!-- <p>Stemmane 10, 4656 Kristiansand, Norway</p>
                <a href="" class="disable-link"><i class="fa fa-phone-square"
                                        aria-hidden="true"></i>+000000000</a>
                                <a href="" class="rotate-icon disable-link"><i class="fa fa-phone"></i>+000000000</a>
                <a href="" class="disable-link"
                  ><i class="fa fa-envelope"></i>hello@svartsvane.com</a
                >

                <a target="_blank" href="" class="disable-link"
                  ><i class="fa fa-map-marker" aria-hidden="true"></i>Find Us</a
                > -->
                <h2>Connect with us</h2>
                <ul class="disable-link header-social-links">
                  <li><a href=""><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                  <li><a href=""><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                  <li><a href=""><i class="fa fa-google" aria-hidden="true"></i></a></li>
                  <li><a href=""><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="menu-bg-txt">Svartsvane</span>
    </div>
  </div>
</div>
<!--footer start-->
<footer class="resume copyright copyright-bg inner-pages-footer"
    *ngIf="url!='/portfolio/portfolio-parallex' && url!='/portfolio/centered-slide' && url!='/portfolio/multiple-carousel' && url!='/portfolio/vertical-slide'">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="link link-horizontal  text-center mb-3">
                    <p class="footer-logo-text">Svartsvane</p>
                    <!-- <img alt="" class="img-fluid" src="/assets/images/logo/SVL_logo.png"> -->
                </div>
            </div>
            <div class="col-12">
                <div class="link link-horizontal">
                    <ul class="justify-content-center">
                        <li><a class="copyright-text disable-link" href="#">home</a></li>
                        <li><a class="copyright-text disable-link" href="#">couple</a></li>
                        <li><a class="copyright-text disable-link" href="#">invitation</a></li>
                        <li><a class="copyright-text disable-link" href="#">album </a></li>
                        <li><a class="copyright-text disable-link" href="#">friends say</a></li>
                        <li><a class="copyright-text disable-link" href="#">gift</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <div class="social-link link-horizontal">
                        <ul class="justify-content-center">
                            <li><a class="copyright-text disable-link" href="#"><i aria-hidden="true"
                                        class="fa fa-facebook"></i></a>
                            </li>
                            <li><a class="copyright-text disable-link" href="#"><i aria-hidden="true"
                                        class="fa fa-twitter"></i></a>
                            </li>
                            <li><a class="copyright-text disable-link" href="#"><i aria-hidden="true"
                                        class="fa fa-google"></i></a>
                            </li>
                            <li><a class="copyright-text disable-link" href="#"><i aria-hidden="true"
                                        class="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--footer end-->
<!--copyright start-->
<div class="agency copyright inner-page"
    *ngIf="url!='/portfolio/portfolio-parallex' && url!='/portfolio/centered-slide' && url!='/portfolio/multiple-carousel' && url!='/portfolio/vertical-slide'">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a class="copyright-text disable-link" href="#">Privacy Policy</a>
                        </li>
                        <li>
                            <a class="copyright-text disable-link" href="#">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right">Copyright ©{{ currentYear }} Svartsvane</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!--copyright start-->
<app-tap-to-top *ngIf="url!='/portfolio/centered-slide' &&
                       url!='/portfolio/multiple-carousel' &&
                       url!='/portfolio/vertical-slide'&&
                       url!='/features/form-popup' && 
                       url!='/features/modal-popup'&&
                       url!='/features/map-popup'&&
                       url!='/features/youtube-popup'"></app-tap-to-top>
<div class="collection-mobile-back">
  <span class="filter-back" (click)="toggleFilter()">
    <i class="fa fa-angle-left" aria-hidden="true"></i> back
  </span>
</div>
<div class="collection-collapse-block top_filter border-0 open">
  <h3 class="collapse-block-title" style="padding-bottom: 10px;"> {{ langContent?.PortfolioCategory }}</h3>
  <div class="collection-collapse-block-content">
    <div class="collection-brand-filter">
      <ngx-treeview [config]="TreeConfig" [items]="TreeItems" (selectedChange)="onSelectedChange($event)"
        (filterChange)="onFilterChange($event)">
      </ngx-treeview>
    </div>
  </div>
</div>
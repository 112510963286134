<section class="alert-section">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header"><h3 class="card-head">basic Alerts</h3>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-primary dark" role="alert">This is a info alert—check it out!</div>
                        <div class="alert alert-secondary dark" role="alert">This is a light alert—check it out!</div>
                        <div class="alert alert-success dark" role="alert">This is a success alert—check it out!</div>
                        <div class="alert alert-info dark" role="alert">This is a danger alert—check it out!</div>
                        <div class="alert alert-warning dark" role="alert">This is a secondary alert—check it out!</div>
                        <div class="alert alert-danger dark" role="alert">This is a warning alert—check it out!</div>
                        <div class="alert alert-dark dark" role="alert">This is a dark alert—check it out!</div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header"><h3 class="card-head">link Alerts</h3>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-primary dark" role="alert">
                            <a href="#">This is a info alert—check it out!</a>
                        </div>
                        <div class="alert alert-secondary dark" role="alert">
                            <a href="#">This is a info alert—check it out!</a>
                        </div>
                        <div class="alert alert-success dark" role="alert">
                            <a href="#">This is a info alert—check it out!</a>
                        </div>
                        <div class="alert alert-info dark" role="alert">
                            <a href="#">This is a info alert—check it out!</a>
                        </div>
                        <div class="alert alert-warning dark" role="alert">
                            <a href="#">This is a info alert—check it out!</a>
                        </div>
                        <div class="alert alert-danger dark" role="alert">
                            <a href="#">This is a info alert—check it out!</a>
                        </div>
                        <div class="alert alert-dark dark" role="alert">
                            <a href="#">This is a info alert—check it out!</a>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header"><h3 class="card-head">outline Alerts</h3>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-primary dark outline" role="alert">This is a info alert—check it out!
                        </div>
                        <div class="alert alert-secondary dark outline" role="alert">This is a light alert—check it
                            out!
                        </div>
                        <div class="alert alert-success dark outline" role="alert">This is a success alert—check it
                            out!
                        </div>
                        <div class="alert alert-info dark outline" role="alert">This is a danger alert—check it out!
                        </div>
                        <div class="alert alert-warning dark outline" role="alert">This is a secondary alert—check it
                            out!
                        </div>
                        <div class="alert alert-danger dark outline" role="alert">This is a warning alert—check it
                            out!
                        </div>
                        <div class="alert alert-dark dark outline" role="alert">This is a dark alert—check it out!</div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header"><h3 class="card-head">dismissing Alerts</h3>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-primary dark alert-dismissible fade show" role="alert">
                            You can check in on some of those fields below.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                        </div>
                        <div class="alert alert-secondary dark alert-dismissible fade show" role="alert">You can check
                            in on some of those fields below.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                        </div>
                        <div class="alert alert-success dark alert-dismissible fade show" role="alert"> You can check in
                            on some of those fields below.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                        </div>
                        <div class="alert alert-info dark alert-dismissible fade show" role="alert"> You can check in on
                            some of those fields below.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                        </div>
                        <div class="alert alert-warning dark alert-dismissible fade show" role="alert">You can check in
                            on some of those fields below.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                        </div>
                        <div class="alert alert-danger dark alert-dismissible fade show" role="alert"> You can check in
                            on some of those fields below.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                        </div>
                        <div class="alert alert-dark dark alert-dismissible fade show" role="alert"> You can check in on
                            some of those fields below.
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
                                    aria-hidden="true">×</span></button>
                        </div>
                    </div>
                </div>
                <div class="card mb-0">
                    <div class="card-header"><h3 class="card-head">dismissing Alerts</h3>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-primary dark " role="alert"><i class="fa fa-heart-o m-r-10"
                                                                               aria-hidden="true"></i>
                            You can check in on some of those fields below.
                        </div>
                        <div class="alert alert-secondary dark" role="alert"><i class="fa fa-heart-o m-r-10"
                                                                                aria-hidden="true"></i>You can check in
                            on some of those fields below.
                        </div>
                        <div class="alert alert-success dark" role="alert"><i class="fa fa-heart-o m-r-10"
                                                                              aria-hidden="true"></i>You can check in on
                            some of those fields below.
                        </div>
                        <div class="alert alert-info dark" role="alert"><i class="fa fa-heart-o m-r-10"
                                                                           aria-hidden="true"></i> You can check in on
                            some of those fields below.
                        </div>
                        <div class="alert alert-warning dark" role="alert"><i class="fa fa-heart-o m-r-10"
                                                                              aria-hidden="true"></i>You can check in on
                            some of those fields below.
                        </div>
                        <div class="alert alert-danger dark" role="alert"><i class="fa fa-heart-o m-r-10"
                                                                             aria-hidden="true"></i>You can check in on
                            some of those fields below.
                        </div>
                        <div class="alert alert-dark dark"><i class="fa fa-heart-o m-r-10" aria-hidden="true"></i> You
                            can check in on some of those fields below.

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

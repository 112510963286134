<div class="agency">
    <app-product-header></app-product-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">{{content?.MenuNewsRoom}}</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a [routerLink]="['/newsroom']">{{content?.MenuNewsRoom}}</a>
                                <li><a><i class="fa fa-angle-double-right"></i>{{content?.NewsRoomCategory}} </a></li>                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->
    <!-- Newsroom category section starts -->
    <section class="section-b-space">
        <div class="container">
            <app-newsroom-category></app-newsroom-category>
                <!-- <div class="row shop-sample-4">
                    <div class="col-xl-3 col-md-6" *ngFor="let blog of blogData">
                        <div class="ncat-box">
                            <div class="img-wrapper">
                                <div class="front">
                                    <a *ngFor="let image of blog.blogImages">
                                        <img alt="" class="img-fluid" [src]="image.imageData">
                                    </a>                            
                                </div>
                            </div>
                            <div class="ncat-detail">
                                <a>
                                    <h6>{{blog.newsroomCategory}}</h6>
                                    <p class="text-center">{{blog.headerText}}/p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div> -->
        </div>
    </section>
    <!-- Newsroom category section ends -->
    <section class="section-b-space">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="title title2 title-inner">
                        <div class="main-title">
                            <h2 class="font-primary borders text-center main-text m-b-0">
                                <span class="text-uppercase">LATEST NEWS</span>
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
          
            <div class="row latest-news m-t-20 shop-sample-4">
                <div class="col-xl-3" *ngFor="let latestBlog of latestBlogData">
                  <div class="blog-contain" [routerLink]="['/newsroom/news-room-details', latestBlog?.id]">
                    <div class="blog-img">
                      <img alt="" class="img-fluid" *ngIf="latestBlog.blogImages && latestBlog.blogImages.length > 0" [src]="latestBlog.blogImages[0]?.imageData">
                    </div>
                    <div class="news-content" style="height: 350px;">
                      <div class="blog-info bottom-0">
                        <div class="m-b-20">
                          <div class="d-flex center-text">
                            <h4 class="blog-head font-800">{{latestBlog.name}}</h4>
                          </div>
                          <div class="center-text">
                            <h6>{{latestBlog.headerText}}</h6>
                          </div>
                        </div>
                            <div class="para1" [style.max-height.px]="200" style="margin-bottom: 10px;" [style.overflow]="'hidden'" [innerHTML]="latestBlog.content"></div>
                        <a class="read-more" style="margin-top: 10px;">Read more...</a>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              
              
              
              
                         
        </div>
    </section>
    <!-- <ngx-masonry [options]="myOptions" [useImagesLoaded]="true" class="masonry-grid m-layout m-t-30">
        <div ngxMasonryItem class="m-width m-3-col" (load)="updateMasonryLayout = !updateMasonryLayout"
            *ngFor="let blog of blogData">
            <div class="blog-agency">
                <div class="blog-contain">
                    <div *ngFor="let blogImage of blog?.blogImages; let i = index">
                        <img class="img-fluid" *ngIf="i==0" [src]="blogImage?.imageData" alt="">
                    </div>
                    <div class="img-container">
                        <div class="blog-info bottom-0">
                            <div class="m-b-20">
                                <div class="d-flex center-text">
                                    <h4 class="blog-head font-800">{{blog?.headerText + ", " +
                                        blog?.createdDate }}</h4>
                                </div>
                                <div class="center-text">
                                    <h6>Created By: {{blog?.name}}</h6>
                                </div>
                            </div>

                            <p class="para2">{{blog?.content}}
                            </p>
                            <div class="btn-bottom  m-t-20">
                                <a [routerLink]="['/newsroom/news-room-details', blog?.id]"
                                    skipLocationChange class="text-uppercase">read more...</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ngx-masonry> -->

    <!-- pagination Start -->
    <!-- <div class="pagination_sec mb-3">
        <div class="content_detail__pagination cdp">
            <ngb-pagination class="d-flex justify-content-center" [collectionSize]="pageCollectionSize" #numPages
                [pageSize]="size" [(page)]="index" (pageChange)="onPageChange()" [maxSize]="10" [rotate]="true"
                [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
        </div>
    </div> -->
    <!-- pagination End -->
    <app-product-footer></app-product-footer>
</div>

<section class="yoga format">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>progress 1</span></h2>
                    </div>
                </div>
            </div>
            <div class="col-md-7 experience-container">
                <img src="assets/images/yoga-img/exprience.png" class="img-fluid exp-img" alt="">
            </div>
            <div class="col-md-5 counters">
                <div class="center-text">
                    <div class="w-100">
                        <div class="meter m-0">
                            <div class="progress progress1 yoga-gradiant">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">90%</h6>
                                </div>
                            </div>
                        </div>
                        <div class="meter">
                            <div class="progress progress2 yoga-gradiant">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">80%</h6>
                                </div>
                            </div>
                        </div>
                        <div class="meter">
                            <div class="progress progress3 yoga-gradiant">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">85%</h6>
                                </div>
                            </div>
                        </div>
                        <div class="meter">
                            <div class="progress progress4 yoga-gradiant">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">70%</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="resume about bg-light-inner">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="title title2 title-inner">
                    <div class="main-title">
                        <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>progress 2</span></h2>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="text-center radial">
                    <div data-label="98%" class="radial-bar radial-bar-primary">
                    </div>
                    <h4 class="color-light m-t-15">Photoshop</h4>
                    <div class="border-container">
                        <div class="borders-bottom"></div>
                    </div>
                    <div>
                        <h4>4 Years Experince</h4>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="text-center radial">
                    <div data-label="90%" class="radial-bar radial-bar-primary radial-90">
                    </div>
                    <h4 class="color-light m-t-15">illustrator</h4>
                    <div class="border-container">
                        <div class="borders-bottom"></div>
                    </div>
                    <div>
                        <h4>4 Years Experince</h4>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="text-center radial">
                    <div data-label="80%" class="radial-bar radial-bar-primary radial-80">
                    </div>
                    <h4 class="color-light m-t-15">html & css</h4>
                    <div class="border-container">
                        <div class="borders-bottom"></div>
                    </div>
                    <div>
                        <h4>4 Years Experince</h4>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="text-center radial">
                    <div data-label="70%" class="radial-bar radial-bar-primary radial-70">
                    </div>
                    <h4 class="color-light m-t-15">wordpress</h4>
                    <div class="border-container">
                        <div class="borders-bottom"></div>
                    </div>
                    <div>
                        <h4>4 Years Experince</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center m-t-50">
            <a class="btn btn-default primary-btn">know more now</a>
        </div>
    </div>
</section>

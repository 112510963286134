import { ProductsService } from './../../../shared/service/e-commerce/products.service';
import { Component, OnInit } from '@angular/core';
import { Products } from 'src/app/shared/model/e-commerce/product.model';
import { LanguageService } from 'src/app/shared/service/gym/language.service';

@Component({
  selector: 'app-product-new',
  templateUrl: './product-new.component.html',
  styleUrls: ['./product-new.component.scss']
})
export class ProductNewComponent implements OnInit {

  public products: Products[] = [];
  content = null;
  
  constructor(private productsService: ProductsService,
    public languageService: LanguageService) {
      this.languageService.languageDataSubject$.subscribe(res => {
        this.content = res;
        localStorage.setItem('data', JSON.stringify(res));
      });
  
      this.content = JSON.parse(localStorage.getItem('data'));
     }

  ngOnInit() {
    this.productsService.getProducts(1, 5).then(products => {
      this.products = products.data.data;
      console.log(products);
    });
  }

}

<div class="agency">
    <app-product-header></app-product-header>
    <!--breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">{{content?.SuccessOrder}}</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a [routerLink]="['/shop/cart/checkout']">{{content?.CheckOut}}</a></li>
                                <li><a [routerLink]="['/shop/cart/checkout/success']"><i
                                            class="fa fa-angle-double-right"></i>{{content?.SuccessOrder}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- section start -->
    <Section class="cart-section section-b-space" *ngIf="!orderDetails.length">
        <div class="container">
            <div class="col-sm-12 empty-cart-cls text-center">
                <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
                <h3><strong>{{content?.NoOrderToShow}}</strong></h3>
                <h4>{{content?.AddSomethingNew}}</h4>
                <a [routerLink]="['/e-commerce']" class="btn btn-default primary-btn radius-0 m-t-10">{{content?.ContinueShopping}}</a>
            </div>
        </div>
    </Section>

    <!-- Section ends -->

    <!-- thank-you section start -->
    <section class="section-b-space light-layout" *ngIf="orderDetails.length">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="success-text">
                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                        <h2>{{content?.ThankYou}}</h2>
                        <p>{{content?.PaymentReceived}}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Section ends -->

    <!-- order-detail section start -->
    <section class="section-b-space" *ngIf="orderDetails.length">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="product-order">
                        <h3>{{content?.YourOrderDetails}}</h3>
                        <div class="row product-order-detail" *ngFor="let item of orderDetails">
                            <div class="col-3">
                                <img [src]="item.posterImage" alt="" class="img-fluid">
                            </div>
                            <div class="col-3 order_detail">
                                <div>
                                    <h4>{{content?.ProductName}}</h4>
                                    <h5>{{item.productName}}</h5>
                                </div>
                            </div>
                            <div class="col-3 order_detail">
                                <div>
                                    <h4>{{content?.Quantity}}</h4>
                                    <h5>{{item.qty}}</h5>
                                </div>
                            </div>
                            <div class="col-3 order_detail">
                                <div>
                                    <h4>{{content?.Price}}</h4>
                                    <h5>{{item.price | currency:productsService?.currency:'symbol'}}</h5>
                                </div>
                            </div>
                        </div>

                        <div class="total-sec">
                            <ul>
                                <li>{{content?.Shipping}} <span>NOK 00.00</span></li>
                                <li>{{content?.Tax}} <span>NOK  00.00</span></li>
                            </ul>
                        </div>
                        <div class="final-total">
                            <h3>{{content?.Total}} <span>{{totalAmount |
                                    currency:productsService?.currency:'symbol'}}</span></h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row order-success-sec">
                        <div class="col-sm-6">
                            <h4>{{content?.Summery}}</h4>
                            <ul class="order-detail">
                                <li>{{content?.OrderStatus}} </li>
                                <li>{{content?.OrderTotal}}{{content?.ColonChar}} </li>
                            </ul>
                        </div>
                        <div class="col-sm-6">
                            <h4>{{content?.ShippingAddres}}</h4>
                            <ul class="order-detail">
                                <li>{{content?.Address}}{{content?.ColonChar}} {{uniqueOrderDetails?.addressLine1}}
                                    {{uniqueOrderDetails?.addressLine2}},
                                    {{uniqueOrderDetails?.city}}, {{uniqueOrderDetails?.zipCode}} </li>
                                <li>{{content?.ContactNo}}{{content?.ColonChar}} {{uniqueOrderDetails?.phoneNumber}}</li>
                            </ul>
                        </div>

                        <div class="col-sm-12 payment-mode">
                            <h4>{{content?.PaymentMethod}}</h4>
                            <p class="mt-1">{{content?.PaymentDetails}}</p>
                        </div>
                        <div class="col-md-12">
                            <div class="delivery-sec">
                                <h3>{{content?.DeliveryDetails}}</h3>
                                <h2>{{content?.DeliveryDate}}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 text-center mt-3">
                    <a [routerLink]="['/shop/orders']"
                        class="btn btn-default primary-btn radius-0 justify-content-center">{{content?.GoToOrders}}</a>
                </div>
            </div>
        </div>
    </section>
</div>
import { LocalService } from './../../../shared/service/e-commerce/local.service';
import { OrderService } from './../../../shared/service/e-commerce/order.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Products } from 'src/app/shared/model/e-commerce/product.model';
import { CartService } from 'src/app/shared/service/e-commerce/cart.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { CheckoutService } from 'src/app/shared/service/e-commerce/checkout.service';
import { IPlaceOrder } from 'src/app/shared/model/e-commerce/IplaceOrder';
import { ProductsService } from './../../../shared/service/e-commerce/products.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styles: [
  ]
})
export class SuccessComponent implements OnInit {

  content = null;
  orderDetails: any[] = [];
  uniqueOrderDetails: any;
  totalCount: number;
  subscription: any;
  private routeSub: Subscription;
  orderId: string;
  placedOrderDetials: IPlaceOrder = null;
  totalAmount: number;

  constructor(public languageService: LanguageService, private orderService: OrderService, private route: ActivatedRoute
    ,         private checkoutService: CheckoutService, private localService: LocalService,public productsService: ProductsService) {
    this.subscription = this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));
  }

  ngOnInit(): void {
    this.placedOrderDetials = JSON.parse(this.checkoutService.getPlaceOrerDetails());
    this.localService.removeItem('validDetails');
    this.placeOrder();
  }

  getOrderDetails(orderId: string) {
    this.orderService.getOrderById(orderId).then(result => {
      this.orderDetails = result?.data?.data;
      this.uniqueOrderDetails = result?.data?.data.find(el => el)?.customeraddress;
      this.totalAmount = result?.data?.data.find(el => el)?.grandTotalAmount;
    });
  }

  async placeOrder() {
    if (this.placedOrderDetials != null) {
      if (this.placedOrderDetials?.checkNewAddress) {
        await this.checkoutService.addAddresses(this.placedOrderDetials?.address).then(add => {
          this.checkoutService.placeOrder(add?.data).then(data => {
            this.orderId = data?.data?.ordernumber;
            this.getOrderDetails(this.orderId);
          });
        });
      } else {
        await this.checkoutService.placeOrder(this.placedOrderDetials?.existingAddressId).then(data => {
          this.orderId = data?.data?.ordernumber;
          this.getOrderDetails(this.orderId);
        });
      }
    }
  }
}

<div class="agency">
  <app-product-header></app-product-header>
  <!-- breadcrumb section start -->
  <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb-contain ">
            <div>
              <h2 class="breadcrumb-txt">{{content?.MenuShop}}</h2>
            </div>
            <div>
              <ul>
                <li><a [routerLink]="['/shop']">{{content?.MenuShop}}</a></li>
                <li><a [routerLink]="['/shop']"><i class="fa fa-angle-double-right"></i>{{content?.PortfolioCategory}}</a></li>
                <li><a><i class="fa fa-angle-double-right"></i>{{content?.SubCategory}}</a></li>
                <li><a><i class="fa fa-angle-double-right"></i>{{content?.Products}}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--breadcrumb section end -->

  <!-- section start -->
  <section class="section-b-space">
    <div class="collection-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <app-product-typehead></app-product-typehead>
          </div>
        </div>
        <div class="row">
          <!-- Collection Sidebar -->

          <!-- FILTERS -->

          <!-- <a class="overlay-sidebar" [class.overlay-sidebar-open]="productsService.filterBar"
            (click)="closeOverlay()"></a>
          <div class="col-sm-3 collection-filter" [class.filterBar]="productsService.filterBar">
            <div class="coll_sidebar_sticky">
              <div class="collection-filter-block">
                <app-product-categories></app-product-categories>
              </div>
              <div class="collection-filter-block">
                <app-brand [tagsFilters]="tags" (tagFilters)="updateTagFilters($event)" *ngIf="tags.length"></app-brand>
                <app-color [colorsFilters]="colors" (colorFilters)="updateColorFilters($event)" *ngIf="colors.length">
                </app-color>
                <app-product-price (priceFilters)="updatePriceFilters($event)"></app-product-price>
              </div>
              <app-product-new></app-product-new>
            </div>
          </div> -->

          <!-- FILTERS -->

          <!-- End Collection Sidebar -->
          <div class="collection-content col">
            <div class="page-main-content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12">

                    <!-- BANNER IMAGE -->

                    <!-- <div class="top-banner-wrapper">
                      <a [routerLink]="'/e-commerce/left-sidebar/collection/new'"><img
                          src="assets/images/inner-page/banner.png" class="img-fluid" alt=""></a>
                    </div> -->

                    <!-- BANNER IMAGE -->
                    
                    <div class="collection-product-wrapper">
                      <!-- Filter Block Products -->
                      <div class="product-top-filter">
                        <div class="container-fluid p-0">
                          <div class="row">
                            <div class="col-xl-12">
                              <div class="filter-main-btn">
                                <span class="filter-btn btn btn-theme btn-default primary-btn radius-0"
                                  (click)="toggleFilter()">
                                  <i class="fa fa-filter" aria-hidden="true"></i> {{ content?.Filter }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <div class="product-filter-content">
                                <div class="search-count">
                                  <h5>{{ content?.ShowingProducts }} 1-{{filterItems().length}}</h5>
                                </div>
                                <div class="collection-view">
                                  <ul>
                                    <li><i class="fa fa-th grid-layout-view" (click)="grid4()"></i></li>
                                    <li><i (click)="listOpen()" class="fa fa-list-ul list-layout-view"></i></li>
                                  </ul>
                                </div>
                                <div class="collection-grid-view" *ngIf="gridOptions">
                                  <ul>
                                    <li>
                                      <img alt="" class="product-2-layout-view"
                                        src="/assets/images/inner-page/icon/2.png" (click)="grid2()">
                                    </li>
                                    <li>
                                      <img alt="" class="product-3-layout-view"
                                        src="/assets/images/inner-page/icon/3.png" (click)="grid3()">
                                    </li>
                                    <li>
                                      <img alt="" class="product-4-layout-view"
                                        src="/assets/images/inner-page/icon/4.png" (click)="grid4()">
                                    </li>
                                  </ul>
                                </div>
                                <div class="product-page-filter">
                                  <select (change)="onChangeSorting($event.target.value)">
                                    <option value="asc">{{ content?.SortingItems }}</option>
                                    <option value="asc">{{ content?.AscendingOrder }}</option>
                                    <option value="desc">{{ content?.DescendingOrder}}</option>
                                    <option value="a-z">{{ content?.Alphabetically}}, {{ content?.AZ }}</option>
                                    <option value="z-a">{{ content?.Alphabetically}}, {{ content?.ZA }}</option>
                                    <option value="low">{{ content?.Price}}, {{ content?.LowToHigh }}</option>
                                    <option value="high">{{ content?.Price}}, {{ content?.HighToLow }}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- End Block Products -->
                      <!-- Display Products -->
                      <div class="product-wrapper-grid shop-sample-4" [class.list-view]="listView">
                        <div class="container-fluid">
                          <div class="row">
                            <div [class.col-xl-2]="col_xl_2" [class.col-xl-12]="col_xl_12" [class.col-xl-3]="col_xl_3"
                              [class.col-xl-4]="col_xl_4" [class.col-md-6]="col_md_6" class="col-grid-box"
                              *ngFor="let product of filterItems() | orderBy:sortByOrder">
                              <div class="product-box wow animated bounceIn">
                                <app-product-box class="list-product" [products]="product"></app-product-box>
                              </div>
                            </div>

                            <div class="col-sm-12 text-center section-b-space mt-5 no-found"
                              *ngIf="!filterItems().length">
                              <img src="assets/images/empty-search.jpg" class="img-fluid mb-4">
                              <h3>{{ content?.SorryCanNotFindTheProduct }} </h3>
                              <p>{{ content?.PleaseCheckMisspeltSomething}}.</p>
                              <a [routerLink]="'/e-commerce'" class="btn btn-default primary-btn ra-dius-0 m-t-10">{{
                                content?.ContinueShopping }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section End -->
  <!-- pagination Start -->
  <div class="pagination_sec mb-3">
    <div class="content_detail__pagination cdp">
      <ngb-pagination class="d-flex justify-content-center" [collectionSize]="pageCollectionSize" #numPages
        [pageSize]="size" [(page)]="index" (pageChange)="onPageChange()" [maxSize]="10" [rotate]="true"
        [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
    </div>
  </div>
  <!-- pagination End -->
  <app-product-footer></app-product-footer>
</div>
<div class="agency">
    <app-product-header></app-product-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-contain ">
              <div>
                <h2 class="breadcrumb-txt">{{content?.OrderDetails}}</h2>
              </div>
              <div>
                <ul>
                  <li><a [routerLink]="['/shop/orders']">{{content?.Orders}}</a>
                  <li><a><i class="fa fa-angle-double-right"></i>{{content?.OrderDetails}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--breadcrumb section end -->
  
    <!-- section start -->
    <section class="cart-section section-b-space">
      <div class="container" *ngIf="orderedItems">
        <div class="col-sm-12 empty-cart-cls text-center" *ngIf="!orderedItems.length">
          <img src="assets/images/icon-empty-cart.png" class="img-fluid mb-4">
          <h3><strong>{{content?.NoOrder}}</strong></h3>
          <a [routerLink]="['/e-commerce']" class="btn btn-default primary-btn radius-0 m-t-10">continue shopping</a>
        </div>
        <div class="row address" *ngIf='orderedItems.length'>
            <div class="col-4">
              <p><b>{{content?.OrderNo}}</b>{{uniqueOrderDetails?.orderNumber}}</p>
              <p><b>{{content?.ShippingAddres}}</b>{{uniqueOrderDetails?.customeraddress?.addressLine1}}<span *ngIf="(uniqueOrderDetails?.customeraddress?.addressLine2) || (uniqueOrderDetails?.customeraddress?.city)">, </span>
              {{uniqueOrderDetails?.customeraddress?.addressLine2}}</p>
              <p>{{uniqueOrderDetails?.customeraddress?.city}}<span *ngIf="uniqueOrderDetails?.customeraddress?.zipCode">, </span>{{uniqueOrderDetails?.customeraddress?.zipCode}}</p>
            </div>
            <div class="col-4">
              <p><b>{{content?.OrderStatus}}</b>{{uniqueOrderDetails?.status}}</p>
              <p><b>{{content?.ModeOfPayment}}</b></p>
            </div>
            <div class="col-4">
              <p><b>{{content?.TotalPrice}}</b>{{ uniqueOrderDetails?.grandTotalAmount | currency:productsService?.currency:'symbol' }}</p>
            </div>
        </div>
        <div class="row products" *ngIf='orderedItems.length'>
          <div class="col-sm-12">
            <table class="table cart-table table-responsive-xs">
              <thead>
                <tr class="table-head">
                  <th scope="col">{{content?.Image}}</th>
                  <th scope="col">{{content?.ProductName}}</th>
                  <th scope="col">{{content?.Price}}</th>
                  <th scope="col">{{content?.Quantity}}</th>
                  <th scope="col">{{content?.Total}}</th>
                </tr>
              </thead>
              <tbody *ngFor="let item of orderedItems">
                <tr>
                  <td>
                    <a [routerLink]="['/shop/product/', item.productId]">
                      <img class="imgSize" [src]="item.posterImage" alt="">
                    </a>
                  </td>
                  <td>
                    <a [routerLink]="['/shop/product/', item.productId]">{{ item.productName }}</a>
                  </td>
                  <td>
                    <h2>{{ item.price | currency:productsService?.currency:'symbol' }}</h2>
                  </td>
                  <td>
                    <h2>{{ item.qty }}</h2>
                  </td>
                  <td>
                    <h2 class="td-color">{{ item.price * item.qty | currency:productsService?.currency:'symbol' }}</h2>
                  </td>
                </tr>
              </tbody>
            </table>
            <table  style="margin-bottom: -10px;" class="table cart-table table-responsive-md">
              <tfoot>
                <tr>
                  <td>{{content?.TotalPrice | uppercase}}</td>
                  <td>
                    <h2> {{ uniqueOrderDetails?.grandTotalAmount | currency:productsService?.currency:'symbol' }} </h2>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="col-lg-12 text-center mt-3">
            <a [routerLink]="['/shop']"
              class="btn btn-default primary-btn radius-0 m-t-10 justify-content-center">{{content?.ContinueShopping}}
            </a>
          </div>
        </div>
      </div>
    </section>
    <!--section end-->
    <app-product-footer></app-product-footer>
  </div>
import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AppIntializerService } from "src/app/shared/service/app-intializer.service";
import { LanguageService } from "src/app/shared/service/gym/language.service";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"],
})
export class AboutUsComponent implements OnInit {
  content = null;

  constructor(
    public languageService: LanguageService,
    public appIntializerService: AppIntializerService,
    private toastr: ToastrService
  ) {
    this.languageService.languageDataSubject$.subscribe((res) => {
      this.content = res;
      localStorage.setItem("data", JSON.stringify(res));
    });
    this.getTranslatedData();
  }

  ngOnInit() {}

  getTranslatedData() {
    this.content = this.appIntializerService.languageData;
  }
  sendEmail() {
    console.log("called");
    var sendEmail = document.createElement("a");
    var toemail = "hello@svartsvane.com";
    var name: any = document.getElementById("form_name");
    var email: any = document.getElementById("form-mail");
    var body: any = document.getElementById("exampleFormControlTextarea1");
    var subject = "Enquiry - " + name.value + "(" + email.value + ")";
    sendEmail.href =
      "mailto:" + toemail + "?&subject=" + subject + "&body=" + body.value;
    sendEmail.click();
    this.toastr.success(
      "Thank you for your enquiry, we will get back to you as soon as possible"
    );
  }

  aboutTestimonial = [
    {
      img: "assets/images/agency/about-us/testimonial-2.png",
    },
    {
      img: "assets/images/agency/about-us/testimonial-3.png",
    },
  ];

  aboutTestimonialCarouselOptions = {
    items: 1,
    margin: 0,
    autoHeight: true,
    nav: true,
    dots: false,
    navText: [
      '<img src="assets/images/music/gallery/gallery-icon/left.png">',
      '<img src="assets/images/music/gallery/gallery-icon/right.png">',
    ],
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
  };
}

<div class="agency">
    <app-product-header></app-product-header>
    <!--breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">{{content?.ShopWithCheckout}}</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a [routerLink]="['/shop']">{{content?.MenuShop}}</a></li>
                                <li><a><i class="fa fa-angle-double-right"></i>{{content?.CheckOut}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->
    <div class="container services bg mt-4">
        <div class="row">
            <div class="col-sm-3" *ngFor="let item of addresses; index as i" (click)="setAddress(item?.id,i+1)">
                <span class="delete-card m-3" (click)="deleteAddress(item?.id)">X</span>
                <div class="service service-overlay text-center" data-tilt="" data-tilt-max="6" data-tilt-speed="400"
                    data-tilt-perspective="500" [id]="'active'+(i+1)"
                    style="will-change: transform; transform: perspective(500px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);">
                    <div class="service-feature">
                        <h4 class="feature-text text-center"><b>{{content?.Address}}{{content?.ColonChar}} {{i+1}}</b></h4>
                        <p><b>{{content?.Name}}{{content?.ColonChar}} </b>{{item?.firstname}} {{item?.lastname}}</p>
                        <p><b>{{content?.Address}}{{content?.ColonChar}} </b>{{item?.addressLine1}}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="container padding-cls mt-5 mb-5">
        <div class="checkout-page">
            <div class="checkout-form">
                <form>
                    <div class="row">
                        <div class="col-lg-6 col-sm-12 col-xs-12">
                            <div class="checkout-title">
                                <h3>{{content?.BillingDetails}}</h3>
                            </div>
                            <div class="row check-out">
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{content?.FirstName}}</div>
                                    <input type="text" name="firstname"
                                        [formControl]="checkoutForm.controls['firstname']" value="" placeholder=""
                                        autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.required"
                                        class="text text-danger">
                                        {{content?.FirstNameRequired}}
                                    </div>
                                    <div *ngIf="checkoutForm.controls.firstname.touched && checkoutForm.controls.firstname.errors?.pattern"
                                        class="text text-danger">
                                        {{content?.FirstNamePattern}}
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{content?.LastName}}</div>
                                    <input type="text" name="lastname" [formControl]="checkoutForm.controls['lastname']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.required"
                                        class="text text-danger">
                                        {{content?.LastNameRequired}}
                                    </div>
                                    <div *ngIf="checkoutForm.controls.lastname.touched && checkoutForm.controls.lastname.errors?.pattern"
                                        class="text text-danger">
                                        {{content?.LastNamePattern}}
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{content?.Phone}}</div>
                                    <input type="text" name="phone" [formControl]="checkoutForm.controls['phone']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.required"
                                        class="text text-danger">
                                        {{content?.PhoneRequired}}
                                    </div>
                                    <div *ngIf="checkoutForm.controls.phone.touched && checkoutForm.controls.phone.errors?.pattern"
                                        class="text text-danger">
                                        {{content?.PhonePattern}}
                                    </div>
                                </div>
                                <div class="form-group col-md-6 col-sm-6 col-xs-12">
                                    <div class="field-label">{{content?.EmailAddress}}</div>
                                    <input type="text" name="email" [formControl]="checkoutForm.controls['email']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.required"
                                        class="text text-danger">
                                        {{content?.EmailAddressRequired}}
                                    </div>
                                    <div *ngIf="checkoutForm.controls.email.touched && checkoutForm.controls.email.errors?.email"
                                        class="text text-danger">
                                        {{content?.EmailAddressPattern}}
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">Country</div>
                                    <select name="country" [formControl]="checkoutForm.controls['country']">
                                        <option value="" selected>Select Country</option>
                                        <option value="India">India</option>
                                        <option value="USA">USA</option>
                                        <option value="Australia">Australia</option>
                                    </select>
                                    <!-- <div *ngIf="checkoutForm.controls.country.touched && checkoutForm.controls.country.errors?.required"
                                        class="text text-danger">
                                        Country is required
                                    </div> -->
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">{{content?.Address1}}</div>
                                    <input type="text" name="address" [formControl]="checkoutForm.controls['address1']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.address1.touched && checkoutForm.controls.address1.errors?.required"
                                        class="text text-danger">
                                        {{content?.Address1Required}}
                                    </div>
                                    <div *ngIf="checkoutForm.controls.address1.touched && checkoutForm.controls.address1.errors?.maxlength"
                                        class="text text-danger">
                                        {{content?.Address1Pattern}}
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">{{content?.Address2}}</div>
                                    <input type="text" name="address" [formControl]="checkoutForm.controls['address2']"
                                        value="" placeholder="" autocomplete="off">
                                </div>
                                <div class="form-group col-md-12 col-sm-12 col-xs-12">
                                    <div class="field-label">{{content?.TownCity}}</div>
                                    <input type="text" name="town" [formControl]="checkoutForm.controls['town']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.town.touched && checkoutForm.controls.town.errors?.required"
                                        class="text text-danger">
                                        {{content?.TownCityRequired}}
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div class="field-label">{{content?.State}}</div>
                                    <input type="text" name="state" [formControl]="checkoutForm.controls['state']"
                                        value="" placeholder="" autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.state.touched && checkoutForm.controls.state.errors?.required"
                                        class="text text-danger">
                                        {{content?.StateRequired}}
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-6 col-xs-12">
                                    <div class="field-label">{{content?.PostalCode}}</div>
                                    <input type="text" name="postalcode"
                                        [formControl]="checkoutForm.controls['postalcode']" value="" placeholder=""
                                        autocomplete="off">
                                    <div *ngIf="checkoutForm.controls.postalcode.touched && checkoutForm.controls.postalcode.errors?.required"
                                        class="text text-danger">
                                        {{content?.PostalCodeRequired}}
                                    </div>
                                </div>
                                <div class="form-group col-md-12 col-sm-6 col-xs-12 d-flex justify-content-center">
                                    <button class="btn btn-default primary-btn radius-0"
                                        (click)="clearForm()">{{content?.Reset}}</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-12 col-xs-12">
                            <div class="checkout-details">
                                <div class="order-box">
                                    <div class="title-box">
                                        <div>{{content?.Product}} <span> {{content?.Total}}</span></div>
                                    </div>
                                    <ul class="qty" *ngIf='shoppingCartItems.length'>
                                        <li *ngFor="let item of shoppingCartItems">
                                            {{ item.productName }} × {{ item.qty }}
                                            <span>
                                                {{ item.price * item.qty |
                                                currency:productsService?.currency:'symbol' }}
                                            </span>
                                        </li>
                                    </ul>
                                    <ul class="qty" *ngIf='!shoppingCartItems.length'>
                                        <li class="empty-checkout"> {{content?.NoProductInCart}} </li>
                                    </ul>
                                    <ul class="sub-total">
                                        <li>
                                            {{content?.SubTotal}} <span class="count">{{ totalCount |
                                                currency:productsService?.currency:'symbol'}}</span>
                                        </li>
                                        <li *ngIf='shoppingCartItems.length'>
                                            {{content?.Shipping}}
                                            <div class="shipping">
                                                <div class="shopping-option">
                                                    <input type="radio" name="shipping" checked="checked"
                                                        id="free-shipping">
                                                    <label for="free-shipping">{{content?.Shipping}}</label>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <ul class="total">
                                        <li>{{content?.Total}} <span class="count">{{ totalCount |
                                                currency:productsService?.currency:'symbol'}}</span></li>
                                    </ul>
                                </div>

                                <div class="payment-box">
                                    <div>
                                        <a [routerLink]="['/shop']"
                                            class="btn btn-default primary-btn radius-0">{{content?.ContinueShopping}}</a>
                                        <button class="btn btn-default primary-btn radius-0 float-right"
                                            (click)="checkout()" type="submit" [disabled]="isValid()">
                                            {{content?.PlaceOrder}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <app-product-footer></app-product-footer>
</div>
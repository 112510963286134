<div class="agency">
    <app-product-header></app-product-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain">
                        <div>
                            <h2 class="breadcrumb-txt">{{ langContent?.ProductDetails }}</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a [routerLink]="['/shop']">{{langContent?.MenuShop}}</a></li>
                                <li><a [routerLink]="['/shop']"><i class="fa fa-angle-double-right"></i>{{langContent?.PortfolioCategory}}</a></li>
                                <li><a><i class="fa fa-angle-double-right"></i>{{langContent?.SubCategory}}</a></li>
                                <li><a><i class="fa fa-angle-double-right"></i>{{langContent?.Products}}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->
    <!-- section start -->
    <div class="agency">
        <section>
            <div class="collection-wrapper">
                <div class="container">
                    <div class="row"> 
                        <a class="overlay-sidebar" [class.overlay-sidebar-open]="productsService.filterBar" (click)="closeOverlay()"></a>
                        <!-- <div class="col-sm-3 collection-filter" [class.filterBar]="productsService.filterBar">
                            <app-sidebar></app-sidebar>
                        </div> -->
                        <div class="col-lg-9 col-sm-12 col-xs-12">
                            <!-- Product - details starts -->
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="filter-main-btn mb-3" *ngIf="url!='/shop/no-sidebar/' +product.id">
                                            <span class="filter-btn btn-default primary-btn radius-0"
                                                (click)="toggleFilter()"><i aria-hidden="true" class="fa fa-filter"></i>
                                                filter</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="row frame-image">
                                    <div class="col-lg-6">
                                        <!-- <img *ngIf="selectedFrame" alt="Frame" class="frame-overlay" [src]="selectedFrame.productImages[0]?.base64String"> -->
                                        <div class="product-image-wrapper">
                                            <owl-carousel-o [options]="productSliderOptions" #owlCar class="product-slick">
                                                <ng-container *ngFor="let data of product.productImages; index as i">
                                                    <ng-template carouselSlide [id]="i">
                                                        <div class="main-product-image">
                                                            <img alt="Product" [src]="data.base64String">
                                                            <img *ngIf="selectedFrame" alt="Frame" class="frame-overlay" [src]="selectedFrame.productImages[0]?.base64String">
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                            </owl-carousel-o>
                                        </div>                                                                              
                                    </div>    
                                    <div class="col-lg-1"></div>                              
                                    
                                    
                                    
                                    <div class="col-lg-5 rtl-text">
                                        <div class="product-right ">
                                            <h2>{{product.name}}</h2>
                                            <h4>
                                                <del>{{product.discountPrice | currency:productsService?.currency:'symbol'}}</del>
                                                <span>{{product.price | currency:productsService?.currency:'symbol'}}</span>
                                            </h4>
                                            <h3>{{product.price | currency:productsService?.currency:'symbol'}}</h3>
                                            <ul class="color-variant" *ngIf="product.productImages && !productsService?.catalogMode">
                                                <li [class]="variant.color" [ngStyle]="{'background-color': variant.color}" [id]="i" (click)="owlCar.to(i.toString())" *ngFor="let variant of product.productImages let i = index"></li>
                                            </ul>
                                            <div class="product-description border-product">
                                                <h6 class="product-title">{{ langContent?.Quantity }}</h6>
                                                <div class="qty-box">
                                                    <div class="input-group">
                                                        <span class="input-group-prepend">
                                                            <button type="button" (click)="decrement(product.id)" class="btn quantity-left-minus" data-type="minus" data-field="">
                                                                <i class="fa fa-chevron-left"></i>
                                                            </button>
                                                        </span>
                                                        <input type="text" name="quantity" disabled class="form-control input-number" value="{{counter}}">
                                                        <span class="input-group-prepend">
                                                            <button type="button" (click)="increment(product.id)" class="btn quantity-right-plus" data-type="plus" data-field="">
                                                                <i class="fa fa-chevron-right"></i>
                                                            </button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="product-buttons" *ngIf="!productsService?.catalogMode">
                                                <a [routerLink]="['/shop/cart']"
                                                    (click)="addToCart(product.id, counter, selectedFrame?.id)"
                                                    class="btn btn-default primary-btn radius-0"
                                                    [class.disabled]="counter > product.stock">
                                                    {{ langContent?.AddToCart }}
                                                    </a>

                                                <a [routerLink]="[]" (click)="buyNow(product, counter)"
                                                    class="btn btn-default primary-btn radius-0"
                                                    [class.disabled]="counter > product.stock">{{ langContent?.BuyNow
                                                    }}</a>
                                            </div>
                                            <div class="border-product">
                                                <h6 class="product-title mb-2">{{ langContent?.ShareIt }}</h6>
                                                <div class="product-icon">
                                                    <ul class="product-social">
                                                        <li><a href="https://www.facebook.com/"><i class="fa fa-facebook"></i></a></li>
                                                        <li><a href="https://plus.google.com/discover"><i class="fa fa-google-plus"></i></a></li>
                                                        <li><a href="https://twitter.com/"><i class="fa fa-twitter"></i></a></li>
                                                        <li><a href="https://www.instagram.com/"><i class="fa fa-instagram"></i></a></li>
                                                    </ul>
                                                    <div class="d-inline-block" *ngIf="!productsService?.catalogMode">
                                                        <button class="wishlist-btn" [class.active]="wishlist" (click)="addToWishlist(product)">
                                                            <i class="fa fa-heart"></i>
                                                            <span class="title-font">{{ langContent?.AddToWishList }}</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
        
                            <div class="row col-12 img-frame-container">
                                <div class="col-6 slider-nav">
                                    <owl-carousel-o [options]="productSliderOptions1" class="product-slick">
                                        <ng-container *ngFor="let data of product.productImages; index as i">
                                            <ng-template carouselSlide [id]="i">
                                                <img alt="Thumbnail" class="img-list" [src]="data.base64String" (click)="owlCar.to(i.toString())">
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </div>
                                <div class="col-6 slider-nav" *ngIf="product.categoryName === 'Atelier' && product.subCategoryId === 33">
                                    <h4 class="frame-slider-label">Frames: </h4>
                                    <owl-carousel-o [options]="frameProductSliderOptions" class="product-slick">
                                      <ng-container *ngFor="let frameProduct of frameProducts">
                                        <ng-template carouselSlide>
                                            <div class="" (click)="selectFrame(frameProduct)">
                                                <img 
                                                  [src]="frameProduct.productImages[0]?.base64String" 
                                                  alt="{{ frameProduct.name }}" 
                                                  class="frame-product-image" 
                                                />
                                                <div class="frame-product-details">
                                                  <p class="frame-product-name">{{ frameProduct.name }}</p>
                                                  <p class="frame-product-price">NOK {{ frameProduct.price }}</p>
                                                  <p class="frame-product-price">{{ frameProduct.categoryName }}</p>
                                                </div>
                                            </div>                                            
                                        </ng-template>
                                      </ng-container>
                                    </owl-carousel-o>                                                  
                                  </div>
                            </div>
                            <section class="tab-product m-0 p-b-0">
                                <div class="row">
                                    <div class="col-sm-12 col-lg-12">
                                        <ngb-tabset>
                                            <ngb-tab title="Description">
                                                <ng-template ngbTabContent>
                                                    <div class="p-b-0" innerHTML="{{ product.description }}"></div>
                                                </ng-template>
                                            </ngb-tab>
                                        </ngb-tabset>
                                    </div>
                                </div>
                            </section>                        
                            <!-- product - details end -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
    <!-- Section ends -->
    <app-product-footer></app-product-footer>
</div>

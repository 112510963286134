import { environment } from './../../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

@Injectable()
// export class AppIntializerService implements Resolve<any>{
  export class AppIntializerService{
  dashboardData: any;
  languageData: any;
  code: string;
  private http: HttpClient;
  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
   }

  public appIntializer(): Promise<any> {
    return this.http
      .get<any>(
        environment.apiHostStartPoint +
        environment.apiHostVersion +
        'CustomerManageDashboard/Get'
      )
      .pipe(
        tap((res: any) => {
          this.dashboardData = res.data;
          console.log(this.dashboardData);
        }),
        switchMap((x) => {
          const storage = localStorage.getItem('data');

          if (this.dashboardData.isDictionaryUpdated || (storage == null || storage == undefined)) {
            if (!localStorage.getItem('language')) {
              localStorage.setItem('language', 'en');
            }
            this.code = localStorage.getItem('language');
            return this.http
              .get(
                environment.apiHostStartPoint +
                environment.apiHostVersion +
                'Language/' +
                this.code
              )
              .pipe(
                tap((y) => {
                  console.log(y);
                  this.languageData = y;
                  localStorage.setItem('data', JSON.stringify(y));
                }
                )
              );
          }
          else {
            this.languageData = JSON.parse(storage);
            return of(JSON.parse(storage));
          }
        })
      )
      .toPromise();
  }
}

import { CheckoutService } from './../../../shared/service/e-commerce/checkout.service';
import { CartItem } from './../../../shared/model/e-commerce/cart.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayPalConfig, PayPalEnvironment, PayPalIntegrationType } from 'ngx-paypal';
import { Observable, of } from 'rxjs';
import { CartService } from 'src/app/shared/service/e-commerce/cart.service';
import { ProductsService } from 'src/app/shared/service/e-commerce/products.service';
import { OrderService } from './../../../shared/service/e-commerce/order.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { Products } from 'src/app/shared/model/e-commerce/product.model';
import { ActivatedRoute, Router } from '@angular/router';
import { IPlaceOrder } from 'src/app/shared/model/e-commerce/IplaceOrder';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  // form group
  public checkoutForm: FormGroup;
  public cartItems: Observable<CartItem[]> = of([]);
  public shoppingCartItems: Products[] = [];
  public orderDetails: any[] = [];
  public payPalConfig?: PayPalConfig;
  content = null;
  payment = null;
  addresses = [];
  isExistingAddress = false;
  public totalCount: number;
  previousSelectedId: number = null;

  // Form Validator
  constructor(private fb: FormBuilder, private cartService: CartService, public languageService: LanguageService, private router: Router,
    private readonly route: ActivatedRoute, private checkoutService: CheckoutService,
    public productsService: ProductsService, private orderService: OrderService) {
    this.getItems();
    this.checkoutForm = this.fb.group({
      id: [''],
      firstname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      lastname: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      phone: ['', [Validators.required, Validators.pattern('[0-9]+')]],
      email: ['', [Validators.required, Validators.email]],
      address1: ['', [Validators.required, Validators.maxLength(50)]],
      address2: [''],
      country: [''],
      town: ['', Validators.required],
      state: ['', Validators.required],
      postalcode: ['', Validators.required]
    });

    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.cartService.cartItems$.subscribe(cartItems => {
      this.shoppingCartItems = cartItems.data.cartItems ?? [];
      this.totalCount = cartItems.data.totalAmount;
      console.log(this.shoppingCartItems);
    });

    this.content = JSON.parse(localStorage.getItem('data'));
  }

  ngOnInit() {
    this.getAddresses();
  }


  async getItems() {
    this.cartService.getItems();
  }

  async getAddresses() {
    this.checkoutService.getAddresses().then(addresses => {
      console.log(addresses);
      this.addresses = addresses?.data?.data;
    });
  }

  setAddress(addressId: number, index: number) {
    let selectedAddress: any = null;
    selectedAddress = this.addresses.find(x => x.id === addressId);
    this.isExistingAddress = true;
    console.log(selectedAddress);
    this.checkoutForm.patchValue({
      id: selectedAddress?.id,
      firstname: selectedAddress?.firstname,
      lastname: selectedAddress?.lastname,
      phone: selectedAddress?.phoneNumber,
      email: selectedAddress?.email,
      address1: selectedAddress?.addressLine1,
      address2: selectedAddress?.addressLine2,
      country: 'India',
      town: selectedAddress?.city,
      state: 'NA',
      postalcode: selectedAddress?.zipCode,
    });
    this.checkoutForm.disable();

    if (this.previousSelectedId !== null && document.getElementById('active' + (this.previousSelectedId)).style.backgroundColor) {
      document.getElementById('active' + (this.previousSelectedId)).style.background = '';
      document.getElementById('active' + (index)).style.backgroundColor = '#FFA07A';
    } else {
      document.getElementById('active' + (index)).style.backgroundColor = '#FFA07A';
    }
    this.previousSelectedId = index;
  }

  clearForm() {
    this.isExistingAddress = false;
    this.checkoutForm.reset({
      country: ''
    });
    this.checkoutForm.enable();
    if (this.previousSelectedId !== null && document.getElementById('active' + (this.previousSelectedId)).style.backgroundColor) {
      document.getElementById('active' + (this.previousSelectedId)).style.background = '';
    }
  }

  checkout() {
    console.log(this.checkoutForm);
    if (!this.isExistingAddress) {
      const addressData = {
        firstname: this.checkoutForm.get('firstname').value,
        lastname: this.checkoutForm.get('lastname').value,
        addressLine1: this.checkoutForm.get('address1').value,
        addressLine2: this.checkoutForm.get('address2').value,
        city: this.checkoutForm.get('town').value,
        phoneNumber: this.checkoutForm.get('phone').value,
        email: this.checkoutForm.get('email').value,
        zipCode: this.checkoutForm.get('postalcode').value
      };
      this.stripeCheckout(true, addressData);
    }
    else {
      this.stripeCheckout(false, null);
    }
  }

  // stripe payment gateway
  stripeCheckout(isNewAddress: boolean, addressData: any) {
    this.checkoutService.addPayment().then(res => {
      if (res) {
        if (isNewAddress) {
          const obj = {
            checkNewAddress: isNewAddress,
            address: addressData,
            existingAddressId: null
          } as IPlaceOrder;
          this.checkoutService.setPlaceOrerDetails(obj);
        }
        else {
          const obj = {
            checkNewAddress: isNewAddress,
            address: null,
            existingAddressId: this.checkoutForm.get('id').value
          } as IPlaceOrder;
          this.checkoutService.setPlaceOrerDetails(obj);
        }
        window.location.href = res?.data?.sessionUrl;
        this.clearForm();
      }
    });
  }

  deleteAddress(addressId: number) {
    this.checkoutService.removeAddress(addressId);
    this.getAddresses();
  }

  isValid(): boolean {
    if (this.checkoutForm.invalid) {
      return true;
    }
    else {
      if (this.shoppingCartItems.length > 0) {
        return false;
      }
      return true;
    }
  }
}

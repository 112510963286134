import { LocalService } from './local.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { BaseResponse } from '../../model/base-response';
import { IPlaceOrder } from '../../model/e-commerce/IplaceOrder';
import { SingleResponse } from '../../model/single-response';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  // public isNewAddress: boolean;
  // public addressData: any;
  // public existingAddressId: number;

  // public placeOrerDetails: IPlaceOrder[] = [];

  constructor(
    private toastr: ToastrService, private httpClient: HttpClient,
    private baseService: ApiService, private router: Router,
    private readonly route: ActivatedRoute,
    private localService: LocalService
  ) { }

  public getAddresses(): Promise<BaseResponse<any>> {
    return this.baseService.GetAll<any>(this.baseService.clietnWebAPIUrl + 'CustomerAddress/GetAllCustomerAddress').toPromise();
  }

  public addAddresses(address: object): Promise<SingleResponse<any>> {
    return this.httpClient.post(this.baseService.APIUrl + 'CustomerAddress/SaveCustomerAddress', address).toPromise()
      .then((result: any) => {
        return result;
      })
      .catch((errorResult) => {
        this.toastr.error(errorResult.error);
        return null;
      });
  }

  public placeOrder(id: number): Promise<any> {
    return this.httpClient.post(this.baseService.APIUrl + 'Order/PlaceOrder', id).toPromise()
      .then((result: any) => {
        this.toastr.success('New order has been placed.');
        return result;
        // this.router.navigate(['../shop/cart/checkout/success'], { relativeTo: this.route });
      })
      .catch((errorResult) => {
        this.toastr.error(errorResult.error);
        return null;
      });
  }

  public removeAddress(id: number) {
    const obj = {
      addressId: id
    };

    return this.httpClient.post(this.baseService.APIUrl + 'CustomerAddress/RemoveCustomerAddress', obj).toPromise()
      .then((result: any) => {
        this.toastr.success('Selected address has been removed.');
      })
      .catch((errorResult) => {
        this.toastr.error(errorResult.error);
        return null;
      });
  }

  public addPayment(): Promise<SingleResponse<any>> {
    return this.baseService.GetAll<any>(this.baseService.clietnWebAPIUrl + 'Order/GetCheckoutSession').toPromise();
  }

  public getPlaceOrerDetails(){
    return this.localService.getJsonValue('validDetails');
  }

  public setPlaceOrerDetails(orderDetails: IPlaceOrder){
    this.localService.setJsonValue('validDetails', JSON.stringify(orderDetails));
  }
}

import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';
import { LanguageService } from "../../shared/service/gym/language.service";



@Component({
  selector: 'app-manifesto',
  templateUrl: './manifesto.component.html',
  styleUrls: ['./manifesto.component.scss']
})
export class ManifestoComponent implements OnInit {
  constructor(
    public languageService: LanguageService) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));
  }

  content = null;

  ngOnInit(): void {
    
  }

  

  // checkScrollY() {
  //   alert(window.scrollY);
  // }

  // mousewheel(event){
  //   var div1 = document.getElementById('div1');
  //   var div2 = document.getElementById('div2');
  //   var div3 = document.getElementById('div3');
  //   var divImage  = document.getElementById('mani-bg');

  //  window.addEventListener('scroll', (e) => {
  //     // const { top } = div1.getBoundingClientRect();
  //     //alert(this.detectMouseWheelDirection(event));
  //     if(this.detectMouseWheelDirection(event) == "down")
  //     {        
  //       if(window.scrollY >= (div2.clientHeight))
  //       {
  //         divImage.style.backgroundImage = "url(/../assets/images/swan-2.jpg)";                                   
  //       }

  //       if(window.scrollY >= (div1.clientHeight + div2.clientHeight))
  //       {
  //         divImage.style.backgroundImage = "url(/../assets/images/swan-3.jpg)";
  //       }        
  //     } 
  //     else 
  //     {
  //       if(window.scrollY <= (div1.clientHeight))
  //       {
  //         divImage.style.backgroundImage = "url(/../assets/images/swan-2.jpg)";
  //       }  

  //       if(window.scrollY <= (div2.clientHeight))
  //       {
  //         divImage.style.backgroundImage = "url(/../assets/images/swan-1.jpg)";                                             
  //       }               
  //     }           
  //   });
  // }

  



  
  mousewheel(event){
    
    var section1 = document.getElementById("Section1");
    var section2 = document.getElementById("Section2");
    var section3 = document.getElementById("Section3");
    var section4 = document.getElementById("Section4");
    var section5 = document.getElementById("Section5");
    var section6 = document.getElementById("Section6");
    var section7 = document.getElementById("Section7");

    var text1 = document.getElementById("text1");
    var text2 = document.getElementById("text2");
    var text3 = document.getElementById("text3");
    var text4 = document.getElementById("text4");
    var text5 = document.getElementById("text5");
    var text6 = document.getElementById("text6");
    var text7 = document.getElementById("text7");

    //alert(section1);
    if(this.detectMouseWheelDirection(event) == "down")
    {
      if(section6.style.opacity == "1")  
      {
        section6.style.opacity = String(Number(section6.style.opacity) - 1);
        section7.style.opacity = String(Number(section7.style.opacity) + 1);
        
        text6.classList.remove("active");
        text7.classList.add("active");
      }

      if(section5.style.opacity == "1")  
      {
        section5.style.opacity = String(Number(section5.style.opacity) - 1);
        section6.style.opacity = String(Number(section6.style.opacity) + 1);

        text5.classList.remove("active");
        text6.classList.add("active");
      }

      if(section4.style.opacity == "1")  
      {
        section4.style.opacity = String(Number(section4.style.opacity) - 1);
        section5.style.opacity = String(Number(section5.style.opacity) + 1);

        text4.classList.remove("active");
        text5.classList.add("active");
      }  

      if(section3.style.opacity == "1")  
      {
        section3.style.opacity = String(Number(section3.style.opacity) - 1);
        section4.style.opacity = String(Number(section4.style.opacity) + 1);

        text3.classList.remove("active");
        text4.classList.add("active");
      }  

      if(section2.style.opacity == "1")  
      {
        section2.style.opacity = String(Number(section2.style.opacity) - 1);
        section3.style.opacity = String(Number(section3.style.opacity) + 1);

        text2.classList.remove("active");
        text3.classList.add("active");
      }  

      if(section1.style.opacity == "1")  
      {
        section1.style.opacity = String(Number(section1.style.opacity) - 1);
        section2.style.opacity = String(Number(section2.style.opacity) + 1);

        text1.classList.remove("active");
        text2.classList.add("active");
      }                        
    }
    else 
    {
      if(section2.style.opacity == "1")  
      {
        section2.style.opacity = String(Number(section2.style.opacity) - 1);
        section1.style.opacity = String(Number(section1.style.opacity) + 1);

        text2.classList.remove("active");
        text1.classList.add("active");
      }

      if(section3.style.opacity == "1")  
      {
        section3.style.opacity = String(Number(section3.style.opacity) - 1);
        section2.style.opacity = String(Number(section2.style.opacity) + 1);

        text3.classList.remove("active");
        text2.classList.add("active");
      }

      if(section4.style.opacity == "1")  
      {
        section4.style.opacity = String(Number(section4.style.opacity) - 1);
        section3.style.opacity = String(Number(section3.style.opacity) + 1);

        text4.classList.remove("active");
        text3.classList.add("active");
      }

      if(section5.style.opacity == "1")  
      {
        section5.style.opacity = String(Number(section5.style.opacity) - 1);
        section4.style.opacity = String(Number(section4.style.opacity) + 1);

        text5.classList.remove("active");
        text4.classList.add("active");
      }

      if(section6.style.opacity == "1")  
      {
        section6.style.opacity = String(Number(section6.style.opacity) - 1);
        section5.style.opacity = String(Number(section5.style.opacity) + 1);

        text6.classList.remove("active");
        text5.classList.add("active");
      }

      if(section7.style.opacity == "1")  
      {
        section7.style.opacity = String(Number(section7.style.opacity) - 1);
        section6.style.opacity = String(Number(section6.style.opacity) + 1);

        text7.classList.remove("active");
        text6.classList.add("active");
      }

      

    }    
  }

  mousewheel2(event){
    alert("called");
    if(this.detectMouseWheelDirection(event) == "down")
    {
      var element1 = document.getElementById("divManifesto2");
      alert(element1.style.opacity);
      if(element1.style.opacity == "1")      
        element1.style.opacity = String(Number(element1.style.opacity) - 1);
    }
    else 
    {

      var element1 = document.getElementById("divManifesto2");
      element1.style.opacity = String(Number(element1.style.opacity) + 1);
    }    
  }

  detectMouseWheelDirection( e )
  {      
      //alert("0");
      var delta = null,
      direction = "";

      if ( !e ) { // if the event is not provided, we get it from the window object
          e = window.event;
      }
      //alert("1");
      if ( e.wheelDelta ) { // will work in most cases
          delta = e.wheelDelta / 60;
      } else if ( e.detail ) { // fallback for Firefox
          delta = -e.detail / 2;
      }
      //alert("2");
      //alert(delta);
      if ( delta !== null ) {
          direction = delta > 0 ? 'up' : 'down';
      }

      //alert("3");
      //alert(direction);
      return direction;
  }
}



// $( document ).ready(function() {
//   alert("halo");
// });

// $.(document).ready({
//   $(window).scroll(function(){
//     $(".manifesto-header-content").css("opacity", 1 - $(window).scrollTop() / 250);
//   });
// })

import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/shared/service/e-commerce/products.service';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { ProductComponent } from "../product/product.component";

@Component({
  selector: 'app-product-categories',
  templateUrl: './product-categories.component.html',
  styleUrls: ['./product-categories.component.scss']
})
export class ProductCategoriesComponent implements OnInit {

  dropdownEnabled = true;
  TreeItems: TreeviewItem[];
  values: number[];
  TreeConfig = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 400
  });
  langContent;

  public categoryId: string;
  public subCategoryId: string;
  private productComponent: ProductComponent;

  constructor(public productsService: ProductsService,
    public languageService: LanguageService,
    public productComponentObj: ProductComponent) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.langContent = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.langContent = JSON.parse(localStorage.getItem('data'));
    this.productComponent = productComponentObj;
  }

  // collapse toggle
  ngOnInit() {
    this.getCategoriesAndSubcategories();
  }

  public getCategoriesAndSubcategories() {
    var res = this.productsService.getCategoriesAndSubcategories().then((result: any) => {
      const categoriesAndSubcategories = result?.data?.data;
      let allItems = [];
      if (categoriesAndSubcategories) {
        for (let i = 0; i < categoriesAndSubcategories.length; i++) {
          const element = categoriesAndSubcategories[i];
          var category = {
            text: element?.name,
            value: 'CateId-' + element?.id,
            collapsed: true
          };

          var subcategories = [];
          for (let k = 0; k < element.subCategories?.length; k++) {
            const subCateObj = element.subCategories[k];
            subcategories.push({ text: subCateObj?.name, value: 'SubCateId-' + subCateObj?.id });
          }
          category["children"] = subcategories;

          allItems.push(new TreeviewItem(category));
        }

        this.TreeItems = allItems;
      }
    });
  }

  // For mobile view
  public mobileFilterBack() { }

  toggleFilter() {
    this.productsService.filterBar = !this.productsService.filterBar;
  }

  onFilterChange(value: string): void {
    console.log('filter:', value);
  }

  onSelectedChange(value: any) {
    var subcategories = [];
    var categories = [];

    for (let i = 0; i < value.length; i++) {
      const element = value[i];
      if (element.startsWith('SubCateId-')) {
        subcategories.push(parseInt(element.split('-')[1]));
      }
      else {
        categories.push(parseInt(element.split('-')[1]));
      }
    }

    this.categoryId = categories.join(',');
    this.subCategoryId = subcategories.join(',');
    this.productComponent.index = 1;
    this.productComponent.categoryId = this.categoryId;
    this.productComponent.subCategoryId = this.subCategoryId;
    this.productComponent.getProducts();
  }
}

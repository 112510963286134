import { SingleResponse } from './../../model/single-response';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private baseService: ApiService) { }

  public getArticleById(id: number): Promise<SingleResponse<any>> {
    return this.baseService.GetById<SingleResponse<any>>(this.baseService.clietnWebAPIUrl + 'Article/GetArticleById', id).toPromise();
  }

  public getArticles(index: number, size: number) {
    return this
      .baseService
      .GetAll<SingleResponse<any>>(
        this.baseService.clietnWebAPIUrl +
        'Article/GetAllArticles?index=' + `${index}` + '&size=' + `${size}`).toPromise();
  }

  public getLatestArticles() {
    return this
      .baseService
      .GetAll<SingleResponse<any>>(
        this.baseService.clietnWebAPIUrl +
        'Article/GetLatestArticles').toPromise();
  }

  public getNewsRoomCategories() {
    return this
      .baseService
      .GetAll<SingleResponse<any>>(
        this.baseService.clietnWebAPIUrl +
        'Article/GetNewsRoomCategories').toPromise();
  }

  public getNewsRoomSubCategoriesByCategoryId(Id,index: number, size: number) {
    return this
      .baseService
      .GetAll<SingleResponse<any>>(
        this.baseService.clietnWebAPIUrl +
        'Article/GetNewsRoomSubCategoriesByCategoryId?id='+ Id + '&index=' + `${index}` + '&size=' + `${size}`).toPromise();
  }
}

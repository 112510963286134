<section class="app2 team-section">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6 text-center teams">
                <div class="our-team">
                    <div class="set-relative">
                        <div class="border-around overflow-hide m-auto">
                            <div class="set-relative overflow-hide">
                                <img src="assets/images/app_landing2/team/1.png" alt="" class="img-fluid team">
                                <div class="overlay-team set-abs">
                                    <img src="assets/images/app_landing2/team/hover-wave-black.png" alt="" class="img-fluid wave">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="set-relative set-border">
                        <div class="set-abs top-0 borders"></div>
                    </div>
                    <div class="employee">
                        <h4 class="e-name text-center">ken pitersan</h4>
                        <h6 class="post text-center ">Seniour UI/Xi Designer</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 text-center teams">
                <div class="our-team">
                    <div class="set-relative">
                        <div class="border-around overflow-hide m-auto">
                            <div class="set-relative overflow-hide">
                                <img src="assets/images/app_landing2/team/2.png" alt="" class="img-fluid team">
                                <div class="overlay-team set-abs">
                                    <img src="assets/images/app_landing2/team/hover-wave-black.png" alt="" class="img-fluid wave">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="set-relative set-border">
                        <div class="set-abs top-0 borders"></div>
                    </div>
                    <div class="employee">
                        <h4 class="e-name text-center">ken pitersan</h4>
                        <h6 class="post text-center ">Seniour UI/Xi Designer</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 text-center teams">
                <div class="our-team">
                    <div class="set-relative">
                        <div class="border-around overflow-hide m-auto">
                            <div class="set-relative overflow-hide">
                                <img src="assets/images/app_landing2/team/3.png" alt="" class="img-fluid team">
                                <div class="overlay-team set-abs">
                                    <img src="assets/images/app_landing2/team/hover-wave-black.png" alt="" class="img-fluid wave">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="set-relative set-border">
                        <div class="set-abs top-0 borders"></div>
                    </div>
                    <div class="employee">
                        <h4 class="e-name text-center">ken pitersan</h4>
                        <h6 class="post text-center ">Seniour UI/Xi Designer</h6>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-sm-6 text-center teams">
                <div class="our-team">
                    <div class="set-relative">
                        <div class="border-around overflow-hide m-auto">
                            <div class="set-relative overflow-hide">
                                <img src="assets/images/app_landing2/team/4.png" alt="" class="img-fluid team">
                                <div class="overlay-team set-abs">
                                    <img src="assets/images/app_landing2/team/hover-wave-black.png" alt="" class="img-fluid wave">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="set-relative set-border">
                        <div class="set-abs top-0 borders"></div>
                    </div>
                    <div class="employee">
                        <h4 class="e-name text-center">ken pitersan</h4>
                        <h6 class="post text-center ">Seniour UI/Xi Designer</h6>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="event counter set-relative bg bg-img3 bg-about" [ngStyle]="{'background-image': 'url(assets/images/event/counter-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/event/counter/1.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h2 class="count-number text-center text-white counts">14</h2>
                        <h6 class="count-desc text-center text-white">Total Topics</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/event/counter/2.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h2 class="count-number text-center text-white counts">08</h2>
                        <h6 class="count-desc text-center text-white">Total Speaker</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/event/counter/3.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h2 class="count-number text-center text-white counts">28</h2>
                        <h6 class="count-desc text-center text-white">Total event</h6>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-6 counter-container">
                <div class="counters">
                    <img src="assets/images/event/counter/4.png" alt="" class="img-fluid counter-img">
                    <div class="counter-text">
                        <h2 class="count-number text-center text-white counts">9195</h2>
                        <h6 class="count-desc text-center text-white">Total attendance</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="faq" class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="faq-block">
                    <div>
                        <h3 class="frequent-text">Frequently Asked Questions</h3>
                        <h6>Our users are impatient. They're probably distracted too. Keep it simple and beautiful, fun
                            and functional. Clean aesthetics supported by a strong concept is what we stand for.</h6>
                            <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                                <ngb-panel id="static-1" class="card">
                                  <ng-template ngbPanelTitle class="card-header">
                                      <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                      aria-expanded="false">
                                     <a>
                                         <div class="fa fa-angle-right rotate"></div>
                                     </a>
                                     Do I need to make a payment?
                                 </div>
                                  </ng-template>
                                  <ng-template ngbPanelContent class="card-body">
                                      No one cares about products. People care about ideas. Is a product an idea?
                                      Noup. Is
                                      a brand? A good one is.
                                  </ng-template>
                                </ngb-panel>
                                <ngb-panel id="static-2" class="card">
                                    <ng-template ngbPanelTitle class="card-header">
                                        <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                        aria-expanded="false">
                                       <a>
                                           <div class="fa fa-angle-right rotate"></div>
                                       </a>
                                       Do I need to make a payment?
                                   </div>
                                    </ng-template>
                    
                                    <ng-template ngbPanelContent class="card-body">
                                        No one cares about products. People care about ideas. Is a product an idea?
                                        Noup. Is
                                        a brand? A good one is.
                                    </ng-template>
                                  </ngb-panel>
                                  <ngb-panel id="static-3" class="card">
                                      <ng-template ngbPanelTitle class="card-header">
                                          <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                          aria-expanded="false">
                                         <a>
                                             <div class="fa fa-angle-right rotate"></div>
                                         </a>
                                         Do I need to make a payment?
                                     </div>
                                      </ng-template>
                      
                                      <ng-template ngbPanelContent class="card-body">
                                          No one cares about products. People care about ideas. Is a product an idea?
                                          Noup. Is
                                          a brand? A good one is.
                                      </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="static-4" class="card">
                                        <ng-template ngbPanelTitle class="card-header">
                                            <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                            aria-expanded="false">
                                           <a>
                                               <div class="fa fa-angle-right rotate"></div>
                                           </a>
                                           Do I need to make a payment?
                                       </div>
                                        </ng-template>
                        
                                        <ng-template ngbPanelContent class="card-body">
                                            No one cares about products. People care about ideas. Is a product an idea?
                                            Noup. Is
                                            a brand? A good one is.
                                        </ng-template>
                                      </ngb-panel>
                              </ngb-accordion>
                        <h6 class="link">Still have a question? Reach out to us: <a>demo@123.com</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/saas1/faq-img.png" class="img-fluid" alt="faq-person">
                </div>
            </div>
        </div>
    </div>
</section>
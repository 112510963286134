<div class="agency">
    <app-product-header></app-product-header>
</div>

<!-- option 2 with 3 section starts here -->
<!-- Manifesto section starts -->
<!-- <section class="manifesto-main-box manifesto-header-content-bg pb-0" id="divManifesto1" (mousewheel)="mousewheel($event)">
   
    <div class="mani-bg-abs" id="mani-bg"></div>
    <div class="mani-bg-overlay"></div>
    <div class="container">
        <div class="row">
            <div id="div1" class="col-12">
                <div class="manifesto-header-content">
                    <h2>Black Swan - Manifesto</h2>
                    <h5>Breaking Consensus Paradigms and Exploring New Ideas</h5>
                    <p>
                        Black Swan is a company based in Kristiansand, Norway, and we are passionate about contributing
                        to
                        the new world. The world is undergoing rapid changes, and we want to be part of the movement
                        towards a more equitable, sustainable, and innovative future. We are committed to incentivizing
                        the
                        exploration of new ideas and methods for solving difficult problems, and we want to break the
                        current
                        consensus of paradigms.
                    </p>
                    <p>We are named after the concept of the black swan event, which represents an unpredictable and
                        rare
                        occurrence that has a profound impact on the world. Just like the discovery of black swans in
                        Australia, which was once thought to be impossible, we want to challenge the assumptions and
                        beliefs that are holding back progress.
                    </p>
                    <p>We believe that the world needs more pragmatic black swan events, and we want to encourage and
                        support individuals and organizations that are willing to take risks and explore new ideas. We
                        want to
                        create a culture of innovation and creativity, where failure is seen as an opportunity for
                        learning and
                        growth. As the late Henry Ford put it; Failure is simply the opportunity to begin again, this
                        time more
                        intelligently
                    </p>
                    <p>We believe that the current consensus paradigms are not serving the world well. From the way we
                        approach sustainability to the way we organize our societies, we need to break out of old ways
                        of
                        thinking and embrace new ideas. We want to challenge the status quo and encourage people to
                        think
                        outside the box.
                    </p>
                    <p>We are inspired by other black swan events that were once thought to be impossible but are now
                        considered self-evident phenomena. The internet, for example, was once a radical idea that few
                        people believed would be successful. Today, it is an essential part of our lives. The same can
                        be said
                        for renewable energy, which was once considered too expensive and inefficient. Now, it is
                        becoming
                        the norm.
                    </p>
                    <p>We believe that there are many other ideas and methods out there that are waiting to be
                        discovered
                        and explored. We want to contribute to the environments where these ideas can flourish, and we
                        want
                        to provide the resources and support necessary for their success and evolvement.</p>
                    <p>We are committed to sustainability and social responsibility. We believe that the world needs to
                        transition to a more sustainable and equitable future, and we want to take part in the solution.
                        We
                        want to invest in and promote sustainability to reduce our carbon footprint. We also want to
                        create a
                        culture of social responsibility, where we give back to our communities and support those who
                        are
                        less fortunate.
                    </p>
                    <p>We believe in the power of collaboration. We want to work with individuals and organizations that
                        share our vision and values. We believe that by working together, we can achieve more than we
                        could
                        on our own.
                    </p>
                </div>
            </div>
            <div id="div2" class="col-12">
                <div class="manifesto-header-content">
                    <h2>A deeper dive</h2>
                    <p>
                        The concept of black swan events refers to highly improbable, unpredictable, and consequential
                        events that disrupt the existing order and have far-reaching consequences. Black swan events
                        have
                        played a crucial role in shaping human history and have led to significant advancements in
                        various
                        fields. From the discovery of electricity to the development of the internet, black swan events
                        have
                        changed the course of human history.
                    </p>
                    <p>At the same time, black swan events also pose
                        significant challenges to our existing institutions,
                        systems, and beliefs. They force us to question our
                        assumptions and push us out of our comfort zones.
                        They often reveal the shortcomings of our existing
                        approaches and force us to find new and innovative
                        solutions.

                    </p>
                    <p>As we navigate the challenges of the 21st century, we
                        are likely to encounter more black swan events. The
                        pace of change is accelerating, and new technologies
                        and ideas are emerging at an unprecedented rate. In
                        such a dynamic environment, it is essential to be open
                        to new possibilities and embrace the unknown.
                    </p>
                    <p>At Black Swan, we believe that black swan events are not just random occurrences but are also the
                        result of deliberate and intentional efforts to explore new ideas and approaches. We believe
                        that by
                        incentivizing the exploration of new ideas and methods, we can increase the likelihood of
                        encountering pragmatic black swan events that have the potential to transform the world for the
                        better
                    </p>
                    <p>Therefore, we are committed to creating a culture of innovation and creativity, where new ideas
                        are
                        encouraged, and risk-taking is seen as a necessary component of progress. We want to empower
                        individuals and organizations to take bold and unconventional approaches to solving complex
                        problems. By doing so, we believe that we can increase the probability of encountering positive
                        black
                        swan events that will propel us towards new destinations and new possibilities.
                    </p>
                </div>
            </div>
            <div id="div3" class="col-12">
                <div class="manifesto-header-content">
                    <h2>Conclusion</h2>
                    <p>
                        In conclusion, we at Black Swan are passionate about breaking consensus paradigms and exploring
                        new ideas. We are where we are today because of black swan events, and we will continue to reach
                        new higher grounds because of black swan events. By embracing the unknown and taking calculated
                        risks, we can increase the likelihood of encountering pragmatic black swan events that have the
                        potential to transform the world for the better. At Black Swan, we are committed to
                        incentivizing the
                        exploration of new ideas and methods, and we believe that this approach can help us navigate the
                        challenges of the 21st century and create a more equitable, sustainable, and innovative future.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> -->
<!-- option 2 with 3 section ends here -->

<!-- <section class="manifesto-main-box manifesto-header-content-bg-1" id="divManifesto2" 
    (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="manifesto-header-content-1">
                    <h2>Black Swan - Manifesto</h2>
                    <h5>Breaking Consensus Paradigms and Exploring New Ideas</h5>
                    <p>We are named after the concept of the black swan event, which represents an unpredictable and
                        rare
                        occurrence that has a profound impact on the world. Just like the discovery of black swans in
                        Australia, which was once thought to be impossible, we want to challenge the assumptions and
                        beliefs that are holding back progress.
                    </p>
                    <p>We believe that the world needs more pragmatic black swan events, and we want to encourage and
                        support individuals and organizations that are willing to take risks and explore new ideas. We
                        want to
                        create a culture of innovation and creativity, where failure is seen as an opportunity for
                        learning and
                        growth. As the late Henry Ford put it; Failure is simply the opportunity to begin again, this
                        time more
                        intelligently
                    </p>
                    <p>We believe that the current consensus paradigms are not serving the world well. From the way we
                        approach sustainability to the way we organize our societies, we need to break out of old ways
                        of
                        thinking and embrace new ideas. We want to challenge the status quo and encourage people to
                        think
                        outside the box.
                    </p>
                    <p>We are inspired by other black swan events that were once thought to be impossible but are now
                        considered self-evident phenomena. The internet, for example, was once a radical idea that few
                        people believed would be successful. Today, it is an essential part of our lives. The same can
                        be said
                        for renewable energy, which was once considered too expensive and inefficient. Now, it is
                        becoming
                        the norm.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <div id="divManifesto3" class="manifesto-header-content-2" style="opacity: 0;" (mousewheel)="mousewheel($event)">
        <h2>Black Swan - Manifesto</h2>
        <h5>Breaking Consensus Paradigms and Exploring New Ideas</h5>        
        <p>We believe that there are many other ideas and methods out there that are waiting to be discovered
            and explored. We want to contribute to the environments where these ideas can flourish, and we want
            to provide the resources and support necessary for their success and evolvement.</p>
        <p>We are committed to sustainability and social responsibility. We believe that the world needs to
            transition to a more sustainable and equitable future, and we want to take part in the solution. We
            want to invest in and promote sustainability to reduce our carbon footprint. We also want to create a
            culture of social responsibility, where we give back to our communities and support those who are
            less fortunate.
        </p>
        <p>We believe in the power of collaboration. We want to work with individuals and organizations that
            share our vision and values. We believe that by working together, we can achieve more than we could
            on our own.
        </p>

    </div> -->

<!-- <div class="container">
        <div class="row">
            <div class="col-12">

            </div>
        </div>
    </div> -->

<!-- Manifesto section ends -->


<!-- option 3 with ? section starts here -->
<!-- Manifesto section starts -->
<section id="Section1" style="opacity: 1;" class="manifesto-bg manifesto-bg-1 wow fadeIn" data-wow-delay=".5s"  (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="col-md-8 active"  id="text1">
                <h2>Black Swan - Manifesto</h2>
                <h5>Breaking Consensus Paradigms and Exploring New Ideas</h5>
                <p>
                    Black Swan is a company based in Kristiansand, Norway, and we are passionate about contributing to
                    the new world. The world is undergoing rapid changes, and we want to be part of the movement
                    towards a more equitable, sustainable, and innovative future. We are committed to incentivizing the
                    exploration of new ideas and methods for solving difficult problems, and we want to break the
                    current
                    consensus of paradigms.

                </p>
                <p class="mb-0">We are named after the concept of the black swan event, which represents an
                    unpredictable and rare
                    occurrence that has a profound impact on the world. Just like the discovery of black swans in
                    Australia, which was once thought to be impossible, we want to challenge the assumptions and
                    beliefs that are holding back progress.
                </p>
            </div>
        </div>
    </div>
</section>

<section id="Section2" style="opacity: 0;" class="manifesto-bg manifesto-bg-2"  (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="offset-md-4 col-md-8"  id="text2">
                <h2>Black Swan - Manifesto</h2>
                <h5>Breaking Consensus Paradigms and Exploring New Ideas</h5>
                <p>
                    We believe that the world needs more pragmatic black swan events, and we want to encourage and
                    support individuals and organizations that are willing to take risks and explore new ideas. We want
                    to
                    create a culture of innovation and creativity, where failure is seen as an opportunity for learning
                    and
                    growth. As the late Henry Ford put it; Failure is simply the opportunity to begin again, this time
                    more
                    intelligently

                </p>
                <p class="mb-0">We believe that the current consensus paradigms are not serving the world well. From the
                    way we
                    approach sustainability to the way we organize our societies, we need to break out of old ways of
                    thinking and embrace new ideas. We want to challenge the status quo and encourage people to think
                    outside the box.

                </p>
            </div>
        </div>
    </div>
</section>

<section id="Section3" style="opacity: 0;" class="manifesto-bg manifesto-bg-3"  (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="col-md-8" id="text3">
                <h2>Black Swan - Manifesto</h2>
                <h5>Breaking Consensus Paradigms and Exploring New Ideas</h5>
                <p>
                    We are inspired by other black swan events that were once thought to be impossible but are now
                    considered self-evident phenomena. The internet, for example, was once a radical idea that few
                    people believed would be successful. Today, it is an essential part of our lives. The same can be
                    said
                    for renewable energy, which was once considered too expensive and inefficient. Now, it is becoming
                    the norm.

                </p>
                <p class="mb-0">We believe that there are many other ideas and methods out there that are waiting to be
                    discovered
                    and explored. We want to contribute to the environments where these ideas can flourish, and we want
                    to provide the resources and support necessary for their success and evolvement.
                </p>
            </div>
        </div>
    </div>
</section>

<section id="Section4" style="opacity: 0;" class="manifesto-bg manifesto-bg-4"  (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="col-md-12" id="text4">
                <h2>Black Swan - Manifesto</h2>
                <h5>Breaking Consensus Paradigms and Exploring New Ideas</h5>
                <p>
                    We are committed to sustainability and social responsibility. We believe that the world needs to
                    transition to a more sustainable and equitable future, and we want to take part in the solution. We
                    want to invest in and promote sustainability to reduce our carbon footprint. We also want to create
                    a
                    culture of social responsibility, where we give back to our communities and support those who are
                    less fortunate.

                </p>
                <p class="mb-0">We believe in the power of collaboration. We want to work with individuals and
                    organizations that
                    share our vision and values. We believe that by working together, we can achieve more than we could
                    on our own.

                </p>
            </div>
        </div>
    </div>
</section>

<section id="Section5" style="opacity: 0;" class="manifesto-bg manifesto-bg-5"  (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="col-md-12" id="text5">
                <h2>A deeper dive</h2>               
                <p>
                    The concept of black swan events refers to highly improbable, unpredictable, and consequential
                    events that disrupt the existing order and have far-reaching consequences. Black swan events have
                    played a crucial role in shaping human history and have led to significant advancements in various
                    fields. From the discovery of electricity to the development of the internet, black swan events have
                    changed the course of human history.

                </p>
                <p>At the same time, black swan events also pose
                    significant challenges to our existing institutions,
                    systems, and beliefs. They force us to question our
                    assumptions and push us out of our comfort zones.
                    They often reveal the shortcomings of our existing
                    approaches and force us to find new and innovative
                    solutions.

                </p>
                <p class="mb-0">
                    As we navigate the challenges of the 21st century, we
                    are likely to encounter more black swan events. The
                    pace of change is accelerating, and new technologies
                    and ideas are emerging at an unprecedented rate. In
                    such a dynamic environment, it is essential to be open
                    to new possibilities and embrace the unknown.
                </p>
            </div>
        </div>
    </div>
</section>
<section id="Section6" style="opacity: 0;" class="manifesto-bg manifesto-bg-6"  (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="offset-md-4 col-md-8" id="text6">
                <h2>A deeper dive</h2>                
                <p>
                    At Black Swan, we believe that black swan events are not just random occurrences but are also the
                    result of deliberate and intentional efforts to explore new ideas and approaches. We believe that by
                    incentivizing the exploration of new ideas and methods, we can increase the likelihood of
                    encountering pragmatic black swan events that have the potential to transform the world for the
                    better.

                </p>

                <p class="mb-0">
                    Therefore, we are committed to creating a culture of innovation and creativity, where new ideas are
                    encouraged, and risk-taking is seen as a necessary component of progress. We want to empower
                    individuals and organizations to take bold and unconventional approaches to solving complex
                    problems. By doing so, we believe that we can increase the probability of encountering positive
                    black
                    swan events that will propel us towards new destinations and new possibilities.
                </p>
            </div>
        </div>
    </div>
</section>
<section id="Section7" style="opacity: 0;" class="manifesto-bg manifesto-bg-7"  (mousewheel)="mousewheel($event)">
    <div class="container">
        <div class="row">
            <div class="offset-md-4 col-md-8" id="text7">
                <h2>Conclusion</h2>                
                <p>
                    In conclusion, we at Black Swan are passionate about breaking consensus paradigms and exploring
                    new ideas. We are where we are today because of black swan events, and we will continue to reach
                    new higher grounds because of black swan events. By embracing the unknown and taking calculated
                    risks, we can increase the likelihood of encountering pragmatic black swan events that have the
                    potential to transform the world for the better. At Black Swan, we are committed to incentivizing
                    the
                    exploration of new ideas and methods, and we believe that this approach can help us navigate the
                    challenges of the 21st century and create a more equitable, sustainable, and innovative future.
                </p>                
            </div>
        </div>
    </div>
</section>
<!-- option 3 with ? section ends here -->
<!-- Manifesto section ends -->
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { map } from 'rxjs/internal/operators';
import { filter } from 'rxjs/operators';
import { AppIntializerService } from 'src/app/shared/service/app-intializer.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public url: any;
  public breadcrumbTitle: string;
  public breadcrumbTitleKey: string;

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    public languageService: LanguageService,
    public appIntializerService: AppIntializerService) {
    this.router.events
      .pipe(filter((event) => {
        if (event instanceof NavigationEnd) {
          this.url = event.url;
        }
        return event instanceof NavigationEnd;
      }))
      .pipe(map(() => this.activatedRoute))
      .pipe(map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(filter(route => route.outlet === PRIMARY_OUTLET))
      .subscribe(event => {
        const breadcrumbKey = event.snapshot.data.breadcrumbTitleKey;
        this.breadcrumbTitleKey = breadcrumbKey;
        this.setBreadCrumbTitle(this.breadcrumbTitleKey);
      });

    this.languageService.languageDataSubject$.subscribe(res => {
      localStorage.setItem('data', JSON.stringify(res));
      this.setBreadCrumbTitle(this.breadcrumbTitleKey);
    });
  }

  ngOnInit() {
  }

  setBreadCrumbTitle(childkey: string) {
    const translatedJson = JSON.parse(localStorage.getItem('data'));
    this.breadcrumbTitle = translatedJson[childkey];
  }
}

<!-- price filter start here -->
<div class="collection-collapse-block border-0 open">
    <h3 class="collapse-block-title">{{ langContent?.Price }}</h3>
    <div class="collection-collapse-block-content">
        <div class="collection-brand-filter">
            <div class="mt-5">
                <ng5-slider [(value)]="value" [(highValue)]="highValue" [options]="options" (userChangeEnd)="onUserChangeEnd($event)">
                </ng5-slider>
            </div>
        </div>
    </div>
</div>
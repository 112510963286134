
  <!-- section start -->
  <section class="section-b-space">
    <div class="collection-wrapper">
      <div class="container">
        <div class="row">
          <!-- End Collection Sidebar -->
          <div class="collection-content col">
            <div class="page-main-content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="collection-product-wrapper">
                      <!-- Display Products -->
                      <div class="product-wrapper-grid shop-sample-4" [class.list-view]="listView">
                        <div class="container-fluid">
                          <div class="row">
                            <div [class.col-xl-2]="col_xl_2" [class.col-xl-12]="col_xl_12" [class.col-xl-3]="col_xl_3"
                              [class.col-xl-4]="col_xl_3" [class.col-md-6]="col_md_6" class="col-grid-box"
                              *ngFor="let newsRoomCategory of newsRoomCategories">
                              <div class="product-box">

                                <div class="img-wrapper">
                                    <div class="front" [routerLink]="['/newsroom/news-room-subcategory/', newsRoomCategory?.id]">
                                      <a>
                                        <img
                                          alt=""
                                          class="img-fluid"
                                          routerLinkActive="active"
                                          [src]="newsRoomCategory.image"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                  
                                  <div class="product-detail">
                                    <a>
                                      <h6>{{ newsRoomCategory.name }}</h6>
                                    </a>
                                  </div>
                                  


                                <!-- <app-category-box class="list-product" [category]="newsRoomCategory"></app-category-box> -->
                              </div>
                            </div>

                            <!-- <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!categories">
                              <img src="assets/images/empty-search.jpg" class="img-fluid mb-4" />
                              <h3>{{ content?.SorryCanNotFindTheProduct }}</h3>
                              <p>
                                {{ content?.PleaseCheckMisspeltSomething }}.
                              </p>
                              <a [routerLink]="'/e-commerce'" class="btn btn-default primary-btn ra-dius-0 m-t-10">{{
                                content?.ContinueShopping }}</a>
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<div class="agency">
    <app-product-header></app-product-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb-contain">
              <div>
                <h2 class="breadcrumb-txt">{{content?.MenuNewsRoom}}</h2>
            </div>
              <div>
                <ul>
                    <li><a [routerLink]="['/newsroom']">{{content?.MenuNewsRoom}}</a></li>
                    <li><a><i class="fa fa-angle-double-right"></i>{{ content?.NewsRoomCategory }}</a></li>
                  <li>
                    <a
                      ><i class="fa fa-angle-double-right"></i
                      >{{ content?.NewsRoomSubCategory}}</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--breadcrumb section end -->
  
    <!-- section start -->
    <section class="section-b-space">
      <div class="collection-wrapper">
        <div class="container">
          <div class="row">
            <!-- End Collection Sidebar -->
            <div class="collection-content col">
              <div class="page-main-content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="collection-product-wrapper">
                        <!-- Display Products -->
                        <div
                          class="product-wrapper-grid shop-sample-4"
                          [class.list-view]="listView"
                        >
                          <div class="container-fluid">
                            <div class="row">
                              <div
                                [class.col-xl-2]="col_xl_2"
                                [class.col-xl-12]="col_xl_12"
                                [class.col-xl-3]="col_xl_3"
                                [class.col-xl-4]="col_xl_3"
                                [class.col-md-6]="col_md_6"
                                class="col-grid-box"
                                *ngFor="let subcategory of newsRoomSubCategories"
                              >
                                <div class="product-box">

                                    <div class="list-product">
                                    <div class="img-wrapper">
                                        <a
                                          ><div
                                            class="front"
                                            [routerLink]="['/newsroom/news-room-details', subcategory?.id]"
                                          >
                                            <img
                                              alt=""
                                              class="img-fluid"
                                              [routerLink]="['/newsroom/news-room-details', subcategory?.id]"
                                              routerLinkActive="active"
                                              [src]="subcategory.image"
                                            />
                                          </div>
                                        </a>
                                      </div>
                                      
                                      <div class="product-detail">
                                        <a>
                                          <h6>{{ subcategory.name }}</h6>
                                        </a>
                                      </div>                                    
                                    </div>
                                </div>
                              </div>               
                            </div>
                          </div>
                        </div>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


     <!-- pagination Start -->
     <div class="pagination_sec mb-3">
      <div class="content_detail__pagination cdp">
          <ngb-pagination class="d-flex justify-content-center" [collectionSize]="pageCollectionSize" #numPages
              [pageSize]="size" [(page)]="index" (pageChange)="onPageChange()" [maxSize]="10" [rotate]="true"
              [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
      </div>
  </div>
  <!-- pagination End -->
    <!-- section End -->
    <app-product-footer></app-product-footer>
  </div>
  
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Options, ChangeContext, PointerType } from 'ng5-slider';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { ProductComponent } from "../product/product.component";

@Component({
  selector: 'app-product-price',
  templateUrl: './product-price.component.html',
  styleUrls: ['./product-price.component.scss']
})
export class ProductPriceComponent implements OnInit {

  langContent;
  sliderForm: FormGroup = new FormGroup({
    sliderControl: new FormControl([100, 10001])
  });

  minPrice: number;
  maxPrice: number;
  value: number;
  highValue: number;

  options: Options = {
    floor: 0,
    ceil: 1000,
    step: 10
  };

  private productComponent: ProductComponent;

  constructor(public languageService: LanguageService, public productComponentObj: ProductComponent) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.langContent = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.productComponent = productComponentObj;
    this.productComponentObj.minAndmaxPrice$.subscribe(res => {
      this.minPrice = res?.minPrice;
      this.maxPrice = res?.maxPrice;
      this.value = res?.priceValue;
      this.highValue = res?.highValue;

      this.options = {
        floor: this.minPrice,
        ceil: this.maxPrice,
        step: 10
      };
    });

    this.langContent = JSON.parse(localStorage.getItem('data'));
  }

  ngOnInit() { }

  onUserChangeEnd(changeContext: ChangeContext): void {
    this.value = changeContext.value;
    this.productComponent.index = 1;
    this.productComponent.minPrice = this.minPrice;
    this.productComponent.maxPrice = this.maxPrice;
    this.productComponent.priceValue = changeContext.value;
    this.productComponent.highValue = changeContext.highValue;
    this.productComponent.getProducts(changeContext.value, changeContext.highValue);
  }

}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SingleResponse } from '../../model/single-response';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class OrderService {

  // Array
  public OrderDetails;

  constructor(private router: Router, private baseService: ApiService,private httpClient: HttpClient,) { }

  public getOrderById(id: string): Promise<SingleResponse<any>> {
    // tslint:disable-next-line:max-line-length
    return this.baseService.GetAll<SingleResponse<any>>(this.baseService.clietnWebAPIUrl + 'Order/GetOrderedProductsByOrderNumber?OrderNumber=' + id).toPromise();
  }

  public getOrders(index: number, size: number) {
    return this
      .baseService
      .GetAll<SingleResponse<any>>(
        this.baseService.clietnWebAPIUrl +
        'Order/GetCustomerOrderedList?index=' + `${index}` + '&size=' + `${size}`).toPromise();
  }
  public downloadOrderInvoice(id: string){  
    return this.httpClient.get(this.baseService.clietnWebAPIUrl + 'Order/DownloadInvoice?OrderNumber=' + id, {  
        responseType: 'blob'  
    });  
}
}

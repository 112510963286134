import { map, tap } from 'rxjs/operators';
import { GymService } from './../../../shared/service/gym/gym.service';
import { ApiService } from './../../../shared/service/api.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppIntializerService } from 'src/app/shared/service/app-intializer.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';

@Component({
  selector: 'app-gym-header',
  templateUrl: './gym-header.component.html',
  styleUrls: ['./gym-header.component.scss']
})
export class GymHeaderComponent implements OnInit, OnDestroy {

  dashboardData: any;
  languageData: any;

    headercarousel = [
        {
            tagLine1: 'build your',
            tagLine2: 'body more strong',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took.'
        },
        {
            tagLine1: 'build your',
            tagLine2: 'body more strong',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took.'
        },
    ];

    headercarouselOptions = {
      items: 1,
      margin: 0,
      autoHeight: true,
      nav: false,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: false,
      // slideSpeed: 500,
      // paginationSpeed: 500,
      loop: true,
      video: true
    };

  constructor(private gymService: GymService, public appIntializerService: AppIntializerService, public languageService: LanguageService) {
    this.gymService.dashBoardDataSubject$.subscribe(res => {
      console.log(res);
      this.dashboardData = res?.data?.data;
    });
    this.languageService.languageDataSubject$.subscribe(res => {
      this.languageData = res;
      this.mapDashboardProperty(this.dashboardData, this.languageData);
    });
    this.getCustomerDashboardData();
  }

  ngOnInit() { }

  async getCustomerDashboardData() {
    this.dashboardData = this.appIntializerService.dashboardData.data;
    this.languageData = this.appIntializerService.languageData;
    await this.mapDashboardProperty(this.dashboardData, this.languageData);
    console.log(this.dashboardData);
    console.log(this.languageData);
  }

   private async mapDashboardProperty(dashBoardData: any, languageData: any) {
    this.dashboardData = dashBoardData.
      map((dashBoardItem: any, index) => {
        return {
          id: dashBoardItem?.id,
          content: dashBoardItem?.content,
          contentType: dashBoardItem?.contentType,
          title: languageData[`${'titleKey_' + (index + 1)}`],
          description: languageData[`${'descriptionKey_' + (index + 1)}`],
        };
      }
      );
  }

  ngOnDestroy(): void {
    this.gymService.dashBoardDataSubject$.unsubscribe();
    this.languageService.languageDataSubject$.subscribe();
  }
}
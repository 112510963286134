import {
  Category,
  ColorFilter,
} from "./../../../shared/model/e-commerce/product.model";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { LanguageService } from "../../../shared/service/gym/language.service";
import { ProductsService } from "../../../shared/service/e-commerce/products.service";
import { CategoriesAndSubcategories } from "src/app/shared/model/e-commerce/categories-and-subcategories.mode";

@Injectable({
  providedIn: "root",
})
@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"],
})
export class CategoryComponent implements OnInit {
  content = null;
  public categories: CategoriesAndSubcategories;
  public listView = false;
  public col_xl_3 = true;
  public col_xl_4 = false;
  public col_md_6 = true;
  public col_xl_12 = false;
  public gridOptions = true;
  public active = false;
  public colorFilters: ColorFilter[] = [];
  public tagsFilters: any[] = [];
  sidebaron = false;
  show = false;
  open = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public languageService: LanguageService,
    public productsService: ProductsService
  ) {
    this.getCategories();
    this.languageService.languageDataSubject$.subscribe((res) => {
      this.content = res;
      localStorage.setItem("data", JSON.stringify(res));
    });

    this.content = JSON.parse(localStorage.getItem("data"));
  }

  ngOnInit() {}

  public getCategories() {
    this.productsService.getCategoriesAndSubcategories().then((res) => {
      if (res !== undefined) {
        this.categories = res.data.data;
      }
    });
  }

  openFilter() {
    if (this.show === true && this.sidebaron === true) {
      this.show = false;
      this.sidebaron = false;
    }
    else {
      this.show = true;
      this.sidebaron = true;
    }
  }
  openMediaFilter() {
    if (this.show === false && this.sidebaron === false && this.open === false) {
      this.show = true;
      this.sidebaron = true;
      this.open = true;
    } else {
      this.show = false;
      this.sidebaron = false;
      this.open = false;
    }
  }

  listOpen() {
    this.gridOptions = false;
    this.listView = true;
    this.col_xl_12 = true;
  }

  grid2() {
    this.gridOptions = true;
    this.col_md_6 = true;
    this.col_xl_3 = false;
    this.listView = false;
    this.col_xl_4 = false;
    this.col_xl_12 = false;

  }

  grid3() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_4 = true;
    this.col_xl_3 = false;
    this.col_md_6 = true;
    this.col_xl_12 = false;
  }

  grid4() {
    this.gridOptions = true;
    this.listView = false;
    this.col_xl_3 = true;
    this.col_md_6 = true;
    this.col_xl_4 = false;
    this.col_xl_12 = false;
  }
}

import { ProductCart } from './../../model/e-commerce/product-cart';
import { Subject } from 'rxjs/internal/Subject';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subscriber } from 'rxjs';
import { Products } from '../../../shared/model/e-commerce/product.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './../api.service';
import { SingleResponse } from '../../model/single-response';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cartItems$: Subject<SingleResponse<ProductCart>> = new Subject<SingleResponse<ProductCart>>();
  public observer: Subscriber<{}>;

  public itemList: Products[];
  constructor(
    private toastr: ToastrService, private httpClient: HttpClient,
    private baseService: ApiService
  ) {
  }

  // Get Products
  async getItems() {
    this.baseService.GetAll<SingleResponse<ProductCart>>(this.baseService.clietnWebAPIUrl + 'Order/GetCartProducts').toPromise()
    .then((result: any) => {
      this.cartItems$.next(result);
    })
    .catch((errorResult) => {
      this.toastr.error(errorResult.error);
      return null;
    });
  }

  public addToCart(productId: number, quantity: number, frameProductId?: number): Promise<boolean> {
    const addToCartObj = {
      Quantity: quantity,
      ProductId: productId,
      FrameProductId: frameProductId || null,  // Include the frame product ID
      CustomerId: '00000000-0000-0000-0000-000000000000'
    };
    return this.httpClient.post(this.baseService.APIUrl + 'Order/AddToCart', addToCartObj).toPromise()
      .then((result: any) => {
        this.getItems();
        return result.data > 0;
      })
      .catch((errorResult) => {
        this.toastr.error(errorResult.error);
        return null;
      });
  }

  // Removed in cart
  public removeFromCart(product: any) {
    const deleteToCartObj = {
      OrderId: product.orderId,
      ProductId: product.productId,
      CustomerId: '00000000-0000-0000-0000-000000000000'
    };

    this.httpClient.post(this.baseService.APIUrl + 'Order/RemoveCartProduct', deleteToCartObj).toPromise()
      .then((result: any) => {
        this.getItems();
        this.toastr.success('This product has been removed from the cart.');
      })
      .catch((errorResult) => {
        this.toastr.error('Something went wrong, please try again after sometime');
      });
  }

  public updateCartQuantity(product: any, quantity: number) {
    const updateToCartObj = {
      OrderId: product.orderId,
      Quantity: product.qty + quantity,
      ProductId: product.productId,
      CustomerId: '00000000-0000-0000-0000-000000000000'
    };

    this.httpClient.post(this.baseService.APIUrl + 'Order/UpdateOrderQuantity', updateToCartObj).toPromise()
      .then((result: any) => {
        this.getItems();
        this.toastr.success('Item Updated Successfully');
      })
      .catch((errorResult) => {
        this.toastr.error('Something went wrong please try again after sometime');
      });
  }
}

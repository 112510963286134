<!--calculate bmi start-->
<section class="gym format bg-cal-bmi" id="bmi" [ngStyle]="{'background-image': 'url(assets/images/gym/calculat-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-6 ">
                <div class=" center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="borders-before text-white"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="text-white about-font-header">calculate your BMI</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="p-light about-para text-white">Lorem Ipsum is simply dummy text of the printing
                                and
                                typesetting
                                industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        </div>
                        <div class="link-horizontal">
                            <ul>
                                <li class="">
                                    <div class="checkbox">
                                        <label class="text-white center-text"><input type="checkbox" value="">Metric
                                            Units</label>
                                    </div>
                                </li>
                                <li class="about-icon">
                                    <div class="checkbox">
                                        <label class="text-white center-text"><input type="checkbox" value="">Imperial
                                            Units</label>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="link-horizontal m-t-40 m-b-40">
                            <ul>
                                <li>
                                    <a class="btn btn-default primary-btn transparent">Weight/Kg</a>
                                </li>
                                <li>
                                    <a class="btn btn-default primary-btn transparent">Height/Cm</a>
                                </li>
                            </ul>
                        </div>
                        <a class="btn btn-default back-white">CALCULATE</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 offset-lg-1 col-md-6">
                <div class="center-text">
                    <table class="calculate-bmi">
                        <tr>
                            <td>BMI</td>
                            <td>Weight Status</td>
                        </tr>
                        <tr>
                            <td>Below 18.5</td>
                            <td>Underweight</td>
                        </tr>
                        <tr>
                            <td>18.5 - 24.9</td>
                            <td>Normal</td>
                        </tr>
                        <tr>
                            <td>25 - 29.9</td>
                            <td>Overweight</td>
                        </tr>
                        <tr>
                            <td>30 and Above</td>
                            <td>Obese</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>
<!--calculate bmi end-->

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { blogDetailDB } from 'src/app/shared/data/blog/blog-detail/blog-detail';
import { BlogService } from 'src/app/shared/service/blog/blog.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  content = null;
  blogData: any;
  public latestBlogData: any;

  constructor(
    public languageService: LanguageService, private route: ActivatedRoute,  private blogService: BlogService,private sanitizer: DomSanitizer) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));

    this.route.params.subscribe(params => {
      const id = +params.id;
      this.blogService.getArticleById(id).then(blog => {
        blog.data.content = this.renderHtmlContent(blog.data.content);
        this.blogData = blog?.data;
        this.getLatestArticles();
      });
    });
  }

  async getLatestArticles() {
    this.blogService.getLatestArticles().then(items => {
      this.latestBlogData = items?.data?.data;
    });
  }

  renderHtmlContent(htmlContent: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  ngOnInit(): void {
  }

}

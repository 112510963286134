<!-- inner-1 breadcrumb section start -->
<section class="p-b-0">
    <div class="title title2 title-inner">
        <div class="main-title">
            <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>type-1</span></h2>
        </div>
    </div>
    <section class="agency breadcrumb-section-main breadcrumb-back-none inner-1 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="col-12">
                        <h2 class="breadcrumb-txt text-center">Breadcrumb</h2>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<!-- inner-1 breadcrumb section end -->

<!-- inner-2 breadcrumb section start -->
<section class="p-b-0">
    <div class="title title2 title-inner">
        <div class="main-title">
            <h2 class="font-primary borders text-center main-text text-uppercase m-b-0"><span>type-2</span></h2>
        </div>
    </div>
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">breadcrumb </h2>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>feature</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>breadcrumb center</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<!-- inner-2 breadcrumb section end -->

<!-- inner-3 section start -->
<section class="p-b-0">
    <div class="title title2 title-inner">
        <div class="main-title">
            <h2 class="font-primary borders text-center main-text text-uppercase "><span>type-3</span></h2>
        </div>
    </div>
    <section class="breadcrumb-section-main inner-3">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain">
                        <div>
                            <ul>
                                <li><a href="#"><i class="fa fa-home"></i></a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i> contact</a></li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="breadcrumb-txt">Breadcrumb</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<!-- inner-3 section end -->

<!-- inner-4 section start -->
<section>
    <div class="title title2 title-inner">
        <div class="main-title">
            <h2 class="font-primary borders text-center main-text text-uppercase "><span>type-4</span></h2>
        </div>
    </div>
    <section class="breadcrumb-section-main inner-4 ">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain">
                        <div>
                            <h2 class="breadcrumb-txt">contact</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#">home</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i> contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</section>
<!-- inner-4 section end -->

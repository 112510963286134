<!--breadcrumb section start-->
<section class="agency breadcrumb-section breadcrumb-left" [ngStyle]="{'background-image': 'url(assets/images/inner-page/breadcrumb-plain.jpg)'}">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <h2 class="breadcrumb-text">features with breadcrumb-left</h2>
                </div>
                <div class="col-lg-6">
                    <ul class="breadcrumb">
                        
                        <li><a href="#">breadcrumb style</a></li>
                        <li>breadcrumb left</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end-->

<app-breadcrumb-layout-content></app-breadcrumb-layout-content>
<!-- header Start-->
<section class="gym header" id="home">
    <div class="header5-content">
        <owl-carousel-o [options]="headercarouselOptions" class="default-dots gym-slider">
            <ng-container *ngFor="let header of dashboardData">
                <ng-template carouselSlide class="item">
                    <div class="gym-header bg"
                        [ngStyle]="{'background-image': header.contentType === 'video/mp4' ? '' : 'url(' + header.content + ')'}">
                        <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true" id="background-video"
                            *ngIf="header.contentType === 'video/mp4'">
                            <source [src]="header.content" type="video/mp4">
                        </video>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center"> 
                                            <div class="header-text anim-typewriter">
                                                <h1 class=""><span class="bold-text">{{header?.title}}</span>
                                                </h1>
                                            </div>
                                            <div class="header-sub-text">
                                                <p class="text-white p-light wow fadeIn" data-wow-duration="1s" data-wow-delay="3s">{{header?.description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>

                <!-- <ng-template carouselSlide class="item" *ngIf="header.contentType === 'video/mp4'">
                    <div class="gym-header bg">
                        
                        <div class="container">
                            <div class="row">
                                <div class="col-md-10 offset-md-1">
                                    <div class="center-text">
                                        <div class="text-center">
                                            <div class="header-text">
                                                <h1><span
                                                        class="bold-text">{{header.title}}</span>
                                                </h1>
                                            </div>
                                            <div class="header-sub-text">
                                                <p class="text-white p-light">{{header.description}}</p>
                                            </div>
                                            <div class="link-horizontal">
                                                <ul class="justify-content-center">
                                                    <li>
                                                        <a class=" btn btn-default"><span>register
                                                                20%<sup>off</sup></span></a>
                                                    </li>
                                                    <li>
                                                        <a class=" btn btn-default">start now</a>
                                                    </li>
                                                </ul>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template> -->
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- header end-->
import { CartService } from './../../../shared/service/e-commerce/cart.service';
import { ProductsService } from './../../../shared/service/e-commerce/products.service';
import { WishListService } from './../../../shared/service/e-commerce/wish-list.service';
import { Products } from './../../../shared/model/e-commerce/product.model';
import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { AppIntializerService } from 'src/app/shared/service/app-intializer.service';
@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit {

  @Input() products: Products;
  closeResult: string;
  public selectedSize: any = '';
  public counter = 1;
  langContent = null;

  constructor(private cartService: CartService,
    public productsService: ProductsService, private wishlistService: WishListService,
    private modalService: NgbModal,
    public languageService: LanguageService,
    public appIntializerService: AppIntializerService,
    private toastr: ToastrService) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.langContent = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.getTranslatedData();
  }

  ngOnInit() {
    console.log(this.products);
  }

  getTranslatedData() {
    this.langContent = this.appIntializerService.languageData;
  }

  // Add to cart
  public addToCart(productId: number, quantity: number = 1) {
    if (quantity === 0) { return false; }
    this.cartService.addToCart(productId, quantity)
      .then(result => {
        if (result) {
          this.toastr.success('This product has been added to cart.');
        }
        else if (result === false) {
          this.toastr.error('Something went wrong please try again after sometime');
        }
      });
  }

  // Add to compare
  public addToCompare(product: Products) {
    this.productsService.addToCompare(product);
  }

  // Add to wishlist
  public addToWishlist(product: Products) {
    this.wishlistService.addToWishlist(product);
  }

  open(content, id) {
    this.productsService.getProduct(id).then(product => {
      console.log(product);
      this.products = product.data;
    });
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Change variant
  public changeVariantSize(variant) {
    this.selectedSize = variant;
  }

  public increment(productId: number) {
    this.productsService.getProductQuantity(productId)
      .then(availableQty => {
        this.counter += 1;
        if (availableQty < this.counter) {
          this.counter -= 1;
          this.toastr.error(`Product not available more than ${availableQty}.`);
        }
      });
  }

  public decrement(productId: number) {
    this.productsService.getProductQuantity(productId)
      .then(availableQty => {
        if (this.counter > 1) {
          this.counter -= 1;
          if (this.counter > availableQty) {
            this.counter = availableQty;
            this.toastr.error(`Product not available more than ${availableQty}.`)
          }
        }
      });
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/shared/service/blog/blog.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';

@Component({
  selector: 'app-newsroom-subcategory',
  templateUrl: './newsroom-subcategory.component.html',
  styleUrls: ['./newsroom-subcategory.component.scss']
})
export class NewsroomSubcategoryComponent implements OnInit {
  public listView = false;
  public col_xl_3 = true;
  public col_xl_4 = false;
  public col_md_6 = true;
  public col_xl_12 = false;
  public gridOptions = true;
  public active = false;
  public tagsFilters: any[] = [];
  constructor(
    public languageService: LanguageService, private blogService: BlogService,private route: ActivatedRoute) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));

    this.route.paramMap.subscribe(params => {
      const id = +params.get('id');
      this.getNewsRoomSubCategories(id);
    });
  }

  index = 1;
  size = 8;
  pageCollectionSize: number;
  content = null;
  public newsRoomSubCategories: any;
  ngOnInit(): void {
  }

  async getNewsRoomSubCategories(Id) {
    this.blogService.getNewsRoomSubCategoriesByCategoryId(Id,this.index, this.size).then(items => {
      this.newsRoomSubCategories = items?.data?.data;
      this.pageCollectionSize = items?.data?.totalCount;
    });
  }

  onPageChange() {
    this.getNewsRoomSubCategories(this.route.snapshot.params.id);
  }
}



<!--accordion1 section start-->

<section class="saas1 faq testimonial-bg">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">accordion with image</span>
                      </h2>
                  </div>

              </div>
          </div>
          <div class="col-md-8">
              <div class="faq-block">
                  <div class="w-100">
                    <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                      <ngb-panel id="static-1" class="card">
                        <ng-template ngbPanelTitle class="card-header">
                            <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                            aria-expanded="false">
                           <a>
                               <div class="fa fa-angle-right rotate"></div>
                           </a>
                           Do I need to make a payment?
                       </div>
                        </ng-template>
                        <ng-template ngbPanelContent class="card-body">
                            No one cares about products. People care about ideas. Is a product an idea?
                            Noup. Is
                            a brand? A good one is.
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel id="static-2" class="card">
                          <ng-template ngbPanelTitle class="card-header">
                              <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                              aria-expanded="false">
                             <a>
                                 <div class="fa fa-angle-right rotate"></div>
                             </a>
                             Do I need to make a payment?
                         </div>
                          </ng-template>
          
                          <ng-template ngbPanelContent class="card-body">
                              No one cares about products. People care about ideas. Is a product an idea?
                              Noup. Is
                              a brand? A good one is.
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3" class="card">
                            <ng-template ngbPanelTitle class="card-header">
                                <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                aria-expanded="false">
                               <a>
                                   <div class="fa fa-angle-right rotate"></div>
                               </a>
                               Do I need to make a payment?
                           </div>
                            </ng-template>
            
                            <ng-template ngbPanelContent class="card-body">
                                No one cares about products. People care about ideas. Is a product an idea?
                                Noup. Is
                                a brand? A good one is.
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel id="static-4" class="card">
                              <ng-template ngbPanelTitle class="card-header">
                                  <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                  aria-expanded="false">
                                 <a>
                                     <div class="fa fa-angle-right rotate"></div>
                                 </a>
                                 Do I need to make a payment?
                             </div>
                              </ng-template>
              
                              <ng-template ngbPanelContent class="card-body">
                                  No one cares about products. People care about ideas. Is a product an idea?
                                  Noup. Is
                                  a brand? A good one is.
                              </ng-template>
                            </ngb-panel>
                    </ngb-accordion>
                  </div>
              </div>
          </div>
          <div class="col-md-4">
              <div class="faq-img-block">
                  <img src="assets/images/saas1/faq-img.png" class="img-fluid" alt="faq-person">
              </div>
          </div>
      </div>
  </div>
</section>

<!--accordion1 section end-->


<!--accordion2 section start-->

<section class="saas1 faq accordion testimonial-bg bg-light-inner">
  <div class="container">
      <div class="row">
          <div class="agency col-12">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text m-b-0"><span
                              class="text-uppercase">default accordion</span></h2>
                  </div>

              </div>
          </div>
          <div class="col-md-12">
              <div class="faq-block">
                  <div class="w-100">
                    <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                      <ngb-panel id="static-1" class="card">
                        <ng-template ngbPanelTitle class="card-header">
                            <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                            aria-expanded="false">
                           <a>
                               <div class="fa fa-angle-right rotate"></div>
                           </a>
                           Do I need to make a payment?
                       </div>
                        </ng-template>
                        <ng-template ngbPanelContent class="card-body">
                            No one cares about products. People care about ideas. Is a product an idea?
                            Noup. Is
                            a brand? A good one is.
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel id="static-2" class="card">
                          <ng-template ngbPanelTitle class="card-header">
                              <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                              aria-expanded="false">
                             <a>
                                 <div class="fa fa-angle-right rotate"></div>
                             </a>
                             Do I need to make a payment?
                         </div>
                          </ng-template>
          
                          <ng-template ngbPanelContent class="card-body">
                              No one cares about products. People care about ideas. Is a product an idea?
                              Noup. Is
                              a brand? A good one is.
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3" class="card">
                            <ng-template ngbPanelTitle class="card-header">
                                <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                aria-expanded="false">
                               <a>
                                   <div class="fa fa-angle-right rotate"></div>
                               </a>
                               Do I need to make a payment?
                           </div>
                            </ng-template>
            
                            <ng-template ngbPanelContent class="card-body">
                                No one cares about products. People care about ideas. Is a product an idea?
                                Noup. Is
                                a brand? A good one is.
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel id="static-4" class="card">
                              <ng-template ngbPanelTitle class="card-header">
                                  <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                  aria-expanded="false">
                                 <a>
                                     <div class="fa fa-angle-right rotate"></div>
                                 </a>
                                 Do I need to make a payment?
                             </div>
                              </ng-template>
              
                              <ng-template ngbPanelContent class="card-body">
                                  No one cares about products. People care about ideas. Is a product an idea?
                                  Noup. Is
                                  a brand? A good one is.
                              </ng-template>
                            </ngb-panel>
                    </ngb-accordion>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<!--accordion2 section end-->


<!--accordion3 section start-->

<section class="saas1 accordion faq testimonial-bg">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">accordion with form</span>
                      </h2>
                  </div>

              </div>
          </div>
          <div class="col-md-7">
              <div class="faq-block">
                  <div class="w-100">
                    <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                      <ngb-panel id="static-1" class="card">
                        <ng-template ngbPanelTitle class="card-header">
                            <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                            aria-expanded="false">
                           <a>
                               <div class="fa fa-angle-right rotate"></div>
                           </a>
                           Do I need to make a payment?
                       </div>
                        </ng-template>
                        <ng-template ngbPanelContent class="card-body">
                            No one cares about products. People care about ideas. Is a product an idea?
                            Noup. Is
                            a brand? A good one is.
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel id="static-2" class="card">
                          <ng-template ngbPanelTitle class="card-header">
                              <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                              aria-expanded="false">
                             <a>
                                 <div class="fa fa-angle-right rotate"></div>
                             </a>
                             Do I need to make a payment?
                         </div>
                          </ng-template>
          
                          <ng-template ngbPanelContent class="card-body">
                              No one cares about products. People care about ideas. Is a product an idea?
                              Noup. Is
                              a brand? A good one is.
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3" class="card">
                            <ng-template ngbPanelTitle class="card-header">
                                <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                aria-expanded="false">
                               <a>
                                   <div class="fa fa-angle-right rotate"></div>
                               </a>
                               Do I need to make a payment?
                           </div>
                            </ng-template>
            
                            <ng-template ngbPanelContent class="card-body">
                                No one cares about products. People care about ideas. Is a product an idea?
                                Noup. Is
                                a brand? A good one is.
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel id="static-4" class="card">
                              <ng-template ngbPanelTitle class="card-header">
                                  <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                  aria-expanded="false">
                                 <a>
                                     <div class="fa fa-angle-right rotate"></div>
                                 </a>
                                 Do I need to make a payment?
                             </div>
                              </ng-template>
              
                              <ng-template ngbPanelContent class="card-body">
                                  No one cares about products. People care about ideas. Is a product an idea?
                                  Noup. Is
                                  a brand? A good one is.
                              </ng-template>
                            </ngb-panel>
                    </ngb-accordion>
                  </div>
              </div>
          </div>
          <div class="col-md-5">
              <div class="register-page section-b-space">

                  <h3>ask your question</h3>
                  <p class="text-sub">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                      Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                  <div class="theme-card">
                      <form class="theme-form">
                          <input type="text" class="form-control" id="fname" placeholder="First Name"
                                 required="">

                          <input type="password" class="form-control" id="lname" placeholder="Last Name"
                                 required="">
                          <div class="form-group">
                              <textarea class="form-control" rows="6" placeholder="your question"></textarea>
                          </div>
                          <a href="#" class="btn primary-btn btn-default radius-0">submit</a>
                      </form>
                  </div>

              </div>
          </div>
      </div>
  </div>
</section>

<!--accordion section end-->


<!--accordion1 section start-->

<section class="saas1 accordion faq testimonial-bg bg-light-inner">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">accordion with video</span>
                      </h2>
                  </div>

              </div>
          </div>
          <div class="col-md-7">
              <div class="faq-block">
                  <div class="w-100">
                    <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                      <ngb-panel id="static-1" class="card">
                        <ng-template ngbPanelTitle class="card-header">
                            <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                            aria-expanded="false">
                           <a>
                               <div class="fa fa-angle-right rotate"></div>
                           </a>
                           Do I need to make a payment?
                       </div>
                        </ng-template>
                        <ng-template ngbPanelContent class="card-body">
                            No one cares about products. People care about ideas. Is a product an idea?
                            Noup. Is
                            a brand? A good one is.
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel id="static-2" class="card">
                          <ng-template ngbPanelTitle class="card-header">
                              <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                              aria-expanded="false">
                             <a>
                                 <div class="fa fa-angle-right rotate"></div>
                             </a>
                             Do I need to make a payment?
                         </div>
                          </ng-template>
          
                          <ng-template ngbPanelContent class="card-body">
                              No one cares about products. People care about ideas. Is a product an idea?
                              Noup. Is
                              a brand? A good one is.
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="static-3" class="card">
                            <ng-template ngbPanelTitle class="card-header">
                                <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                aria-expanded="false">
                               <a>
                                   <div class="fa fa-angle-right rotate"></div>
                               </a>
                               Do I need to make a payment?
                           </div>
                            </ng-template>
            
                            <ng-template ngbPanelContent class="card-body">
                                No one cares about products. People care about ideas. Is a product an idea?
                                Noup. Is
                                a brand? A good one is.
                            </ng-template>
                          </ngb-panel>
                          <ngb-panel id="static-4" class="card">
                              <ng-template ngbPanelTitle class="card-header">
                                  <div class="collapsed" data-toggle="collapse" data-target="#collapse1"
                                  aria-expanded="false">
                                 <a>
                                     <div class="fa fa-angle-right rotate"></div>
                                 </a>
                                 Do I need to make a payment?
                             </div>
                              </ng-template>
              
                              <ng-template ngbPanelContent class="card-body">
                                  No one cares about products. People care about ideas. Is a product an idea?
                                  Noup. Is
                                  a brand? A good one is.
                              </ng-template>
                            </ngb-panel>
                    </ngb-accordion>
                  </div>
              </div>
          </div>
          <div class="col-md-5">
              <div class="saas1 testimonial videos">
                  <div class="center-content">
                      <div class="video w-100">
                          <a class="button center-content">
                              <img src="assets/images/saas1/testimonial-video-icon.png"  (click)="openVerticallyCentered(content)" alt="video"
                                   class="img-fluid">
                                   <ng-template #content let-modal>
                                   <div class="modal-body video-modal">
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                      <iframe class="mfp-iframe" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/dNIfsv1rKJo?autoplay=1"></iframe>
                                </div>
                            </ng-template>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>

<!--accordion1 section end-->


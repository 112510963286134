<section class="coming-soon coming-soon-2 p-0">
    <div class="container">
        <div class="row text-center">
            <div class="col-md-12 p-0">
                <div class="center-container">
                    <div class="set-bg">
                        <div>
                            <img src="assets/images/logo/SVL_logo.png" alt="" class="coming-logo">
                        </div>
                        <h1 class="text-white our-web-text anim-typewriter">
                            <h2 class="breadcrumb-text">Build in progress. Come back soon!</h2></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

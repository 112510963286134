import { Component, OnDestroy, OnInit } from '@angular/core';
import { ColorScssService } from 'src/app/shared/service/color-scss.service';

@Component({
  selector: 'app-portfolio-component',
  templateUrl: './portfolio-component.component.html',
  styleUrls: ['./portfolio-component.component.scss']
})
export class PortfolioComponentComponent implements OnInit, OnDestroy {

  constructor(public displayblock: ColorScssService) {
    displayblock.displyBlock = true;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.displayblock.displyBlock = false;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Products } from 'src/app/shared/model/e-commerce/product.model';
import { OrderService } from 'src/app/shared/service/e-commerce/order.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { ProductsService } from './../../../shared/service/e-commerce/products.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  content = null;
  orderId: string = null;
  orderedItems: Products[] = [];
  subscription: any;
  private routeSub: Subscription;
  uniqueOrderDetails: any;

  constructor(public productsService: ProductsService,public languageService: LanguageService, public orderService: OrderService, private route: ActivatedRoute) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });
    this.content = JSON.parse(localStorage.getItem('data'));
  }

  ngOnInit(): void {
    this.routeSub = this.route.params.subscribe(params => {
      this.orderId = params.id;
      this.getItems();
    });
  }

  async getItems() {
    this.orderService.getOrderById(this.orderId).then(items => {
      this.orderedItems = items?.data?.data;
      this.uniqueOrderDetails = items?.data?.data.find(el => el);
    });
  }

}

<div class="agency">
    <app-product-header></app-product-header>
    <!-- breadcrumb section start -->
    <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">{{content?.MenuNewsRoom}}</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a [routerLink]="['/newsroom']">{{content?.MenuNewsRoom}}</a>
                                <li><a><i class="fa fa-angle-double-right"></i>{{content?.NewsRoomCategory}}</a>
                                </li>
                                <li><a><i class="fa fa-angle-double-right"></i>{{content?.NewsRoomSubCategory}}</a>
                                </li>     
                                <li><a><i class="fa fa-angle-double-right"></i>{{content?.NewsRoomDetails}}</a>
                                </li>                        
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--breadcrumb section end -->

    <!-- section start -->
    <section class="agency blog-sec single_blog_item">
        <div class="container">
            <div class="row">
                <div class="col-xl-8 detailed-news">
                    <div class="blog-block wow fadeInLeft" data-wow-duration="2s" data-wow-delay="1s">
                        <div class="blog-box">
                            <div class="overflow-hidden text-center"
                                *ngFor="let blogImage of blogData?.blogImages; let i = index">
                                <img class="img-fluid blur-up lazyload" [src]="blogImage.imageData" alt="blog">
                            </div>
                        </div>
                    </div>
                    <div class="blog-text m-t-20">
                        <div class="news-specification-details wow fadeInLeft" data-wow-duration="2s" data-wow-delay="2s">
                            <ul>
                                <li><i class="fa fa-user"></i>{{blogData.name}}</li>
                                <li><i class="fa fa-calendar"></i> {{blogData.createdDate}}</li>
                            </ul>
                        </div>
                        <!-- <h3 class="blog-head">{{blogData?.headerText + ", " + blogData?.createdDate}}</h3> -->
                        <h3 class="blog-head mt-4 wow fadeInLeft" data-wow-duration="2s" data-wow-delay="2.5s">{{blogData.headerText}}</h3>
                        <div class="blog-divider"></div>
                        <div class="news-content wow fadeInLeft" data-wow-duration="2s" data-wow-delay="3s">
                            <p [innerHTML]="blogData?.content"></p>
                          
                        
                            <!-- <h5>{{blogData?.name}}</h5> -->
                            <input type="hidden" name="metaKeyWords" value="{{blogData?.metaKeyWords}}" />
                            <!-- <h5 class="pull-right">{{blogData.like}} <i class="fa fa-heart-o"></i>{{blogData.comment}}
                                <i class="fa fa-comments-o"></i>
                            </h5> -->
                        </div>
                        <div class="news-social-share wow fadeInLeft" data-wow-duration="2s" data-wow-delay="0s">
                            Share:
                            <ul>
                                <li><a href=""><i class="fa fa-facebook"></i></a></li>
                                <li><a href=""><i class="fa fa-twitter"></i></a></li>
                                <li><a href=""><i class="fa fa-linkedin"></i></a></li>
                                <li><a href=""><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <!-- Comment Sec Start -->
                    <!-- <h3 class="page-header-comment">{{blogData.section}}</h3>
                    <div class="comment-list blog_comment">
                        <div class="comment_sec" *ngFor="let data of blogData.commentSection">
                            <article class="row">
                                <div class="" [ngClass]="data.Id==2?'col-11 offset-1':'col-12'">
                                    <div class="blog_center" [ngClass]="data.Id==2?re_comment:''">
                                        <div>
                                            <figure class="thumbnail">
                                                <img [src]="data.commentImg" alt="">
                                            </figure>
                                        </div>
                                        <div class="blog_user arrow left">
                                            <div class="panel-body">
                                                <div class="text-left">
                                                    <div class="comment-user"><i class="fa fa-user"></i>
                                                        <h6>{{data.commentName}}</h6>
                                                    </div>
                                                    <div class="comment-date"><i class="fa fa-clock-o"></i>
                                                        <h6>{{data.commentDate}}</h6>
                                                    </div>
                                                </div>
                                                <div class="comment-post">
                                                    <p>
                                                        {{data.commentDescription}}
                                                    </p>
                                                </div>
                                                <a href="#" class="link-btn"><i class="fa fa-reply"></i>
                                                    {{data.commentReplayTag}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div> -->
                    <!-- Comment Sec End -->
                    <!-- <div class="quote_2 comment_form_sec">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 quote_form">
                                    <h3 class="page-header-comment mb-4">Leave you comment :</h3>
                                    <form>
                                        <div class="form-row">
                                            <div class="form-group col-md-6">
                                                <label>Name :</label>
                                                <input type="text" class="form-control" placeholder="Enter Your Name"
                                                    required="">
                                            </div>
                                            <div class="form-group col-md-6">
                                                <label for="inputEmail4">Email :</label>
                                                <input type="email" class="form-control" id="inputEmail4"
                                                    placeholder="Enter Your Email" required="">
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label for="exampleTextarea">Message :</label>
                                                <textarea class="form-control msg_coment" id="exampleTextarea" rows="3"
                                                    placeholder="Leave a Comment" required=""></textarea>
                                            </div>
                                        </div>
                                        <a class="btn btn-default primary-btn m-t-20 radius-0" href="#">
                                            Post Comment
                                            <span class="con_btn_eff"></span>
                                        </a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="col-xl-4 blog-sidebar">
                    <div class="widget recent-post-widget wow fadeInRight" data-wow-duration="2s" data-wow-delay="2s">
                        <h3>Related Posts</h3>
                        <div class="posts" *ngFor="let latestBlog of latestBlogData">
                            <div class="post">
                                <div class="img-holder">
                                    <img [src]="latestBlog?.blogImages?.[0]?.imageData" alt="">
                                </div>
                                <div class="details">
                                    <span class="date">{{latestBlog.createdDate}}</span>
                                    <h4><a [routerLink]="['/newsroom/news-room-details', latestBlog?.id]">{{latestBlog.name}}</a></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>
    </section>
    <!-- section start -->

    <app-product-footer></app-product-footer>
</div>
import { Component, OnInit } from '@angular/core';
import { Observable, of, OperatorFunction } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, tap, switchMap } from 'rxjs/operators';
import { ProductsService } from 'src/app/shared/service/e-commerce/products.service';
import { Router } from "@angular/router";
import { ProductComponent } from "../product/product.component";
import { LanguageService } from 'src/app/shared/service/gym/language.service';

@Component({
  selector: 'app-product-typehead',
  templateUrl: './product-typehead.component.html',
  styleUrls: ['./product-typehead.component.scss']
})

export class ProductTypeheadComponent implements OnInit {

  model: any;
  searching = false;
  searchFailed = false;
  productSearch = "";
  private productComponent: ProductComponent;
  content = null;

  constructor(public productsService: ProductsService,
    private router: Router,
    public productComponentObj: ProductComponent,
    public languageService: LanguageService) {
  
      this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.content = JSON.parse(localStorage.getItem('data'));
    this.productComponent = productComponentObj;
  }

  ngOnInit(): void {
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.productsService.SearchProductByName(term)
          .pipe(
            tap(() => {
              this.productSearch = term;
              this.productComponent.productSearchTerm = term;
              this.searchFailed = false;
              this.productComponent.getProducts();
            }),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            }),
          ),
      ),
      tap(() => (this.searching = false)),
    );

  formatter = (x: { product: string }) => x.product;

  selectedItem(item) {
    this.router.navigate(['/shop/product/', item.item.productId]);
  }

}

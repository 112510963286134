
<section class="shadow-section">
  <div class="container">
      <div class="row">
          <div class="col-md-8 offset-md-2">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text m-b-0"><span
                              class="text-uppercase">large shadow</span></h2>
                  </div>

              </div>
              <div class="shadow-lg p-5 shadow-showcase text-center ">
                  <h3 class="m-0 f-18 text-center shadow-font">Larger shadow</h3>
              </div>

          </div>
      </div>
  </div>
</section>

<section class="shadow-section bg-light-inner">
  <div class="container">
      <div class="row">
          <div class="col-md-8 offset-md-2">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text m-b-0"><span
                              class="text-uppercase">regular shadow</span></h2>
                  </div>
              </div>
              <div class="shadow p-5 shadow-showcase text-center ">
                  <h3 class="m-0 f-18 text-center shadow-font">rgular shadow</h3>
              </div>
          </div>
      </div>
  </div>
</section>

<section class="shadow-section">
  <div class="container">
      <div class="row">
          <div class="col-md-8 offset-md-2">
              <div class="title title2 title-inner">
                  <div class="main-title">
                      <h2 class="font-primary borders text-center main-text m-b-0"><span
                              class="text-uppercase">small shadow</span></h2>
                  </div>

              </div>
              <div class="shadow-sm p-5 shadow-showcase text-center ">
                  <h3 class="m-0 f-18 text-center shadow-font">small shadow</h3>
              </div>
          </div>
      </div>
  </div>
</section>

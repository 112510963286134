<app-content></app-content>
<!-- footer start -->
<footer class="resume copyright copyright-bg inner-pages-footer">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="link link-horizontal  text-center mb-3">
                    <img alt="" class="img-fluid" src="assets/images/logo/5.png">
                </div>
            </div>
            <div class="col-12">
                <div class="link link-horizontal">
                    <ul class="justify-content-center">
                        <li><a class="copyright-text" href="#">home</a></li>
                        <li><a class="copyright-text" href="#">couple</a></li>
                        <li><a class="copyright-text" href="#">invitation</a></li>
                        <li><a class="copyright-text" href="#">album </a></li>
                        <li><a class="copyright-text" href="#">friends say</a></li>
                        <li><a class="copyright-text" href="#">gift</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <div class="social-link link-horizontal">
                        <ul class="justify-content-center">
                            <li><a class="copyright-text" href="#"><i aria-hidden="true" class="fa fa-facebook"></i></a>
                            </li>
                            <li><a class="copyright-text" href="#"><i aria-hidden="true" class="fa fa-twitter"></i></a>
                            </li>
                            <li><a class="copyright-text" href="#"><i aria-hidden="true" class="fa fa-google"></i></a>
                            </li>
                            <li><a class="copyright-text " href="#"><i aria-hidden="true"
                                                                       class="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer end -->

<!-- copyright start -->
<div class="agency copyright inner-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a class="copyright-text" href="#">Privacy Policy</a>
                        </li>
                        <li>
                            <a class="copyright-text" href="#">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <h6 class="copyright-text text-white text-right">Copyright ©2019 Unice by <i
                            aria-hidden="true"
                            class="fa fa-heart"></i>
                        pixelstrap</h6>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- copyright end -->
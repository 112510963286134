<!-- Nav Start-->
<header class="gym loding-header nav-abs custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto dark-logo-bg">
                        <img src="/assets/images/logo/SVL_logo.png" alt="" class="img-fluid">
                    </a>
                    <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->
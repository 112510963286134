<div class="img-wrapper">
  <div class="front" [routerLink]="['/shop/subcategory/', category?.id]">
    <a>
      <img
        alt=""
        class="img-fluid"
        routerLinkActive="active"
        [src]="category.image"
      />
    </a>
  </div>
</div>

<div class="product-detail">
  <a>
    <h6>{{ category.name }}</h6>
  </a>
</div>

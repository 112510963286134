<app-content></app-content>
<!--contact section start-->
<footer class="resume copyright copyright-bg">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="link link-horizontal">
                    <ul class="justify-content-center">
                        <li>
                            <a class="copyright-text" href="#">home</a>
                        </li>
                        <li>
                            <a class="copyright-text" href="#">couple</a>
                        </li>
                        <li>
                            <a class="copyright-text" href="#">invitation</a>
                        </li>
                        <li>
                            <a class="copyright-text" href="#">album </a>
                        </li>
                        <li>
                            <a class="copyright-text" href="#">friends say</a>
                        </li>
                        <li>
                            <a class="copyright-text" href="#">gift</a>
                        </li>
                    </ul>
                </div>
                <hr>
            </div>
            <div class="col-12">
                <div class="text-center">
                    <div class="social-link link-horizontal">
                        <ul class="justify-content-center">
                            <li>
                                <a class="copyright-text" href="#">
                                    <i aria-hidden="true" class="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a class="copyright-text" href="#">
                                    <i aria-hidden="true" class="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a class="copyright-text" href="#">
                                    <i aria-hidden="true" class="fa fa-google"></i>
                                </a>
                            </li>
                            <li>
                                <a class="copyright-text " href="#">
                                    <i aria-hidden="true" class="fa fa-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="m-l-auto center-para">
                    <h6 class="copyright-text text-center">© 2019 Unice powered by Pixelstrap</h6>
                </div>
            </div>
        </div>
    </div>
</footer>
<!--contact section end-->

<div class="agency">
  <app-product-header></app-product-header>
  <!-- breadcrumb section start -->
  <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb-contain">
            <div>
              <h2 class="breadcrumb-txt">{{ content?.MenuShop }}</h2>
            </div>
            <div>
              <ul>
                <li>
                  <a [routerLink]="['/shop']">{{ content?.MenuShop }}</a>
                </li>
                <li>
                  <a href="javscript:void(0)"><i class="fa fa-angle-double-right"></i>{{ content?.PortfolioCategory }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--breadcrumb section end -->

  <!-- section start -->
  <section class="section-b-space">
    <div class="collection-wrapper">
      <div class="container">
        <div class="row">
          <!-- End Collection Sidebar -->
          <div class="collection-content col">
            <div class="page-main-content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="collection-product-wrapper">
                      <!-- Display Products -->
                      <div class="product-wrapper-grid shop-sample-4" [class.list-view]="listView">
                        <div class="container-fluid">
                          <div class="row">
                            <div [class.col-xl-2]="col_xl_2" [class.col-xl-12]="col_xl_12" [class.col-xl-3]="col_xl_3"
                              [class.col-xl-4]="col_xl_3" [class.col-md-6]="col_md_6" class="col-grid-box"
                              *ngFor="let category of categories">
                              <div class="product-box">
                                <app-category-box class="list-product" [category]="category"></app-category-box>
                              </div>
                            </div>

                            <div class="col-sm-12 text-center section-b-space mt-5 no-found" *ngIf="!categories">
                              <img src="assets/images/empty-search.jpg" class="img-fluid mb-4" />
                              <h3>{{ content?.SorryCanNotFindTheProduct }}</h3>
                              <p>
                                {{ content?.PleaseCheckMisspeltSomething }}.
                              </p>
                              <a [routerLink]="'/e-commerce'" class="btn btn-default primary-btn ra-dius-0 m-t-10">{{
                                content?.ContinueShopping }}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="welcome-txt">
                        <div class="title title2 title-inner">
                          <div class="main-title">
                            <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">{{
                                content?.ShopWelcome }}</span>
                            </h2>
                          </div>
                        </div>
                        <!-- <h3>{{ content?.ShopWelcome }}</h3> -->

                        <p>{{ content?.ShopWelcomeLine1 }}.</p>

                        <p>{{ content?.ShopWelcomeLine2 }}.</p>

                        <p>{{ content?.ShopWelcomeLine3 }}.</p>

                        <p>{{ content?.ShopWelcomeLine4 }}.</p>

                        <p>{{ content?.ShopWelcomeLine5 }}.</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section End -->
  <!-- FAQ section start -->
  <section class="saas1 faq testimonial-bg">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="title title2 title-inner">
            <div class="main-title">
              <h2 class="font-primary borders text-center main-text m-b-0"><span class="text-uppercase">FAQ</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-md-8">
          <div class="faq-block wow slideInLeft" data-wow-delay="0.6s">
            <div class="w-100">
              <ngb-accordion [closeOthers]="true" class="accordion faq" activeIds="static-1">
                <ngb-panel id="static-1" class="card">
                  <ng-template ngbPanelTitle class="card-header">
                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1" aria-expanded="false">
                      <a>
                        <div class="fa fa-angle-right rotate"></div>
                      </a>
                      Do I need to make a payment?
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent class="card-body">
                    No one cares about products. People care about ideas. Is a product an idea?
                    Noup. Is
                    a brand? A good one is.
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="static-2" class="card">
                  <ng-template ngbPanelTitle class="card-header">
                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1" aria-expanded="false">
                      <a>
                        <div class="fa fa-angle-right rotate"></div>
                      </a>
                      Do I need to make a payment?
                    </div>
                  </ng-template>

                  <ng-template ngbPanelContent class="card-body">
                    No one cares about products. People care about ideas. Is a product an idea?
                    Noup. Is
                    a brand? A good one is.
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="static-3" class="card">
                  <ng-template ngbPanelTitle class="card-header">
                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1" aria-expanded="false">
                      <a>
                        <div class="fa fa-angle-right rotate"></div>
                      </a>
                      Do I need to make a payment?
                    </div>
                  </ng-template>

                  <ng-template ngbPanelContent class="card-body">
                    No one cares about products. People care about ideas. Is a product an idea?
                    Noup. Is
                    a brand? A good one is.
                  </ng-template>
                </ngb-panel>
                <ngb-panel id="static-4" class="card">
                  <ng-template ngbPanelTitle class="card-header">
                    <div class="collapsed" data-toggle="collapse" data-target="#collapse1" aria-expanded="false">
                      <a>
                        <div class="fa fa-angle-right rotate"></div>
                      </a>
                      Do I need to make a payment?
                    </div>
                  </ng-template>

                  <ng-template ngbPanelContent class="card-body">
                    No one cares about products. People care about ideas. Is a product an idea?
                    Noup. Is
                    a brand? A good one is.
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="faq-img-block wow slideInRight" data-wow-delay="0.6s">
            <img src="assets/images/saas1/faq.webp" class="img-fluid" alt="faq-person">
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section End -->
  <app-product-footer></app-product-footer>
</div>
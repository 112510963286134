<!-- Nav Start-->
<header class="resume loding-header nav-abs custom-scroll">
    <div class="container">
        <div class="row">
            <div class="col">
                <nav>
                    <a href="#" class="d-inline-block m-r-auto"><img src="assets/images/logo/5.png" alt="" class="img-fluid"></a>
                  <app-menu></app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->

<!--header start-->
<section class="resume header" id="header">
    <div class="header7-content">
        <div class="resume bg bg-pink" [ngStyle]="{'background-image': 'url(assets/images/resume/h7-bg-text.png)'}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-5 col-md-6">
                        <img src="assets/images/resume/h7.png" alt="" class="img-fluid set-abs bottom-0 right-0">
                    </div>
                    <div class="col-lg-6 col-md-6 offset-lg-1">
                        <div class="center-text">
                            <div>
                                <div class="header-sub-text">
                                    <h5 class="text-hash text-uppercase"># resume</h5>
                                </div>
                                <div class="header-text">
                                    <h1><span class="bold-text">Hi, I’m</span><br> Elana Parker</h1>
                                </div>
                                <div class="header-sub-text">
                                    <h4 class="font-secondary">Html , <span class="typed-resume">Bootstrap, css, No Jquery</span></h4>
                                </div>
                                <div class="header-sub-text">
                                    <p class="font-secondary font-large">Lorem Ipsum is simply dummy text of the printing andtypesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy textever since the 1500s, when an unknown.</p>
                                </div>
                                <div class="link-horizontal">
                                    <ul>
                                        <li><a class="btn btn-default primary-btn back-white">know more</a></li>
                                        <li><a class="btn btn-default primary-btn back-white">download cv</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--header end-->

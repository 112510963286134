import { ProductsService } from 'src/app/shared/service/e-commerce/products.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Products } from 'src/app/shared/model/e-commerce/product.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WishListService } from 'src/app/shared/service/e-commerce/wish-list.service';
import { CartService } from 'src/app/shared/service/e-commerce/cart.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/shared/service/gym/language.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit, OnDestroy {

  public url: any;
  public product: Products = {};
  public counter = 1;
  public selectedSize: any = '';
  public wishlist = false;
  public closeResult: string;
  private productSubscription: Subscription;
  public frameProducts: { productImages: { base64String: string }[], name: string, price: number }[] = [];
  selectedFrame: any = null;
  
  
  frameProductSliderOptions = {
    center: true,
    items: 3,            
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    responsive: {
      600:{
        items:4
    },
    },
    slideBy: 1,           
    autoplay: false,
    autoHeight: true, 
  };

  langContent = null;
  productSliderOptions = {
    items: 1,
    nav: false,
    dots: false,
    autoplay: false,
    slideSpeed: 300,
    loop: true
  };

  productSliderOptions1 = {
    items: 3,
    loop: true,
    margin: 10,
    dots: false
  };

  constructor(private route: ActivatedRoute, private router: Router,
    public productsService: ProductsService, private wishlistService: WishListService,
    private cartService: CartService, private modalService: NgbModal,
    public languageService: LanguageService,
    private toastr: ToastrService) {

    this.languageService.languageDataSubject$.subscribe(res => {
      this.langContent = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.langContent = JSON.parse(localStorage.getItem('data'));

    this.route.params.subscribe(params => {
      const id = +params.id;
      this.productsService.getProduct(id).then(product => this.product = product.data);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    });
  }

  ngOnInit() {
    this.getFrameProducts(); 
  }
  private getFrameProducts() {
    this.productsService.getFrameProducts()
      .then(response => {
        if (response.isSuccess) {
          const products = response.data;
          if (Array.isArray(products)) {
            this.frameProducts = products.map(product => ({
              productImages: product.productImages,
              name: product.name,
              price: product.price,
              id:product.id
            }));
          }
        }
      });
  }

  selectFrame(frame: any): void {
    if (this.selectedFrame === frame) {
      this.selectedFrame = null;
    } else {
      this.selectedFrame = frame;
    }
    console.log('Selected Frame:', this.selectedFrame);
  }
  closeOverlay() {
    this.productsService.filterBar = false;
  }

  public increment(productId: number) {
    this.productsService.getProductQuantity(productId)
      .then(availableQty => {
        this.counter += 1;
        if (availableQty < this.counter) {
          this.counter -= 1;
          this.toastr.error(`Product not available more than ${availableQty}.`);
        }
      });
  }

  public decrement(productId: number) {
    this.productsService.getProductQuantity(productId)
      .then(availableQty => {
        if (this.counter > 1) {
          this.counter -= 1;
          if (this.counter > availableQty) {
            this.counter = availableQty;
            this.toastr.error(`Product not available more than ${availableQty}.`)
          }
        }
      });
  }

  // Add to cart
  public addToCart(productId: number, quantity: number): void {
    const frameProductId = this.selectedFrame ? this.selectedFrame.id : null; // Get selected frame ID or null
    this.cartService.addToCart(productId, quantity, frameProductId).then((isAdded) => {
      if (isAdded) {
        this.toastr.success('Product added to cart successfully!');
      } else {
        this.toastr.error('Failed to add product to cart.');
      }
    }).catch(() => {
      this.toastr.error('Failed to add product to cart.');
    });
  }

  // Add to cart
  public buyNow(product: Products, quantity) {
    if (quantity > 0) {
      // tslint:disable-next-line:radix
      //this.cartService.addToCart(product.id, parseInt(quantity));
    }
    // this.router.navigate(['/e-commerce/checkout']);
  }

  // Add to wishlist
  public addToWishlist(product: Products) {
    this.wishlist = true;
    this.wishlistService.addToWishlist(product);
  }



  open(content) {
    this.modalService.open(content, { centered: true, ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  toggleFilter() {
    this.productsService.filterBar = !this.productsService.filterBar;
  }

  ngOnDestroy() {
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
  }
}

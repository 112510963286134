import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ColorScssService } from '../../shared/service/color-scss.service';

@Component({
  selector: 'app-gym',
  templateUrl: './gym.component.html',
  styleUrls: ['./gym.component.scss']
})
export class GymComponent implements OnInit {

  constructor(
    private colorPicker: ColorScssService,
    private route: ActivatedRoute,
    private title: Title,
    ) { }

  ngOnInit() {
    // this.colorPicker.setColorScheme('color-5');
  }

}

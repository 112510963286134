import { Component, OnInit } from '@angular/core';
import { AppIntializerService } from 'src/app/shared/service/app-intializer.service';
import { LanguageService } from 'src/app/shared/service/gym/language.service';
import { MyprofileService } from 'src/app/shared/service/my-profile/myprofile.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss']
})
export class MyprofileComponent implements OnInit {

  content = null;
  myProfileData;

  addressForm = new FormGroup({
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(''),
    city: new FormControl(''),
    zipCode: new FormControl('')
  });

  myDetailForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    mobileNo: new FormControl('')
  });

  constructor(public languageService: LanguageService, public appIntializerService: AppIntializerService,
    public myProfileService: MyprofileService, private modalService: NgbModal) {
    this.languageService.languageDataSubject$.subscribe(res => {
      this.content = res;
      localStorage.setItem('data', JSON.stringify(res));
    });

    this.getTranslatedData();
    this.getMyProfileData();
  }

  ngOnInit(): void {
  }

  getTranslatedData() {
    this.content = this.appIntializerService.languageData;
  }

  async getMyProfileData() {
    this.myProfileService.getMyProfile()
      .then(result => {
        console.log(result);
        this.myProfileData = result;
      });
  }

  async deleteAddress(addressId: number) {
    this.myProfileService.removeAddress(addressId).then(() => {
      this.getMyProfileData();
    });
  }

  addNewAddress(addressModel) {
    this.modalService.open(addressModel, {
      ariaLabelledBy: 'modal-basic-title', centered: true
    });
    this.addressForm.reset();
  }

  async saveAddress() {
    const addressData = {
      firstname: '', lastname: '', phoneNumber: '', email: '',
      addressLine1: this.addressForm.get('addressLine1').value,
      addressLine2: this.addressForm.get('addressLine2').value,
      city: this.addressForm.get('city').value,
      zipCode: this.addressForm.get('zipCode').value
    };
    this.myProfileService.addAddresses(addressData).then(res => {
      this.addressForm.reset();
      this.getMyProfileData();
      this.modalService.dismissAll();
    });
  }

  editMyDetails(myDetailModel) {
    this.modalService.open(myDetailModel, {
      ariaLabelledBy: 'modal-basic-title', centered: true
    });
    this.myDetailForm.patchValue({
      firstName: this.myProfileData?.name,
      lastName: this.myProfileData?.surname,
      email: this.myProfileData?.email,
      mobileNo: this.myProfileData?.mobileNumber
    });
  }

  async saveMyDetails() {
    const details = {
      firstName: this.myDetailForm.get('firstName').value,
      lastName: this.myDetailForm.get('lastName').value,
      email: this.myDetailForm.get('email').value,
      mobileNo: this.myDetailForm.get('mobileNo').value,
    };

    this.myProfileService.SaveMyDetails(details).then((result) => {
      this.getMyProfileData();
      this.modalService.dismissAll();
    });
  }

}

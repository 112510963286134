<div class="agency">
  <app-product-header></app-product-header>
  <!-- breadcrumb section start -->
  <section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="breadcrumb-contain">
            <div>
              <h2 class="breadcrumb-txt">{{ content?.MenuShop }}</h2>
            </div>
            <div>
              <ul>
                <li>
                  <a [routerLink]="['/shop']">{{ content?.MenuShop }}</a>
                </li>
                <li>
                  <a [routerLink]="['/shop']"
                    ><i class="fa fa-angle-double-right"></i
                    >{{ content?.PortfolioCategory }}</a
                  >
                </li>
                <li>
                  <a
                    ><i class="fa fa-angle-double-right"></i
                    >{{ content?.SubCategory }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--breadcrumb section end -->

  <!-- section start -->
  <section class="section-b-space">
    <div class="collection-wrapper">
      <div class="container">
        <div class="row">
          <!-- End Collection Sidebar -->
          <div class="collection-content col">
            <div class="page-main-content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="collection-product-wrapper">
                      <!-- Display Products -->
                      <div
                        class="product-wrapper-grid shop-sample-4"
                        [class.list-view]="listView"
                      >
                        <div class="container-fluid">
                          <div class="row">
                            <div
                              [class.col-xl-2]="col_xl_2"
                              [class.col-xl-12]="col_xl_12"
                              [class.col-xl-3]="col_xl_3"
                              [class.col-xl-4]="col_xl_3"
                              [class.col-md-6]="col_md_6"
                              class="col-grid-box"
                              *ngFor="let subcategory of subCategories"
                            >
                              <div class="product-box">
                                <app-subcategory-box
                                  class="list-product"
                                  [subcategory]="subcategory"
                                ></app-subcategory-box>
                              </div>
                            </div>

                            <div
                              class="col-sm-12 text-center section-b-space mt-5 no-found"
                              *ngIf="!subCategories"
                            >
                              <img
                                src="assets/images/empty-search.jpg"
                                class="img-fluid mb-4"
                              />
                              <h3>{{ content?.SorryCanNotFindTheProduct }}</h3>
                              <p>
                                {{ content?.PleaseCheckMisspeltSomething }}.
                              </p>
                              <a
                                [routerLink]="'/e-commerce'"
                                class="btn btn-default primary-btn ra-dius-0 m-t-10"
                                >{{ content?.ContinueShopping }}</a
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section End -->
  <app-product-footer></app-product-footer>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "./../api.service";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import {
  Category,
  ColorFilter,
  Products,
} from "../../model/e-commerce/product.model";
import { BaseResponse } from "../../model/base-response";
import { SingleResponse } from "../../model/single-response";
import { ToastrService } from "ngx-toastr";
import { CategoriesAndSubcategories } from "../../model/e-commerce/categories-and-subcategories.mode";
import { of } from "rxjs";

// Get product from Localstorage
const products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  public currency = "NOK ";
  public catalogMode = false;
  public productTotalCount: number;
  // For toggling filter of detail page in mobile view
  public filterBar = false;

  constructor(
    private baseService: ApiService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {}

  private products(
    index: number,
    size: number
  ): Promise<BaseResponse<Products[]>> {
    return this.baseService
      .GetAll<any>(
        this.baseService.clietnWebAPIUrl +
          "Product?index=" +
          `${index}` +
          "&size=" +
          `${size}`
      )
      .toPromise();
  }

  private productsWithFilter(
    index: number,
    size: number,
    categoryId: string,
    subCategoryIds: string,
    minPrice: number,
    maxPrice: number,
    productSearchTerm: string
  ): Promise<BaseResponse<Products[]>> {
    return this.baseService
      .GetAll<any>(
        this.baseService.clietnWebAPIUrl +
          "Product?index=" +
          `${index}` +
          "&size=" +
          `${size}` +
          "&categoryId=" +
          `${categoryId}` +
          "&subCategoryIds=" +
          `${subCategoryIds}` +
          "&minPrice=" +
          `${minPrice}` +
          "&maxPrice=" +
          `${maxPrice}` +
          "&searchTerm=" +
          `${productSearchTerm}`
      )
      .toPromise();
  }

  public getProducts(
    index: number,
    size: number
  ): Promise<BaseResponse<Products[]>> {
    return this.products(index, size);
  }

  public getProduct(id: number): Promise<SingleResponse<Products>> {
    return this.baseService
      .GetById<SingleResponse<Products>>(
        this.baseService.clietnWebAPIUrl + "Product",
        id
      )
      .toPromise();
  }
  public getFrameProducts(): Promise<BaseResponse<Products[]>> {
  return this.baseService
    .GetAll<BaseResponse<Products[]>>(
      this.baseService.clietnWebAPIUrl + "Product/GetFrameProducts"
    )
    .toPromise();
}


  public getCategories(): Promise<BaseResponse<Category[]>> {
    return this.baseService
      .GetAll<any>(this.baseService.clietnWebAPIUrl + "Category")
      .toPromise();
  }

  public getProductByColor(
    color: ColorFilter[],
    index: number,
    size: number
  ): Promise<Products[]> {
    return this.products(index, size).then((items) => {
      return items.data.data.filter((item: Products) => {
        if (color === item.colors) {
          return item.colors;
        } else {
          return item;
        }
      });
    });
  }

  public getProductQuantity(productId: number): Promise<number> {
    return this.http
      .get(
        this.baseService.APIUrl +
          "Product/GetProductQuantity?productId=" +
          productId
      )
      .toPromise()
      .then((result: any) => {
        return result.data?.quantity;
      })
      .catch((error) => {
        this.toastr.error("Something went wrong");
        return 0;
      });
  }

  // tslint:disable-next-line:no-shadowed-variable
  public checkDuplicateInObject(tag, Products) {
    let seenDuplicate = false;
    const testObject = {};

    // tslint:disable-next-line:only-arrow-functions
    Products.map(function (item) {
      const itemPropertyName = item[tag];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      } else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  public getProductByCategory(
    category: string,
    index: number,
    size: number,
    categoryId: string,
    subCategoryIds: string,
    minPrice: number,
    maxPrice: number,
    productSearchTerm: string
  ): Promise<Products[]> {
    return this.productsWithFilter(
      index,
      size,
      categoryId,
      subCategoryIds,
      minPrice,
      maxPrice,
      productSearchTerm
    ).then((items) => {
      this.productTotalCount = items.data.totalCount;
      return items.data.data.filter((item: Products) => {
        if (category === "all") {
          return item;
        } else {
          return item.category === category;
        }
      });
    });
  }

  private tag(index: number, size: number): Promise<BaseResponse<Products[]>> {
    return this.products(index, size);
  }

  public getTags(
    index: number,
    size: number
  ): Promise<BaseResponse<Products[]>> {
    return this.products(index, size);
  }

  public getCategoriesAndSubcategories(): Promise<
    BaseResponse<CategoriesAndSubcategories>
  > {
    return this.baseService
      .Get<BaseResponse<CategoriesAndSubcategories>>(
        this.baseService.APIUrl + "Category/GetCategoryWithSubCategory",
        ""
      )
      .toPromise();
  }

  /*
     ---------------------------------------------
     ----------  Compare Product  ----------------
     ---------------------------------------------
  */

  // Get Compare Products
  public getComapreProducts(): Observable<Products[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(products);
      observer.complete();
    });
    return itemsStream as Observable<Products[]>;
  }

  // If item is aleready added In compare
  public hasProduct(product: Products): boolean {
    // tslint:disable-next-line:no-shadowed-variable
    const item = products.find((item) => item.id === product.id);
    return item !== undefined;
  }

  // Add to compare
  public addToCompare(product: Products): Products | boolean {
    let item: Products | boolean = false;
    if (this.hasProduct(product)) {
      // tslint:disable-next-line:no-shadowed-variable
      item = products.filter((item) => item.id === product.id)[0];
      const index = products.indexOf(item);
    } else {
      if (products.length < 4) {
        products.push(product);
      } else {
        //this.toastrService.warning('Maximum 4 products are in compare.');
      } // toasr services
    }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
  }

  // Removed Product
  public removeFromCompare(product: Products) {
    if (product === undefined) {
      return;
    }
    const index = products.indexOf(product);
    products.splice(index, 1);
    localStorage.setItem("compareItem", JSON.stringify(products));
  }

  public SearchProductByName(name: string): Observable<string[]> {
    if (name === "") {
      return of([]);
    }

    return this.baseService
      .Get<any>(
        this.baseService.APIUrl + "Product/SearchProduct?productName=" + name,
        ""
      )
      .pipe(map((response) => response?.data?.searchProducts));
  }
}

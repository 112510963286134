<div class="img-wrapper">
  <a
    ><div
      class="front"
      [routerLink]="['/shop/productlist/', categoryId, subcategory?.id]"
    >
      <img
        alt=""
        class="img-fluid"
        [routerLink]="['/shop/productlist/', categoryId, subcategory?.id]"
        routerLinkActive="active"
        [src]="subcategory.image"
      />
    </div>
  </a>
</div>

<div class="product-detail">
  <a>
    <h6>{{ subcategory.name }}</h6>
  </a>
</div>

import { OrderDetailsComponent } from './product/order-details/order-details.component';
import { CheckoutComponent } from './product/checkout/checkout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GymComponent } from './gym/gym.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { PortfolioComponentComponent } from './portfolio/portfolio-component/portfolio-component.component';
import { ProductCartComponent } from './product/product-cart/product-cart.component';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { ProductComponent } from './product/product/product.component';
import { CategoryComponent } from './product/category/category.component';
import { SuccessComponent } from './product/success/success.component';
import { OrderListComponent } from './product/order-list/order-list.component';
import { BlogDetailsComponent } from './blogs/blog-details/blog-details.component';
import { BlogListComponent } from './blogs/blog-list/blog-list.component';
import { ManifestoComponent } from './manifesto/manifesto.component';
import { SubCategoryComponent } from './product/subcategory/subcategory.component';
import { NewsroomSubcategoryComponent } from './blogs/newsroom-subcategory/newsroom-subcategory.component';

const routes: Routes = [
  {
    path: '',
    component: GymComponent
  },
  {
    path: 'portfolio',
    component: PortfolioComponentComponent,
    data: {
      breadcrumbTitleKey: 'MenuPortfolio'
    }
  },
  {
    path: 'shop',
    component: CategoryComponent,
    data: {
      breadcrumbTitleKey: 'MenuShop'
    }
  },
  {
    path: 'shop/product/:id',
    component: ProductDetailsComponent,
    data: {
      title: 'ProductDetails',
      breadcrumbTitleKey: 'ProductDetails'
    }
  },
  {
    path: 'shop/subcategory/:id',
    component: SubCategoryComponent,
    data: {
      breadcrumbTitleKey: 'MenuShop'
    }
  },
  {
    path: 'shop/productlist/:categoryid/:subcategoryid',
    component: ProductComponent,
    data: {
      breadcrumbTitleKey: 'MenuShop'
    }
  },
  {
    path: 'shop/cart',
    component: ProductCartComponent,
    data: {
      breadcrumbTitleKey: 'Cart'
    }
  },
  {
    path: 'shop/cart/checkout',
    component: CheckoutComponent,
    data: {
      breadcrumbTitleKey: 'CheckOut'
    }
  },
  {
    path: 'myprofile',
    component: MyprofileComponent,
    data: {
      breadcrumbTitleKey: 'MyProfile'
    }
  },
  {
    path: 'shop/cart/checkout/success',
    component: SuccessComponent,
    data: {
      breadcrumbTitleKey: 'SuccessOrder'
    }
  },
  {
    path: 'shop/order-details/:id',
    component: OrderDetailsComponent,
    data: {
      breadcrumbTitleKey: 'OrderDetails'
    }
  },
  {
    path: 'shop/orders',
    component: OrderListComponent,
    data: {
      breadcrumbTitleKey: 'Orders'
    }
  },
  {
    path: 'newsroom',
    component: BlogListComponent,
    data: {
      breadcrumbTitleKey: 'MenuNewsRoom'
    }
  },
  {
    path: 'newsroom/news-room-subcategory/:id',
    component: NewsroomSubcategoryComponent,
    data: {
      breadcrumbTitleKey: 'MenuNewsRoom'
    }
  },
  {
    path: 'newsroom/news-room-details/:id',
    component: BlogDetailsComponent,
    data: {
      breadcrumbTitleKey: 'MenuNewsRoom'
    }
  },
  {
    path: 'manifesto',
    component: ManifestoComponent,
    data: {
      breadcrumbTitleKey: 'MenuManifesto'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
